import axios from "axios"
import { useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import Spinner from 'react-bootstrap/Spinner';

export default function DeleteBtn({item,setData}) {

    const [deleteLoad,setdeleteLoad] = useState(false)

    let getCompanyID = useSelector(compID => compID.checkedSessionReducer)
    let getmyinfo = useSelector(compID => compID.getMayInfoReducer)
    const handleDeletShablon=(e)=>{
        setdeleteLoad(true)
        axios.post('/deleteShablon/api', {
          CompanyID:getCompanyID.checkedSession,
        creatorTask:getmyinfo.myinfo._id,
        taskID:e._id
        })
        .then(function (response) {
        setData(response.data.data)
        setdeleteLoad(false)
        })
        .catch(function (error) {
          console.log(error);
          setdeleteLoad(false)
        });
      }

        if (deleteLoad) {
            return      <Spinner animation="border" variant="danger" style={{marginLeft:"3px"}}/>
        }else{
            return      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" viewBox="0 0 27 26" fill="none" onClick={()=>handleDeletShablon(item)}>
  <g filter="url(#filter0_d_1521_4039)">
    <ellipse cx="13.3325" cy="11" rx="9.77778" ry="10" fill="white"/>
  </g>
  <path d="M9.03819 17.223C8.72309 17.223 8.45334 17.1303 8.22895 16.9448C8.00456 16.7594 7.89236 16.5365 7.89236 16.276V7.27983H7.10938V6.33286H10.6997V5.85938H15.7413V6.33286H19.3316V7.27983H18.5486V16.276C18.5486 16.5286 18.434 16.7495 18.2049 16.9389C17.9757 17.1283 17.7083 17.223 17.4028 17.223H9.03819ZM17.4028 7.27983H9.03819V16.276H17.4028V7.27983ZM11.0625 14.9187H12.2083V8.62137H11.0625V14.9187ZM14.2326 14.9187H15.3785V8.62137H14.2326V14.9187Z" fill="#E62E7B"/>
  <defs>
    <filter id="filter0_d_1521_4039" x="0.554688" y="0" width="25.5557" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="1.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1521_4039"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1521_4039" result="shape"/>
    </filter>
  </defs>
                      </svg>
       
        }
       
}