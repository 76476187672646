import axios from "axios"
import { useEffect, useState } from "react";
import "./uploadFile.css"
import { useSelector } from "react-redux";
import zipIcon from "./iconUpload/zipIcon.png"
import pdf from "./iconUpload/pdf.png"
import exel from "./iconUpload/exel.png"
import world from "./iconUpload/world.png"
const random = () => {
  return Math.random(Math.floor() * 100000)
}
export default function UploadFileTaskMenegerEditChilds({ handleChangeFile, files, setFile, handleDeleteFile,Taskfiles,setTaskfiles,index}) {

  const getDarkModoe = useSelector(darkModes => darkModes);



  function generateUniqueId() {
    const timestamp = Date.now().toString(36); // Convert current timestamp to base36 string
    const randomChars = Math.random().toString(36).substr(2, 5); // Generate random characters

    return timestamp + randomChars;
  }

  let getCompanyID = useSelector(compID => compID.checkedSessionReducer)
  let getmyinfo = useSelector(compID => compID.getMayInfoReducer)

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFile(selectedFiles)
  };



  const handleDeleteLocal = (index) => {
    setFile(prevArray => {
      const newArray = [...prevArray]; // Create a new array copy
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the modified array
    });

  }

  const downloadFileForLocal = (item) => {
    const fileURL = URL.createObjectURL(item);
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = item.name || 'downloaded_file';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(fileURL);
  };


  const downloadFile = (item) => {
    const fileURL = `/${item.filename}`; // Adjust path as needed
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = item.filename || 'downloaded_file';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "uploadFileDark" : "uploadFileLight"} key={index}>
      <div>
        <div>
          <label htmlFor={"uploadEditTaskChildEdit" + index}>
            <p>Добавить файлы</p>
            <input type="file" id={"uploadEditTaskChildEdit" + index} style={{ display: "none" }} onChange={handleFileChange} multiple />
          </label>
        </div>
        <div>


          {
            Taskfiles?.map((item, index) => {
              return (
                <div key={index} >
                  <div >
                    <div>
                      <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M7.5364 11.73C7.18492 11.3785 6.61508 11.3785 6.2636 11.73C5.91213 12.0814 5.91213 12.6513 6.2636 13.0027L9.53043 16.2696C9.8819 16.621 10.4517 16.621 10.8032 16.2696L18.5364 8.5364C18.8879 8.18492 18.8879 7.61508 18.5364 7.2636C18.1849 6.91213 17.6151 6.91213 17.2636 7.2636L10.1668 14.3604L7.5364 11.73Z" fill="#005FDB" />
                        </svg>
                        <p>{item.originalname}</p>
                      </div>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={() => handleDeleteFile(index)}>
                          <path d="M11.1712 12L7.77521 8.60406C7.40826 8.23711 7.40826 7.64216 7.77521 7.27521C8.14216 6.90826 8.73711 6.90826 9.10406 7.27521L12.5 10.6712L15.8959 7.27521C16.2629 6.90826 16.8578 6.90826 17.2248 7.27521C17.5917 7.64216 17.5917 8.23711 17.2248 8.60406L13.8288 12L17.2248 15.3959C17.5917 15.7629 17.5917 16.3578 17.2248 16.7248C16.8578 17.0917 16.2629 17.0917 15.8959 16.7248L12.5 13.3288L9.10406 16.7248C8.73711 17.0917 8.14216 17.0917 7.77521 16.7248C7.40826 16.3578 7.40826 15.7629 7.77521 15.3959L11.1712 12Z" fill="#1C2E45" fill-opacity="0.6" />
                        </svg>
                      </div>
                    </div>
                    {item && (item.mimetype === "image/png" || item.mimetype === "image/jpeg") && (
                      <img src={`/${item.filename}`} alt="Uploaded" onClick={()=> downloadFile(item)}/>
                    )}
                    {item && (item.mimetype === "video/mp4") && (
                      <video src={`/${item.filename}`} alt="Uploaded" />
                    )}

                  </div>
                </div>
              )
            })
          }
          {
            files?.map((item, index) => {
              console.log(item, "local");
              return (
                <div key={index} >
                  <div >
                    <div>
                      <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M7.5364 11.73C7.18492 11.3785 6.61508 11.3785 6.2636 11.73C5.91213 12.0814 5.91213 12.6513 6.2636 13.0027L9.53043 16.2696C9.8819 16.621 10.4517 16.621 10.8032 16.2696L18.5364 8.5364C18.8879 8.18492 18.8879 7.61508 18.5364 7.2636C18.1849 6.91213 17.6151 6.91213 17.2636 7.2636L10.1668 14.3604L7.5364 11.73Z" fill="#005FDB" />
                        </svg>
                        <p>{item.name}</p>
                      </div>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={() => handleDeleteLocal(index)}>
                          <path d="M11.1712 12L7.77521 8.60406C7.40826 8.23711 7.40826 7.64216 7.77521 7.27521C8.14216 6.90826 8.73711 6.90826 9.10406 7.27521L12.5 10.6712L15.8959 7.27521C16.2629 6.90826 16.8578 6.90826 17.2248 7.27521C17.5917 7.64216 17.5917 8.23711 17.2248 8.60406L13.8288 12L17.2248 15.3959C17.5917 15.7629 17.5917 16.3578 17.2248 16.7248C16.8578 17.0917 16.2629 17.0917 15.8959 16.7248L12.5 13.3288L9.10406 16.7248C8.73711 17.0917 8.14216 17.0917 7.77521 16.7248C7.40826 16.3578 7.40826 15.7629 7.77521 15.3959L11.1712 12Z" fill="#1C2E45" fill-opacity="0.6" />
                        </svg>
                      </div>
                    </div>
                    {item && (item.type === "image/png" || item.type === "image/jpeg") && (
                      <img src={URL.createObjectURL(item)} alt="Uploaded" onClick={()=> downloadFileForLocal(item)}/>
                    )}
                    {item && (files.type === "video/mp4") && (
                      <video src={URL.createObjectURL(item)} alt="Uploaded" onClick={()=> downloadFileForLocal(item)}/>
                    )}

                  </div>
                </div>
              )
            })
          }

        </div>
      </div>
    </div>
  )
}