import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import EmojiPicker from 'emoji-picker-react';
import { Navigate, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Avatar, Textarea, AvatarBadge, Img, shouldForwardProp, Icon } from "@chakra-ui/react";
import Mydata from "../../settingsUser/settingsPage/mydata/mydata";
import FileRenderComment from "../commentBox/fileRenderComment";
import { getLinkPreview, getPreviewFromContent } from "link-preview-js";
import UrlPreview from "../UrlPreview/urlPreview";
import InputEmoji from 'react-input-emoji'
import { useDispatch, useSelector } from "react-redux";
import { getadminInfoAction } from "../../../../redux/reducer/getAdminInfo/action/action";
import { getAllUsersAction } from "../../../../redux/reducer/getallusers/action/action";
import { fetchUsers } from "../../../../redux/reducer/postFetcReducer/action/action";
import SendMessageNoPerformerNotify from "../sendMessageNoPerformerNotify/sendMessageNoPerformerNotify";
import { AtSignIcon } from "@chakra-ui/icons";
import sockets from "../../../../socket/socket";

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'
import CommentPopover from "../commentPopover/commentPopover";
import ToWhomToSend from "../to whom to send/toWhomToSend";

const socket = sockets
function getDate(params) {
  return new Date().toLocaleString()
}

export default function CommentBoxChildEdit({ comments, setComments, index, isChild, allitem, editAllTask, update }) {





  const [localComment, setlocalComment] = useState([])
  const [isSend, setisSend] = useState(false)
  const [load, setLoad] = useState(false)
  const getDarkModoe = useSelector(darkModes => darkModes)
  const [commentsVal, setcommentsVal] = useState("");
  const [date, setDate] = useState("")
  const getmyinfo = useSelector(info => info)
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [images, setImages] = useState([]);
  const fileInputRef = useRef(null);
  const [toggle, setToggle] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [indexFile, setindexFile] = useState(0)
  const [deleteFiles, setdeleteFiles] = useState([])
  const [show, setShow] = useState(false)
  const [selectPerformer, setselectPerformer] = useState({})
  // если isSend верно и это родительский(isChild) элемент то отправляем на сервер
  let onlineUSers = useSelector(onlineUSers => onlineUSers)
  const [uploadProgress, setUploadProgress] = useState(0);

  const dispatch = useDispatch()
  const [text, setText] = useState('');
  const textAreaRef = useRef(null);
  // ==========================

  useEffect(()=>{
    console.log(uploadProgress,"uploadProgress");
  },[uploadProgress])
  useEffect(() => {
    dispatch(fetchUsers())
  }, [dispatch])

  // здесь отправляем на сервер
  useEffect(() => {
    if (isSend) {
      if (!isChild) {
        axios.post('/taskaddCommentsParent/api', {
          taskID: allitem._id,
          localComment: comments,
          editAllTask
        })
          .then(function (response) {
            console.log(response);
            setisSend(false)
            setLoad(false)
            update()
            console.log("gge");
          })
          .catch(function (error) {

            console.log(error);
          });
      } else {
        axios.post('/taskaddCommentsChild/api', {
          taskID: allitem._id,
          localComment: comments,
          editAllTask,
          index
        })
          .then(function (response) {
            console.log(response);
            setLoad(false)
            setisSend(false)

            update()
            sendCreatNotifyForCooments()
          })
          .catch(function (error) {

            console.log(error);
          });
      }
    }


  }, [isSend])


  async function uploadCommentFileForChild(tasks) {
    const updatedTasks = []; // Инициализируем пустой массив для обновленных задач

    for (const task of tasks) { // Проходим по каждой задаче в массиве задач
      const updatedComments = []; // Инициализируем пустой массив для обновленных комментариев

      for (const comment of task.comments) { // Проходим по каждому комментарию в задаче
        const formData = new FormData(); // Создаем новый объект FormData
        comment.images.forEach((file) => { // Для каждого файла изображения в комментарии
          console.log(file instanceof Blob);
          formData.append('images', file); // Добавляем файл в FormData с именем поля 'images'
        });

        try {
          const response = await axios.post('/uploadChildCommentAndGetForSaveImagePathAndComment/api', formData, { // Отправляем POST-запрос на сервер для загрузки файлов
            headers: {
              'Content-Type': 'multipart/form-data' // Устанавливаем заголовок для отправки форм-данных
            }
          });

          const imagePaths = response.data.paths; // Предполагаем, что сервер возвращает пути к загруженным файлам
          const blobs = comment.images.filter(file => !(file instanceof Blob)); // Keep existing images that are not blobs
          console.log(blobs, "blobs");

          const updatedComment = {
            ...comment, // Сохраняем остальные данные комментария
            images: [...imagePaths, ...blobs], // Обновляем массив изображений с путями к загруженным файлам

          };
          console.log(updatedComment, "updatedComment");

          updatedComments.push(updatedComment); // Добавляем обновленный комментарий в массив обновленных комментариев
        } catch (error) {
          console.error('Ошибка при загрузке файлов:', error); // Обрабатываем ошибки при загрузке файлов
        }
      }

      updatedTasks.push({
        ...task, // Сохраняем остальные данные задачи
        comments: updatedComments // Обновляем массив комментариев задачи
      });
    }

    return updatedTasks; // Возвращаем массив обновленных задач
  }



  

  const sendServerComment = () => {
    const formData = new FormData();
    //     // Добавляем данные, которые не являются файлами
    formData.append('taskID', allitem._id);
    formData.append('localComment', JSON.stringify({
      commentsVal,
      date,
      tel: getmyinfo.getMayInfoReducer.myinfo.tel,
      name: getmyinfo.getMayInfoReducer.myinfo.name,
      myid: getmyinfo.getMayInfoReducer.myinfo._id,
      images, // Временно пустой массив
      whoReadThisComment: []
    }));
    formData.append('editAllTask', editAllTask);
    formData.append('index', index);
    // Добавляем изображения
    files.forEach((file, idx) => {
      formData.append(`files`, file); // Имя поля должно совпадать с тем, что вы ожидаете на сервере
    });

    // Отправляем запрос на сервер
    axios.post('/taskaddCommentsChildEdit/api', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log(response);
        setLoad(false);
        setisSend(false);
        sendCreatNotifyForCooments()
        update();
      })
      .catch(error => {
        console.error(error);
      });
  };

  // upload server and set image
  function resetFileInput() {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the value of the file input
    }
  }

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Convert FileList to an array
    setFiles([...files, ...selectedFiles]);
    setImages([...images, ...selectedFiles])
    console.log(images, "imagesimagesimagesimages");

  };
  


  // add comment widht enter
  const addComment = (e) => {
    setDate(new Date().toLocaleString())
    if (e.key === "Enter") {

      setComments(comments => [...comments, {
        commentsVal,
        date,
        tel: getmyinfo.getMayInfoReducer.myinfo.tel,
        name: getmyinfo.getMayInfoReducer.myinfo.name,
        myid: getmyinfo.getMayInfoReducer.myinfo._id,
        images,
        whoReadThisComment: []
      }])
      sendServerComment()
      setcommentsVal("")
      setImages([])
      setFiles([])
      resetFileInput()

    }

  }
  // add comment widht button
  // здесь добавляем всё в состоянии setComments()
  const addCommentBtn = (e) => {

    setDate(new Date().toLocaleString())
    setComments(comments => [...comments, {
      myid: getmyinfo.getMayInfoReducer.myinfo._id,
      commentsVal,
      date,
      tel: getmyinfo.getMayInfoReducer.myinfo.tel,
      name: getmyinfo.getMayInfoReducer.myinfo.name,
      images,


    }])
    setcommentsVal("")
    setImages([])
    resetFileInput()
    setisSend(true)
    dispatch(fetchUsers())
  }

  // delete comment
  function deleteComment(index) {
    setisSend(true)
    try {
      if (comments[index].images.length > 0) {
        axios.post('/deleteImageComment/api', {
          fileNames: comments[index].images,

        })
          .then(function (response) {
            setComments(prevArray => {
              const newArray = [...prevArray]; // Create a new array copy
              newArray.splice(index, 1); // Remove the element at the specified index
              return newArray; // Update the state with the modified array
            });

            setisSend(false)

          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } catch (error) {
      console.log(error);
    }
    setComments(prevArray => {
      const newArray = [...prevArray]; // Create a new array copy
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the modified array
    });


  }

  // set emojy setcommentsVal
  const handleEmojiClick = (emoji) => {
    setcommentsVal(val => val + emoji.emoji);
  };


  // dropdown toggle
  const handleShoweDropDown = (e) => {
    setToggle(!toggle);
  }
  const handleAll = (e) => {
    try {
      const parentElement = document.getElementById('dropdownCommentEmojy'); // Replace 'parentElement' with the actual parent element's ID
      if (parentElement != undefined && parentElement != null) {
        const isChild = parentElement?.contains(e.target);
        if (!isChild) {
          setToggle(false);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }
  // 
  window.addEventListener('click', handleAll);



  // get all Comment for edit


  const handleAllComments = (item, index) => {
    setcommentsVal("")
    setImages([])
    setcommentsVal(item.commentsVal)
    setImages(item.images)
    setIsEdit(true)
    setindexFile(index)
  }

  const enditChildUpdateComment = (updatedComments) => {
    const formData = new FormData();
    // Добавляем данные, которые не являются файлами
    formData.append('taskID', allitem._id);
    formData.append('editComment', JSON.stringify(updatedComments));
    formData.append('editAllTask', editAllTask);
    formData.append('subTaskIndex', index);
    formData.append('commentIndexChild', indexFile);

    // Добавляем изображения
    files.forEach((file, idx) => {
      formData.append(`files`, file); // Имя поля должно совпадать с тем, что вы ожидаете на сервере
    });

    // Отправляем запрос на сервер
    axios.post('/EdittaskaddCommentsChildEdit/api', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => {
        // Вычисляем процент загрузки
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(progress); // Обновляем состояние прогресса загрузки
      }
    })
      .then(response => {
        console.log(response);
        setLoad(false);
        setisSend(false);
        setUploadProgress(0); // Сбрасываем прогресс после завершения загрузки
        update();
      })
      .catch(error => {
        console.error(error);
        setUploadProgress(0); // Сбрасываем прогресс в случае ошибки
      });
  };

  // submit all Comment for edit
  const submitEditComment = (e) => {
    if (e.key === "Enter") {
      canchelEdit()
      // Create a copy of the existing comments array
      const updatedComments = [...comments];
      // Update the specific comment object at indexFile
      updatedComments[indexFile] = {
        ...updatedComments[indexFile], // Preserve other properties
        commentsVal: commentsVal,
        images: images,
      };
      // Set the updated comments state
      setComments(updatedComments);
      enditChildUpdateComment(updatedComments[indexFile]);
      setcommentsVal("");
      setImages([]);
      setIsEdit(false);
      setFiles([]);

    }

  }



  // delet btn file
  const deleteFileInLocal = (e) => {
    setImages(prevState => prevState.filter((_, i) => i !== e))
    setdeleteFiles(prevState => [...prevState, images[e]])
    setFiles(prevState => prevState.filter((_, i) => i !== e))

  }

  const canchelEdit = () => {
    setImages(prev => [...prev, ...deleteFiles])
    setdeleteFiles([])
    setcommentsVal("")
    setImages([])
    setIsEdit(false)

  }


  function handleOnEnter(text) {
    console.log('enter', text)
  }
  // когда нажимается собачка(@) срабатывает этот событие
  function sendNotify(event) {

    if (event.key === '@') {
      setShow(true)
    } else {
      setShow(false)
    }
  }

  // здесь выбранный сотрудник добавляется в комментарии и добавляется State (setselectPerformer)
  const selectPerformerFunc = (e) => {
    if (e.name) {
      setcommentsVal(commentsVal + e.name + " ")
    } else {
      setcommentsVal(commentsVal + e.tel + " ")
    }

    setselectPerformer(e)
  }
  // этот события для того чтобы создать уведомление здесь проверяется в состоянии есть исполнитель или постановщик чтобы отправить ему уведомление Что есть новое сообщение для него
  function sendCreatNotifyForCooments() {
    if (selectPerformer.workerID) {
      axios.post('/creatNotifyForComment/api', {
        localComment: {
          commentsVal,
          date,
          tel: getmyinfo.getMayInfoReducer.myinfo.tel,
          name: getmyinfo.getMayInfoReducer.myinfo.name,
          myid: getmyinfo.getMayInfoReducer.myinfo._id,
          images, // Временно пустой массив
          whoReadThisComment: []
        },
        isChild,
        selectPerformer,
        index,
        allitem
      })
        .then(function (response) {
          console.log(response);
          setselectPerformer({})
          socket.emit('getNotifyEmit');
        })
        .catch(function (error) {

          console.log(error);
        });
    }

  }

  // Это для того когда вы будете удалить в комментариях текст и там будет собачка тогда откроется модельный одну для выбора
  useEffect(() => {
    if (commentsVal[commentsVal.length - 1] == "@") {
      setShow(true)
    }

  }, [commentsVal])
  return (

    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "CommentAllContDark" : "CommentAllContLight"}>
      <div>
        <div >
          <div>
            <div>
            {uploadProgress > 0 && <div>Upload Progress: {uploadProgress}%</div>}
              {
                isEdit ?
                  <p>Редактируемое сообщение</p>
                  :
                  <p>Комментарии</p>
              }
              <div>
                <div>
                  <textarea name="" id="" onKeyDown={(e) => isEdit ? submitEditComment(e) : addComment(e)} value={commentsVal} ref={textAreaRef} onChange={(e) => [setcommentsVal(e.target.value), setDate(new Date().toLocaleString())]}></textarea>
                  <div className="editIamgeCont">
                    
                    {
                      images?.map((item, index) => {
                        return <FileRenderComment files={item} showButton={true} deletFile={deleteFileInLocal} index={index} allFiles={images} key={index} isLocal={true} />
                      })
                    }

                  </div>
                </div>

                <div className="commentOptions">
                  <label htmlFor={`uploadFileForCommentEdit` + index} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none">
                      <path d="M19.0899 11.5008L11.9558 18.635C11.0818 19.509 9.8964 20 8.66039 20C7.42437 20 6.23899 19.509 5.365 18.635C4.491 17.761 4 16.5756 4 15.3396C4 14.1035 4.491 12.9182 5.365 12.0442L12.4992 4.90999C13.0819 4.32733 13.8721 4 14.6961 4C15.5201 4 16.3104 4.32733 16.893 4.90999C17.4757 5.49266 17.8031 6.28292 17.8031 7.10692C17.8031 7.93093 17.4757 8.72119 16.893 9.30385L9.75109 16.438C9.45976 16.7294 9.06462 16.893 8.65263 16.893C8.24062 16.893 7.84549 16.7294 7.55416 16.438C7.26283 16.1467 7.09916 15.7516 7.09916 15.3396C7.09916 14.9276 7.26283 14.5324 7.55416 14.2411L14.1449 7.65809" stroke="#767676" stroke-width="1.16445" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <input type="file" id={`uploadFileForCommentEdit` + index} style={{ display: "none" }} multiple onChange={handleImageChange} ref={fileInputRef} />
                  </label>
                  <div>
                    <Popover placement='auto'>
                      <PopoverTrigger>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                          <path d="M9.5 17.5C5.08172 17.5 1.5 13.9182 1.5 9.5C1.5 5.08172 5.08172 1.5 9.5 1.5C13.9182 1.5 17.5 5.08172 17.5 9.5C17.5 13.9182 13.9182 17.5 9.5 17.5Z" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M13.0984 11.5C13.0984 11.5 11.8984 13.1 9.49844 13.1C7.09844 13.1 5.89844 11.5 5.89844 11.5" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12.2984 7.09688C12.0776 7.09688 11.8984 6.91779 11.8984 6.69688C11.8984 6.47596 12.0776 6.29688 12.2984 6.29688C12.5193 6.29688 12.6984 6.47596 12.6984 6.69688C12.6984 6.91779 12.5193 7.09688 12.2984 7.09688Z" fill="#767676" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.69688 7.09688C6.47596 7.09688 6.29688 6.91779 6.29688 6.69688C6.29688 6.47596 6.47596 6.29688 6.69688 6.29688C6.91779 6.29688 7.09688 6.47596 7.09688 6.69688C7.09688 6.91779 6.91779 7.09688 6.69688 7.09688Z" fill="#767676" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </PopoverTrigger>
                      <PopoverContent width={"auto"} padding={0} className="smileCommentContent">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody padding={0}> <EmojiPicker className="smileCommentContentEmoji" onEmojiClick={handleEmojiClick} allowExpandReactions={true} /></PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </div>
                  {/* <ToWhomToSend show={show} commentsVal={commentsVal} textAreaRef={textAreaRef} setShow={setShow}/> */}
                  <SendMessageNoPerformerNotify setShow={setShow} show={show} selectPerformerFunc={selectPerformerFunc} />
                </div>
              </div>

            </div>
          </div>

          <div className="commentScrollDiv">

            {
              comments?.map((item, index) => {
                return (
                  <div key={index} className="CommentListCont">
                    <div className="CommentItem">
                      <div>
                        {
                          onlineUSers.getOnlineUserViaRouterReducer.users.data?.includes(item.myid) ?
                            <Avatar size="md" name="Christian Nwamba" src={`/${getmyinfo.getMayInfoReducer.myinfo.img}`} zIndex={0}>
                              <AvatarBadge boxSize='0.9em' bg='green.500' zIndex={0} />
                            </Avatar>
                            :
                            <Avatar size="md" name="Christian Nwamba" src={`/${getmyinfo.getMayInfoReducer.myinfo.img}`} zIndex={0} />
                        }
                        <div className="firstDiv">
                          <div>
                            <div>
                              <p>{item.name}</p>
                              <p className="commentItemTime">{item.date} PM</p>
                              {item?.whoReadThisComment?.length > 0?
                             <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                             <path d="M6.5 10L9.14286 13L17 5" stroke="#3B79F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                             <path d="M1 9L5 13L15 3" stroke="#3B79F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           </svg>
                              :
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <path d="M5 13L9 17L19 7" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                              }
                            </div>
                            <div>
                              <UrlPreview url={`${item.commentsVal}`} />
                            </div>
                          </div>
                        </div>
                        <div className="lastDiv">
                          <CommentPopover deleteComment={() => deleteComment()} handleAllComments={handleAllComments} item={item} index={index} />


                        </div>
                      </div>
                    </div>
                    <div className="localFilesRender">
                      {
                        item.images?.map((itemChild, indexChild) => {
                          return <FileRenderComment files={itemChild} showButton={false} deletFile={deleteFileInLocal} index={indexChild} allFiles={item.images} isLocal={false} />
                        })
                      }
                    </div>
                  </div>
                )

              })

            }



          </div>
        </div>

      </div>

    </div>
  )
}