import React, { useRef, useState } from 'react';

import axios from 'axios';
import { useToast } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import sockets from '../../../../../socket/socket';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    useDisclosure
  } from '@chakra-ui/react'

  const socket = sockets
const DropdownMobilePioritet = ({ isChild, prioritetGet, allitemForStatus, index, update }) => {

    let getmyinfo = useSelector(compID => compID.getMayInfoReducer)
    const myinfos = useSelector(data => data.getMyImageReducer);
    const getDarkModoe = useSelector(darkModes => darkModes)
    const modalRed = useRef(null)
    const spanRef = useRef(null)
    const toast = useToast()

    const { onOpen, onClose, isOpen } = useDisclosure();
    const [prioritetColor, setPrioritetColor] = useState("red")

    const handleGetNotify = () => {
        socket.emit('getNotifyEmit');
    }
    async function sendInfoNotify(allitemForStatus, isChild, whatIsNotofy, index, currentTask) {
        console.log(currentTask);

        axios.post('/sendInfoNotify/api', {
            allitemForStatus,
            isChild,
            whatIsNotofy,
            index,
            currentTask
        })
            .then(function (response) {
                console.log(response);
                handleGetNotify()
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const handleUpdateStatus = (e) => {
        if (isChild) {

            allitemForStatus.alltask[0].child[index].prioritet = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    console.log(response.data.message);
                    sendInfoNotify(allitemForStatus, isChild, "prioritet", index,allitemForStatus.alltask[0].child[index])
                    update()
                    toast({
                        position: "top",
                        title: "Приоритет обновлен",
                        description: "Приоритет обновлен",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                  
                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        } else {
            allitemForStatus.alltask[0].prioritet = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    console.log(response);
                    sendInfoNotify(allitemForStatus, isChild, "prioritet",index,allitemForStatus.alltask[0])
                    update()
                    toast({
                        position: "top",
                        title: "Приоритет обновлен",
                        description: "Приоритет обновлен",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                   
                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        }

    }


    return (
        <div  className={getDarkModoe.ToggleDarkModeReducer.chekced ? "selectPrioritetDark" : "selectPrioritetLight"}>

            {/* <div className='showStatus'>
                {(() => {
                    if (prioritetGet == "Критический") {
                        return (
                            <div onClick={handleOpenModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5658 2.17515C10.8433 2.17515 10.2207 1.66664 10.0763 0.958735C9.98333 0.492443 9.55966 0.147791 9.06366 0.147791H1.64433C1.07599 0.147791 0.610992 0.603947 0.610992 1.16147V16.3667C0.610992 16.9242 1.07599 17.3804 1.64433 17.3804C2.21266 17.3804 2.67766 16.9242 2.67766 16.3667V12.2846C2.67766 11.18 3.57309 10.2846 4.67766 10.2846H7.22289C7.94535 10.2846 8.568 10.7931 8.71233 11.501C8.80533 11.9774 9.22899 12.312 9.72499 12.312H15.0777C15.646 12.312 16.111 11.8558 16.111 11.2983V3.18883C16.111 2.63131 15.646 2.17515 15.0777 2.17515H11.5658Z" fill="#FF7070" />
                                </svg>
                                <p>Критический</p>
                            </div>
                        )
                    } else if (prioritetGet == "Важно") {
                        return (
                            <div onClick={handleOpenModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5658 2.98082C10.8433 2.98082 10.2207 2.4723 10.0763 1.7644C9.98333 1.29811 9.55966 0.953456 9.06366 0.953456H1.64433C1.07599 0.953456 0.610992 1.40961 0.610992 1.96714V17.1723C0.610992 17.7299 1.07599 18.186 1.64433 18.186C2.21266 18.186 2.67766 17.7299 2.67766 17.1723V13.0903C2.67766 11.9857 3.57309 11.0903 4.67766 11.0903H7.22289C7.94535 11.0903 8.568 11.5988 8.71233 12.3067C8.80533 12.7831 9.22899 13.1176 9.72499 13.1176H15.0777C15.646 13.1176 16.111 12.6615 16.111 12.1039V3.9945C16.111 3.43697 15.646 2.98082 15.0777 2.98082H11.5658Z" fill="#707EFF" />
                                </svg>
                                <p>Важно</p>
                            </div>
                        )
                    } else if (prioritetGet == "Срочно") {
                        return (
                            <div onClick={handleOpenModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5658 2.78648C10.8433 2.78648 10.2207 2.27797 10.0763 1.57006C9.98333 1.10377 9.55966 0.75912 9.06366 0.75912H1.64433C1.07599 0.75912 0.610992 1.21528 0.610992 1.7728V16.978C0.610992 17.5355 1.07599 17.9917 1.64433 17.9917C2.21266 17.9917 2.67766 17.5355 2.67766 16.978V12.8959C2.67766 11.7914 3.57309 10.8959 4.67766 10.8959H7.22289C7.94535 10.8959 8.568 11.4044 8.71233 12.1123C8.80533 12.5888 9.22899 12.9233 9.72499 12.9233H15.0777C15.646 12.9233 16.111 12.4671 16.111 11.9096V3.80016C16.111 3.24264 15.646 2.78648 15.0777 2.78648H11.5658Z" fill="#FFE870" />
                                </svg>
                                <p>Срочно</p>
                            </div>
                        )
                    }
                    else if (prioritetGet == "Низший") {
                        return (
                            <div onClick={handleOpenModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5658 2.5941C10.8433 2.5941 10.2207 2.08558 10.0763 1.37768C9.98333 0.911388 9.55966 0.566737 9.06366 0.566737H1.64433C1.07599 0.566737 0.610992 1.02289 0.610992 1.58042V16.7856C0.610992 17.3431 1.07599 17.7993 1.64433 17.7993C2.21266 17.7993 2.67766 17.3431 2.67766 16.7856V12.7035C2.67766 11.599 3.57309 10.7035 4.67766 10.7035H7.22289C7.94535 10.7035 8.568 11.2121 8.71233 11.92C8.80533 12.3964 9.22899 12.7309 9.72499 12.7309H15.0777C15.646 12.7309 16.111 12.2747 16.111 11.7172V3.60778C16.111 3.05025 15.646 2.5941 15.0777 2.5941H11.5658Z" fill="#6B7A99" />
                                </svg>
                                <p>Низший</p>
                            </div>
                        )
                    }
                    // else {
                    //     return (
                    //         <div onClick={handleOpenModal}>
                    //              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                    //             <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5658 2.5941C10.8433 2.5941 10.2207 2.08558 10.0763 1.37768C9.98333 0.911388 9.55966 0.566737 9.06366 0.566737H1.64433C1.07599 0.566737 0.610992 1.02289 0.610992 1.58042V16.7856C0.610992 17.3431 1.07599 17.7993 1.64433 17.7993C2.21266 17.7993 2.67766 17.3431 2.67766 16.7856V12.7035C2.67766 11.599 3.57309 10.7035 4.67766 10.7035H7.22289C7.94535 10.7035 8.568 11.2121 8.71233 11.92C8.80533 12.3964 9.22899 12.7309 9.72499 12.7309H15.0777C15.646 12.7309 16.111 12.2747 16.111 11.7172V3.60778C16.111 3.05025 15.646 2.5941 15.0777 2.5941H11.5658Z" fill="#6B7A99" />
                    //         </svg>
                    //         </div>

                    //     )
                    // }

                })()}
            </div>


            <div id="myModal" class="modal" ref={modalRed} onClick={close}>

                <div class="modal-content">
                    <span class="close" ref={spanRef} onClick={handleCloseModal}>&times;</span>
                    {
                        (() => {
                            if (getmyinfo.myinfo._id === allitemForStatus.creatorTasks) {
                                return (
                                    <>
                                        <div class="dropdown-contentChangeStatus">
                                            <div onClick={() => handleUpdateStatus("Критический")}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5658 2.17515C10.8433 2.17515 10.2207 1.66664 10.0763 0.958735C9.98333 0.492443 9.55966 0.147791 9.06366 0.147791H1.64433C1.07599 0.147791 0.610992 0.603947 0.610992 1.16147V16.3667C0.610992 16.9242 1.07599 17.3804 1.64433 17.3804C2.21266 17.3804 2.67766 16.9242 2.67766 16.3667V12.2846C2.67766 11.18 3.57309 10.2846 4.67766 10.2846H7.22289C7.94535 10.2846 8.568 10.7931 8.71233 11.501C8.80533 11.9774 9.22899 12.312 9.72499 12.312H15.0777C15.646 12.312 16.111 11.8558 16.111 11.2983V3.18883C16.111 2.63131 15.646 2.17515 15.0777 2.17515H11.5658Z" fill="#FF7070" />
                                                </svg>
                                                Критический
                                            </div>
                                            <div onClick={() => handleUpdateStatus("Важно")}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5658 2.98082C10.8433 2.98082 10.2207 2.4723 10.0763 1.7644C9.98333 1.29811 9.55966 0.953456 9.06366 0.953456H1.64433C1.07599 0.953456 0.610992 1.40961 0.610992 1.96714V17.1723C0.610992 17.7299 1.07599 18.186 1.64433 18.186C2.21266 18.186 2.67766 17.7299 2.67766 17.1723V13.0903C2.67766 11.9857 3.57309 11.0903 4.67766 11.0903H7.22289C7.94535 11.0903 8.568 11.5988 8.71233 12.3067C8.80533 12.7831 9.22899 13.1176 9.72499 13.1176H15.0777C15.646 13.1176 16.111 12.6615 16.111 12.1039V3.9945C16.111 3.43697 15.646 2.98082 15.0777 2.98082H11.5658Z" fill="#707EFF" />
                                                </svg>
                                                Важно
                                            </div>
                                            <div onClick={() => handleUpdateStatus("Срочно")}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5658 2.78648C10.8433 2.78648 10.2207 2.27797 10.0763 1.57006C9.98333 1.10377 9.55966 0.75912 9.06366 0.75912H1.64433C1.07599 0.75912 0.610992 1.21528 0.610992 1.7728V16.978C0.610992 17.5355 1.07599 17.9917 1.64433 17.9917C2.21266 17.9917 2.67766 17.5355 2.67766 16.978V12.8959C2.67766 11.7914 3.57309 10.8959 4.67766 10.8959H7.22289C7.94535 10.8959 8.568 11.4044 8.71233 12.1123C8.80533 12.5888 9.22899 12.9233 9.72499 12.9233H15.0777C15.646 12.9233 16.111 12.4671 16.111 11.9096V3.80016C16.111 3.24264 15.646 2.78648 15.0777 2.78648H11.5658Z" fill="#FFE870" />
                                                </svg>
                                                Срочно
                                            </div>
                                            <div onClick={() => handleUpdateStatus("Низший")}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5658 2.5941C10.8433 2.5941 10.2207 2.08558 10.0763 1.37768C9.98333 0.911388 9.55966 0.566737 9.06366 0.566737H1.64433C1.07599 0.566737 0.610992 1.02289 0.610992 1.58042V16.7856C0.610992 17.3431 1.07599 17.7993 1.64433 17.7993C2.21266 17.7993 2.67766 17.3431 2.67766 16.7856V12.7035C2.67766 11.599 3.57309 10.7035 4.67766 10.7035H7.22289C7.94535 10.7035 8.568 11.2121 8.71233 11.92C8.80533 12.3964 9.22899 12.7309 9.72499 12.7309H15.0777C15.646 12.7309 16.111 12.2747 16.111 11.7172V3.60778C16.111 3.05025 15.646 2.5941 15.0777 2.5941H11.5658Z" fill="#6B7A99" />
                                                </svg>
                                                Низший
                                            </div>


                                        </div>
                                    </>
                                )
                            }
                        })()
                    }

                </div>

            </div> */}
                 <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}

      >
        <PopoverTrigger>
          <button disabled={getmyinfo.myinfo._id !== allitemForStatus?.creatorTasks}>
            <div>
              <div>
                <div>
                  <p>Приоритет:</p>
                  <div>
                    <p style={{ color: prioritetColor }}>{prioritetGet}</p>
                  </div>
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                  </svg>
                </div>
              </div>
            </div>
          </button>
        </PopoverTrigger>
        <PopoverContent borderRadius={"0 0 12px 12px"} overflow={"hidden"} width={"240px"}>

          <PopoverBody className={getDarkModoe.ToggleDarkModeReducer.chekced ? "popoverForCreatPrioritetDark" : "popoverForCreatPrioritetLight"}>
            <div onClick={() => [handleUpdateStatus("Критический"), onClose()]}>
              <div>
                <div>
                  <p>Критический</p>
                </div>
              </div>
            </div>
            <div onClick={() => [handleUpdateStatus("Важно"), onClose()]}>
              <div>
                <div>
                  <p>Важно</p>
                </div>
              </div>
            </div>
            <div onClick={() => [handleUpdateStatus("Срочно"), onClose()]}>
              <div>
                <div>
                  <p>Срочно</p>
                </div>
              </div>
            </div>
            <div onClick={() => [handleUpdateStatus("Низший"), onClose()]}>
              <div>
                <div>
                  <p>Низший</p>
                </div>
              </div>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
        </div>
    );
};

export default DropdownMobilePioritet;
