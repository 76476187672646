// UrlPreview.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
const urlRegex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/g;

const UrlPreview = ({ url }) => {
  const [data, setData] = useState(null);
  const [searchUrl,setsearchUrl] = useState("")
  const [urltext, Seturltext] = useState("hell have are you  https://andrejgajdos.com/how-to-create-a-link-preview/");


  useEffect(() => {




    // Use the match() method to find all links
    const urlMatches = url.match(urlRegex);

        console.log(urlMatches);
            if (urlMatches) {
                const fetchUrlData = async () => {
                    try {
                      if (!url) return; // Ensure there's a valid URL before making the request
              
                      const response = await axios.get(`/preview/api?url=${url}`);
                      setData(response.data.parseInf);
                      console.log(response.data.parseInf);
                         
                    } catch (error) {
                      console.error('Error fetching URL preview:', error);
                    }
                  };
              
                  fetchUrlData();
            }

  }, [url]);

  return (
    <div>
      {/* p not url  */}
         <p>{url.replace(urlRegex, '')}</p>
         <a href={url.match(urlRegex)} target="_blank">{url.match(urlRegex)}</a>
      {data ? (
        <div>
            {
                data.imageUrl?
                <img src={data.imageUrl}  style={{width:"100px",height:"50px",objectFit:"cover"}}  />
                :
                ""
            }
             
          <h2>{data.title}</h2>
          <p>{data.description}</p>
          <p>{data.name}</p>
        
          asd
        </div>
      ) : (
        ""
      )}

       
    </div>
  );
};

export default UrlPreview;
