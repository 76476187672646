import React, { useRef, useState, useEffect } from "react";

import { useToast } from '@chakra-ui/react'


import PerfomerEdit from "../perfomerEdit/perfomerEdit";
import { useSelector } from "react-redux";
import axios from "axios";
import UploadFileTaskMenegerEditChilds from "../uploadFile/uploadFileChildEdit";
import MetkaEdit from "../metka/editMetka";
import DropdownMobileStatus from "../tableTask/EditStatusForMobile/editStatusForMobile";
import DropdownMobilePioritet from "../tableTask/editPrioritetForMobile/editPrioritetForMobile";
import Prioritet from "../prioritet/prioritet";
import Status from "../status/status";
import sockets from "../../../../socket/socket";
import StartDateModalEdit from "../startDateModalEdit/startDateModalEdit";
import EndDateModalEdit from "../endDateModalEdit/endDateModalEdit";
import CommentBoxChildEdit from "../commentBoxChildEdit/commentBoxChildEdit";

const socket = sockets


function ChilMNodalEdit({ item, allaray, nameCurrentSubTask, sendServer, load, editAllTask, index, allitem, handleGetNotify, handleClose, update, deleteItemByIndex,updateComment }) {

  const toast = useToast()
  const getmyinfo = useSelector((allWorkers) => allWorkers);

  const [comments, setComments] = useState([]);
  const currentDate = new Date();
  const adjustedDate = new Date(currentDate.getTime() - currentDate.getTimezoneOffset() * 60000);
  const formattedDate = adjustedDate.toISOString().slice(0, 16);
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);
  const [status, setStatus] = useState("Новая");
  const [prioritet, setPrioritet] = useState("Низший");
  const [metka, setMetka] = useState([]);
  const [performer, setperformer] = useState([]);
  const [aboutTask, setAboutTask] = useState("");
  const [file, setfile] = useState([]);
  const [perfomerID, setperfomerID] = useState([]);
  const [endDateConfirm, setDndDateConfirm] = useState("");
  const modalRef = useRef(null);
  const getDarkModoe = useSelector(darkModes => darkModes);
  const [taskInfo, setTasktaskInfo] = useState("");
  const [Taskfiles, setTaskfiles] = useState([]);
  const [price,setPrice]=useState(0);

  useEffect(() => {
    modalRef.current.style.display = "none"
  }, [])


  useEffect(() => {


    setTasktaskInfo(item.taskInfo)
    setComments(item.comments)
    setStartDate(item.startDate)
    setEndDate(item.endDate)
    setAboutTask(item.aboutTask)
    setPrioritet(item.prioritet)
    setStatus(item.status)
    setperformer(item.performer)
    // setfile(item.file)
    setMetka(item.metka)
    setTaskfiles(item.Taskfiles)
    setPrice(item.price)

  }, [item.comments, item.startDate, item.endDate, item.aboutTask, item.prioritet, item.status, item.performer, item.file, item.metka, item.taskInfo,item.price])




  const setTaskInfoFunc = (e) => {

    setTasktaskInfo(e)
  }


  // добавляет состояние и отправлять на сервер

  const sendserver = () => {
    item.comments = comments
    item.performer = performer
    item.startDate = startDate
    item.endDate = endDate
    item.status = status
    item.prioritet = prioritet
    item.aboutTask = aboutTask
    item.file = file
    item.metka = metka
    item.taskInfo = taskInfo
    item.price = price
    sendSeverCreatNotifyForTask()
    if (performer.email) {
      console.log(performer.email);
      axios.post('/sendEmail/api', {
        perfomerEmail: performer.email
      })
        .then(function (response) {
          console.log(response);

        })
        .catch(function (error) {
          console.log(error);
        });
    }
    sendServer()
    // handleClose()
    // handleCloseChild()
  }

  // обрабатывать добавить исполнителя 
  const HandleSetPerfomerId = (e) => {

    setperfomerID([e])
    setperformer(e)
    item.performer = performer
    if (allaray[0]?.performer) {
      allaray[0].child[index].performer = e
    }
    console.log(performer,"performerperformerperformerperformerperformer");
  }

  // обработать удалить Исполнитель 
  const HandleDeletePerfomerId = (e) => {
    setperformer("")
    setperfomerID([])
    item.performer = []


  }
  // этот функция отправляет для подтверждения дату

  const handleDate = () => {

    axios.post('/notifyConfirm', {
      eidtTaskID: allitem._id,
      isChild: true,
      adminIDTask: allitem.creatorTasks.toString(),
      typenotify: "date",
      indexTask: index,
      senderName: getmyinfo.getMayInfoReducer.myinfo.name,
      senderTel: getmyinfo.getMayInfoReducer.myinfo.tel,
      senderEmail: getmyinfo.getMayInfoReducer.myinfo.email,
      text: "",
      messageConfirm: endDateConfirm,
      taskName: nameCurrentSubTask,
      oldDate: editAllTask[0].child[index].endDate

    })
      .then(function (response) {
        console.log(response);
        handleGetNotify()
        toast({
          position: "top",
          title: 'Отправлено на подтверждение постановщику задачи',
          description: "Отправлено на подтверждение постановщику задачи",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // обрабатывать файл изменений
  const handleChangeFile = (e) => {

    setfile(e)
    console.log(file);
  }

  const handleShowChildModal = async() => {
    modalRef.current.style.display = "flex"
    // try {
    //   const response = await axios.post('/whoReadThisComment/api', {
    //     taskID: allitem._id,
    //     isChild:true,
    //     indexSubTask:index
    //   });
    //  update()
     
    // } catch (error) {
    //     console.error(error);
    // } 
  }


  const handleCloseChildModal = (e) => {
    e.stopPropagation();
    modalRef.current.style.display = "none"
    item.comments = comments
    item.performer = performer
    item.startDate = startDate
    item.endDate = endDate
    item.status = status
    item.prioritet = prioritet
    item.aboutTask = aboutTask
    item.file = file
    item.metka = metka
    item.taskInfo = taskInfo

  }
  useEffect(() => {
    if (performer == "") {
      if (allaray[0]?.performer) {
        setperformer(allaray[0]?.performer)
      }
    }
  }, [])


  async function sendSeverCreatNotifyForTask(e) {
    const taskID = await axios.get(`/findTaskById/api?taskID=${allitem._id}`);
    console.log(new Date(taskID.data.data.alltask[0].child[index].endDate).getTime(), new Date(allitem.alltask[0].endDate).getTime());
    if (taskID.data.data) {
      if (new Date(taskID.data.data.alltask[0].child[index].endDate).getTime() != new Date(endDate).getTime()) {
        if (allitem.alltask[0].performer) {
          // получаем информацию о пользователе
          const userInfo = await axios.get(`/getuserByID/api?userID=${allitem.creatorTasks}`);
          let mount = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
          const covertDate = new Date(endDate).getDay() + " " + mount[new Date(endDate).getMonth()] + " " + new Date(endDate).getFullYear()
          let notifyText = `${userInfo.data.data.name} изменил срок окончания <a target="_blank" class="taskName" href="editChild/${allitem._id}/${index}">${allitem.alltask[0].taskName}</a> на ${covertDate}  конца.`
          axios.post('/creatNotifyForTaskInfo/api', {
            // здесь не проверяем Это задача и подзадача
            isChild: true,
            // здесь мы отправляем полное задание
            task: allitem,
            //  // если это подзадача тогда получаем индекс этого подзадачу
            index,
            // здесь мы отправляем текст для уведомлений
            notifyText,
            // кому нужно отправить этот уведомление мы берём идентификатор этого пользователя
            notificationRecipientID: allitem.alltask[0].child[index].performer.workerID,
            // информация пользователя для отправления уведомлений на электронную почту
            emailInfo: {
              // здесь мы отправляем идентификатор пользователя
              userIdForEmail: allitem.alltask[0].child[index].performer.workerID,
              // текст для заголовки
              subject: "Смена срока окончания задачи",
              // текст для электронную почту
              textEmail: `${userInfo.data.data.name} изменил срок окончания <a target="_blank" class="taskName" href="https://xn--h1alceggc.xn--p1ai/editChild/${allitem._id}/${index}">${allitem.alltask[0].taskName}</a> на ${covertDate}  конца.`
            }
          })
            .then(function (response) {
              console.log(response);
              socket.emit('getNotifyEmit');
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    }




  }
  const handleDeleteFile = (e) => {
    setTaskfiles((prevFiles) => prevFiles.filter((_, index) => index !== e));
  }


  return (
    <div className="modalCreatTaskChild" onClick={handleShowChildModal} key={index}>
      <div>
        <p>{item.msg}</p>
        <div onClick={(e) => deleteItemByIndex(index, e)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path d="M7.6115 7.9974L5.34754 5.73344C5.1029 5.4888 5.1029 5.09217 5.34754 4.84754C5.59217 4.6029 5.9888 4.6029 6.23344 4.84754L8.4974 7.1115L10.7614 4.84754C11.006 4.6029 11.4026 4.6029 11.6473 4.84754C11.8919 5.09217 11.8919 5.4888 11.6473 5.73344L9.38329 7.9974L11.6473 10.2614C11.8919 10.506 11.8919 10.9026 11.6473 11.1473C11.4026 11.3919 11.006 11.3919 10.7614 11.1473L8.4974 8.88329L6.23344 11.1473C5.9888 11.3919 5.59217 11.3919 5.34754 11.1473C5.1029 10.9026 5.1029 10.506 5.34754 10.2614L7.6115 7.9974Z" fill="#1C2E45" fill-opacity="0.6" />
          </svg>
        </div>
      </div>
      <div class="modalChildModal" ref={modalRef} id="childModalCont">
        <div class="modal-contentChildModal">
          <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "CreatTaskDark" : "CreatTask"}>
            <div className="headerTask">

              <div>

                {
                  prioritet ?
                    <DropdownMobilePioritet isChild={true} prioritetGet={prioritet} allitemForStatus={allitem} index={index} update={update} />
                    :
                    <Prioritet setPrioritet={setPrioritet} prioritet={prioritet} />
                }
                {
                  status ?
                    <DropdownMobileStatus isChild={true} statusGet={status} allitemForStatus={allitem} index={index} update={update} />
                    :
                    <Status setStatus={setStatus} status={status} />
                }
              </div>
              <div>
                {/* <Shablon sendMessage={sendMessage} currentTask={currentTask} perfomerID={perfomerID} receivedMessages={receivedMessages} handleDelet={handleDelet} delLoad={delLoad} update={update} handleGetNotify={handleGetNotify} closeParent={handleClose} /> */}
              </div>
            </div>
            <div className="choosePerformerDiv">
              {/* <Perfomer setperfomerID={setperfomerID} HandleSetPerfomerId={HandleSetPerfomerId} HandleDeletePerfomerId={HandleDeletePerfomerId} bydefault={performer} /> */}
              <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "selectPerformerDark" : "selectPerformerLight"} >
                <PerfomerEdit performerS={performer} setperformerS={setperformer} HandleSetPerfomerId={HandleSetPerfomerId} HandleDeletePerfomerId={HandleDeletePerfomerId} />
              </div>

            </div>
            <div className="sectionOtherTask">
              <div>
                <div>
                  <label htmlFor="taskNameInput">
                    <p>Выберите название задачи </p>
                    <input type="text"
                      id="taskNameInput"
                      placeholder="Введите название задачи"
                      className="nameTaskInput"
                      value={item.msg}
                      disabled

                    />
                  </label>
                </div>
                <div>
                  <label htmlFor="priceCreatInput">
                    <p>Цена</p>
                    <input type="number"
                      id="priceCreatInput"
                      placeholder="Цена"
                      className="nameTaskInput"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </label>
                </div>

                <div className="dateCreatDiv">
                  <div>
                    <div>
                      <div>
                        <p>Сроки: начало</p>
                        <StartDateModalEdit startDate={startDate} setStartDate={setStartDate} handleDate={handleDate} />
                      </div>
                      <div>
                        <p>Сроки: конец</p>
                        <EndDateModalEdit endDate={endDate} setEndDate={setEndDate} handleDate={handleDate} setDndDateConfirm={setDndDateConfirm} allitem={allitem} endDateConfirm={endDateConfirm} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <label>
                    <p>Добавьте описание задачи</p>
                    <textarea placeholder='Добавьте описание задачи' value={taskInfo} onChange={(e) => setTaskInfoFunc(e.target.value)} />
                  </label>

                  <UploadFileTaskMenegerEditChilds files={file} setFile={setfile} Taskfiles={Taskfiles} setTaskfiles={setTaskfiles} allitem={allitem} index={index} isChild={true} handleChangeFile={handleChangeFile} handleDeleteFile={handleDeleteFile} key={index} />
                </div>
              </div>
              <div>
                {/* <CommentBoxCreatTask setComments={setComments} comments={comments} /> */}
                <MetkaEdit setMetka={setMetka} metka={metka} />
                <CommentBoxChildEdit setComments={setComments} comments={comments} index={index} isChild={true} allitem={allitem} editAllTask={editAllTask} update={update} />
              </div>
            </div>
            <div className="creatTaskButton" style={{ gap: "10px" }}>
              <button colorScheme="blue" onClick={(e) => handleCloseChildModal(e)}> <p>Закрыть</p></button>
              {
                load?
                <button colorScheme="blue" > <p>Загрузка</p></button>
                :
                <button colorScheme="blue" onClick={(e) => sendserver(e)}> <p>Сохранить подзадачу</p></button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChilMNodalEdit;
