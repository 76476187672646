import { Pagination } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Input
} from "@chakra-ui/react";

import "./allUsers.css";

import { useEffect, useRef, useState, useTransition } from "react";
import { getadminInfoAction } from "../../../../redux/reducer/getAdminInfo/action/action";

import axios from "axios";

import ModalEditUsers from "./modalForEditUsers/modalEditUsers";
import DeleteUsersByAdmin from "./deleteUsers/deleteUsers";
import { getUsersAllAction } from "../../../../redux/reducer/getAllUsersForAdmin/action/action";
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import sockets from "../../../../socket/socket";

const socket = sockets;
export default function Allusers() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);
  const [seatchVal, setseatchVal] = useState("");
  const [isPending, startTransition] = useTransition();
  const dispatch = useDispatch();

  const getAllUsers = useSelector((users) => users);


  useEffect(() => {
    dispatch(getadminInfoAction("/getadmininfo/admin/api"));

  }, [dispatch]);

  useEffect(() => {

    if (seatchVal == "") {
      dispatch(getUsersAllAction(`/getallusers/api?page=${currentPage}`));
    } else {
      dispatch(
        getUsersAllAction(
          `/searchbyinputusers/api?search=${selectForm.current.browser.value}&page=${currentPage}`
        )
      );
    }


  }, [currentPage]);

  const selectForm = useRef(null);

  // for input seacrh
  const handleSubmit = (e) => {


    startTransition(() => {
      if (selectForm.current.browser.value == "") {
        dispatch(getUsersAllAction(`/getallusers/api?page=${currentPage}`));
      } else {
        dispatch(
          getUsersAllAction(
            `/searchbyinputusers/api?search=${selectForm.current.browser.value}&page=${currentPage}`
          )
        );
      }

      setseatchVal(e.target.value)
      e.PreventDefault();
      selectForm.current.submit();
    });

  };

  const setDataForEdit = (item) => {
    setData(item);
    console.log(data);
    setShow(true);
  };


  const handlePageChange = (page) => {
    setCurrentPage(page);




  };

  useEffect(() => {
    const handleUpdateMyData = () => {
      dispatch(getUsersAllAction(`/getallusers/api?page=${currentPage}`));
    };

    // Subscribe to the event
    socket.on("updateForAdminAllUsers", handleUpdateMyData);

    // Unsubscribe when the component unmounts
    return () => {
      socket.off('updateForAdminAllUsers', handleUpdateMyData);
    };
  }, [dispatch]); // empty dependency array means this effect runs once when the component mounts and cleans up when it unmounts


  if (getAllUsers.getAllUsersForAdminReducer.allUsers.data != undefined && getAllUsers.getAllUsersForAdminReducer.allUsers.data != "") {
    return (
      <>
        <h1 className="titleH1">Поиск пользователей</h1>
        <form
          className="searchContForm"
          onChange={handleSubmit}
          ref={selectForm}
        >
          <Input
            list="browsers"
            name="browser"
            id="browser"
            placeholder="Поиск пользователей"
            value={seatchVal}
          />
          <datalist id="browsers">
            <option value="Edge" />
            <option value="Firefox" />
            <option value="Chrome" />
            <option value="Opera" />
            <option value="Safari" />
          </datalist>
        </form>
        {getAllUsers.getAllUsersForAdminReducer.loading ?
          <div className="loading">
            <Spinner animation="border" size="sm" />

          </div>
          :
          ""
        }
        <div className="allUsers">

          <TableContainer>
            <Table size="sm">
              <Thead>
                <Tr>
                  <ModalEditUsers setShows={setShow} shows={show} data={data} currentPage={currentPage} />
                  <Th >

                  </Th>
                  <Th>Имя</Th>
                  <Th>Тел.</Th>
                  <Th >электронная почта</Th>
                  <Th>блокирован/Разблокировано</Th>
                  <Th>Удалить</Th>
                </Tr>
              </Thead>
              <Tbody>
                {getAllUsers.getAllUsersForAdminReducer?.allUsers?.data?.map(
                  (item, index) => {

                    return (
                      <Tr key={index}>
                        <Td onClick={() => setDataForEdit(item)}>
                          {
                            item.online ?
                              <Avatar src={item.img ? `/${item.img}` : "https://bit.ly/broken-link"}  >
                                <AvatarBadge boxSize='1.25em' bg='green.500' />
                              </Avatar>
                              :
                              <Avatar name={item.name} src={item.img ? `/${item.img}` : "https://bit.ly/broken-link"} />
                          }

                        </Td>
                        <Td onClick={() => setDataForEdit(item)} >
                          {item.name}
                        </Td>
                        <Td onClick={() => setDataForEdit(item)}>
                          {item.tel}{" "}
                        </Td>
                        <Td onClick={() => setDataForEdit(item)} >
                          {item.email}
                        </Td>
                        <Td onClick={() => setDataForEdit(item)}>
                          {
                            item.amiblocked ?
                              "заблокирован"
                              :
                              "разблокирован"
                          }
                        </Td>
                        <Td >
                          {" "}
                          <DeleteUsersByAdmin item={item} />{" "}
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </TableContainer>



        </div>
        <div className="paginationUsers">
          <Pagination total={getAllUsers.getAllUsersForAdminReducer.allUsers.totalPages} initialPage={getAllUsers.getAllUsersForAdminReducer.allUsers.currentPage} onChange={handlePageChange} />

        </div>
      </>
    );
  }
  return (
    <>
      <h1>Нет пользователей</h1>
      <form className="searchContForm" onChange={handleSubmit} ref={selectForm}>
        <input
          list="browsers"
          name="browser"
          id="browser"
          placeholder="Поиск компании"
        />
        <datalist id="browsers">
          <option value="Edge" />
          <option value="Firefox" />
          <option value="Chrome" />
          <option value="Opera" />
          <option value="Safari" />
        </datalist>
      </form>
    </>
  );
}
