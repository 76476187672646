import { useNavigate } from "react-router"
import "./noTaskPageLight.css"
import "./noTaskPageDark.css"
import { useSelector } from "react-redux";
import TaskMenegerHeader from "../tableTask/taskMenegerHeader/taskMenegerHeader";

export default function NoTaskPage({ searchByPerfomer, handleSelect,searchValue }) {
    const navigate = useNavigate();
    const getDarkModoe = useSelector(darkModes => darkModes)
    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "noTaskContDark" : "noTaskContLight"}>
             <TaskMenegerHeader searchByPerfomer={searchByPerfomer} handleSelect={handleSelect} searchValue={searchValue}/>
            <div>
                <div>
                   
                </div>
                <div>
                    <div>
                        <p>Вы еще не создали задание</p>
                        <p>Страница находится в разработке, добавляйте ее  в закладки следите за обновлениями</p>
                    </div>
                    <button onClick={() => navigate("/creattask")}>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                                <path d="M12.25 8C12.25 7.58579 11.9142 7.25 11.5 7.25C11.0858 7.25 10.75 7.58579 10.75 8V10.25H8.5C8.08579 10.25 7.75 10.5858 7.75 11C7.75 11.4142 8.08579 11.75 8.5 11.75H10.75V14C10.75 14.4142 11.0858 14.75 11.5 14.75C11.9142 14.75 12.25 14.4142 12.25 14V11.75H14.5C14.9142 11.75 15.25 11.4142 15.25 11C15.25 10.5858 14.9142 10.25 14.5 10.25H12.25V8Z" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 0.25C5.56294 0.25 0.75 5.06294 0.75 11C0.75 16.9371 5.56294 21.75 11.5 21.75C17.4371 21.75 22.25 16.9371 22.25 11C22.25 5.06294 17.4371 0.25 11.5 0.25ZM2.25 11C2.25 5.89137 6.39137 1.75 11.5 1.75C16.6086 1.75 20.75 5.89137 20.75 11C20.75 16.1086 16.6086 20.25 11.5 20.25C6.39137 20.25 2.25 16.1086 2.25 11Z" fill="white" />
                            </svg>
                        </div>
                        <p>Добавить задачу</p>
                    </button>
                </div>
            </div>
        </div>
    )
}