import { useState,Children, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import "./modalForView.css"
export default function ModalForView({children,item,name }) {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

 return (
    <>
      <div variant="primary" onClick={handleShow} className='childCont'>
          {children }
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Просмотр продукта</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <KitchenSinkExample name={name} item={item}/>
           
       
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          Закрыть
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

function KitchenSinkExample({name,item}) {

    return (
      <Card style={{ width: '18rem' }}>
       
              {
                (name == "ozon")?
               <div>
                    <Card.Img variant="top" src={item.images[0]&&item.images[0].file_name} />
              
               </div>
                :
               <div>
                 <Card.Img variant="top" src={item.alllistss.mediaFiles&&item.alllistss.mediaFiles[0]} />
                
               </div>
            }


             {
                (name == "ozon")?
               <div>
                     <Card.Body>
                        <Card.Title>{item.productName}</Card.Title>
                       
                        </Card.Body>
                        <ListGroup className="list-group-flush">
                        <ListGroup.Item>Артикул : {item.arcticule}</ListGroup.Item>
                        <ListGroup.Item>Цена :  {item.price}</ListGroup.Item>
                        <ListGroup.Item>Продукт id : {item.product_id}</ListGroup.Item>

                        
                        </ListGroup>
                      
              
               </div>
                :
               <div>
                 <Card.Body>
                    <Card.Title>{item.alllistss.object}</Card.Title>
                   
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                    <ListGroup.Item>Артикул : {item.alllistss.imtID}</ListGroup.Item>
                    <ListGroup.Item>Цена : {item.price.price}</ListGroup.Item>
                  
                    
                    </ListGroup>
                   
                
               </div>
            }
       
      </Card>
    );
  }