import FileUploadTes from "../test/upload multiple files with progress indicator/uploadTestProgress"
import "./homeLight.css"

export default function Home() {
    return (
        <div className="glavimg">
            <img src="/glav.svg" alt="Glav SVG" />
            {/* <FileUploadTes /> */}
        </div>
    )
}