import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import { fetchUsers } from "../../../../redux/reducer/postFetcReducer/action/action";

export default function ChangeDirector({ creaTorTask, setcreaTorTask, onlineUsers, adminInfo }) {

  const dispatch = useDispatch();
  // здесь не получаем всех сотрудников на этой компании
  const gerChekcedCompany = useSelector((allWorkers) => allWorkers);
  const [users, setUsers] = useState([]);
  const [performer, setperformer] = useState("");
  const openModalRef = useRef(null)
  const getDarkModoe = useSelector(darkModes => darkModes);
  let onlineuser = useSelector(onlineUsers => onlineUsers)
  // здесь мы добавляем на состояние всех сотрудников




  useEffect(() => {

    setUsers(gerChekcedCompany.rootReducer.users.data);
  }, []);

  useEffect(() => {

    // This effect will run when gerChekcedCompany.getMayInfoReducer.myinfo._id changes.
    if (gerChekcedCompany.getMayInfoReducer.myinfo._id == creaTorTask) {

      setperformer(gerChekcedCompany.getMayInfoReducer.myinfo);
    } else if (adminInfo._id == creaTorTask) {
      setperformer(adminInfo);
      console.log(performer);
    }
    else {
      for (let i = 0; i < users.length; i++) {
        if (users.workerID === creaTorTask) {
          setperformer(users);
        }

      }
    }

  }, [creaTorTask]);




  // После нажатия кнопку добавляем нового сотрудника и обновляем состояние
  const perfomer = (e) => {
    setperformer(e)
    setcreaTorTask(e.workerID)
  };
  // здесь ищем сотрудника по номеру телефона и имени
  const searchPerfomer = (e) => {

    const regexPattern = new RegExp(e, 'i');
    let x = gerChekcedCompany.rootReducer.users.data.filter(item => regexPattern.test(item.name) || regexPattern.test(item.tel))
    setUsers(x)
    if (e == "") {
      dispatch(fetchUsers())
      setUsers(gerChekcedCompany.rootReducer.users.data)
    }

  }

  const openModal = () => {
    openModalRef.current.style.display = "flex"
    setUsers(gerChekcedCompany.rootReducer.users.data)
  }

  const closeModal = () => {
    openModalRef.current.style.display = "none"
  }

  useEffect(() => {
    openModalRef.current.style.display = "none"
  }, [])
  return (
    <>
      <div id="myModalPerforem" ref={openModalRef} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "modalPerfomerDark" : "modalPerfomer"}>


        <div className="modal-contentPerfomer">
          <div class="modal-headerPerfomer">
            <div>
              <p>Изменить постановщика</p>


              <div onClick={closeModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M1 1L15 15M1.00003 15L8.00003 8L15 1" stroke="#393939" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </div>
            </div>
            <label htmlFor="searchPerformer">

              <input type="text" placeholder="Поиск" onChange={(e) => searchPerfomer(e.target.value)} id="searchPerformer" />
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="#A8A8A8" />
                  <path d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L18.5304 17.4698Z" fill="#A8A8A8" />
                </svg>
              </div>
            </label>
          </div>
          <div className="modal-bodyPerfomer">
            <div className="executorCont">
              {users?.map(
                (item, index) => {

                  return (
                    <div
                      key={index}
                      className="executor"
                      onClick={(e) => perfomer(item)}

                    >
                      <div>
                        {
                          (() => {
                            if (item.workerID == performer.workerID) {
                              return <div className="chekedDivTrue">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                                  <path d="M1.94635 3.96898C1.65345 3.67608 1.17858 3.67608 0.885686 3.96898C0.592792 4.26187 0.592792 4.73674 0.885686 5.02963L3.60804 7.75199C3.90093 8.04488 4.3758 8.04488 4.6687 7.75199L11.113 1.30767C11.4059 1.01478 11.4059 0.539907 11.113 0.247014C10.8201 -0.0458796 10.3452 -0.0458796 10.0524 0.247014L4.13837 6.161L1.94635 3.96898Z" fill="white" />
                                </svg>
                              </div>
                            } else {
                              return <div className="chekedDivFalse"></div>
                            }
                          })()
                        }

                        {
                          onlineuser.AllOnlineUsersReducer.allOnlineUsers?.includes(item.workerID) ?
                            <Avatar size="xs" name="Christian Nwamba" src={item.img ? `/${item.img}` : "https://bit.ly/code-beast"} >
                              <AvatarBadge boxSize='0.9em' bg='green.500' />
                            </Avatar>
                            :
                            <Avatar size="xs" name="Christian Nwamba" src={item.img ?`/${item.img}` : "https://bit.ly/code-beast"} />
                        }
                        <p className="perfName">{item.name}</p>
                        <p className="perfTel">{item.tel}</p>
                        {/* <p className="perfEmail"> email: {item.email}</p> */}
                      </div>


                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="modal-footerPerfomer">
            <button onClick={closeModal}>
              <div>

                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 16 16" fill="white">
                  <path d="M1 1L15 15M1.00003 15L8.00003 8L15 1" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </div>
              <div><p> Пропустить</p></div>
            </button>
          </div>
        </div>

      </div>




      <div className="ChangedirectorEdit">
      <p>Постановщик:</p>
        {
          (() => {
            if (performer != "") {
              return (
                <div>
    
                  {
                    performer.tel ?
                      <div style={{ display: 'flex' }} onClick={() => {
                        if (adminInfo._id == gerChekcedCompany.getMayInfoReducer.myinfo._id) {
                          openModal()

                        }

                      }}>
                        <p className="forDekstop">Постановщик:</p>
                        {
                          (() => {
                            if (performer._id) {
                              return onlineuser.AllOnlineUsersReducer.allOnlineUsers?.includes(performer.workerID || performer._id) ?
                                <Avatar size="sm" name="Christian Nwamba" src={window.location.origin + "/" + performer.img} style={{ marginRight: "5px", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)" }} >
                                  <AvatarBadge boxSize='0.9em' bg='green.500' />
                                </Avatar>
                                :
                                <Avatar size="sm" name="Christian Nwamba" src={window.location.origin + "/" + performer.img} style={{ marginRight: "5px", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)" }} />
                            } else if (performer.workerID) {
                              return onlineuser.AllOnlineUsersReducer.allOnlineUsers?.includes(performer.workerID || performer._id) ?
                                <Avatar size="sm" name="Christian Nwamba" src={performer.img} style={{ marginRight: "5px", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)" }} >
                                  <AvatarBadge boxSize='0.9em' bg='green.500' />
                                </Avatar>
                                :
                                <Avatar size="sm" name="Christian Nwamba" src={window.location.origin + "/" + performer.img} style={{ marginRight: "5px", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)" }} />
                            }
                          })()
                        }


                        {
                          performer.name ?
                            <p className="perfName">{performer.name}</p>
                            :
                            <p className="perfName">{performer.tel}</p>
                        }
                        {/* {
                          performer.tel ?
                            <p className="perfTel">{performer.tel}</p>
                            :
                            ""
                        } */}
                        {/* {
                        performer.email ?
                          <p className="perfEmail">{performer.email}</p>
                          :
                          ""
                      } */}

                      </div>
                      :
                      ""
                  }
                </div>
              )
            } else {
              return <button className="perfomerOpenModal" onClick={openModal}>Изменить постановщика</button>
            }
          })()
        }
      </div>


      {/* =========================================================== */}


    </>
  )
}