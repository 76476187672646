import { NavLink, Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import "./chatNavFilterLight.css"
import "./chatNavFilterDark.css"
import { useDispatch, useSelector } from "react-redux"
import CreatChatModal from "../creatChatModal/creatChatModal"
import SearchChat from "../searchChat/searchChat"
import ChatItem from "../chatItem/chatItem"
import { useEffect, useRef, useState } from "react"
import Tarde from "../trade/trade"
import { perexodChatMenu } from "../../../../redux/reducer/perexodChatMenu/actiom/action"
import { getItemChatAction } from "../../../../redux/reducer/getChatItem/action/action"
import axios from "axios"
import { getMessageItem } from "../../../../redux/reducer/getMessageItem/action/action"
import { getChatInfoByIDAction } from "../../../../redux/reducer/getChatInfoByID/action/action"
import DiscussionSidebar from "../discussion_sidebar/discussionSidebar"
import CreatPrivateChat from "../creatPrivateChat/creatChatPrivate"
import ChatItemPrivate from "../chatItemPrivate/chatItemPrivate"
import sockets from "../../../../socket/socket";
import { getCurrentSubMessageAction } from "../../../../redux/reducer/getCurrentSubMessage/action/action"
const socket = sockets;

export default function ChatNavFilter() {
    const getDarkModoe = useSelector(darkModes => darkModes);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const ChatOulatCont = useRef();
    const leftSideNanCont = useRef();
    const otherChatOptions = useRef();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // const perexeodMenu = useSelector(perexeodMenu => perexeodMenu).PerexodeChatMenuReducer.chekced;
    const getItemChat = useSelector(itemChat => itemChat);
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const chatID = searchParams.get('chatID');
    const thread = searchParams.get('thread_message_id');
    const discussion_sidebar = searchParams.get('discussion_sidebar');
    const [countMessage, setcountMessage] = useState(0)

    useEffect(() => {
        console.log(location.pathname);
        if (location.pathname == "/chat/conversations") {
            dispatch(getItemChatAction("/getItemChat/api?filter=Conversations"));


        } else if (location.pathname == "/chat/public") {
            dispatch(getItemChatAction("/getItemChat/api?filter=Public"));


        } else if (location.pathname == "/chat/private") {
            dispatch(getItemChatAction("/getItemChatPrivate/api?filter=private"));


        }
        else {
            dispatch(getItemChatAction("/getItemChatPrivate/api"));
        }

    }, [location.pathname])



    useEffect(() => {

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        const adjustLayout = () => {
            if (windowWidth <= 1024) {
                if (chatID == null && thread == null && discussion_sidebar == null) {
                    adjustMobileLayout();
                } else if (chatID && thread == null && discussion_sidebar == null) {
                    adjustChatOnlyLayout();
                } else if (chatID && thread) {
                    adjustChatThreadLayout();
                }
                else if (chatID && discussion_sidebar) {
                    adjustChatThreadLayout();
                }
            } else {
                adjustDesktopLayout();
            }
        };

        const adjustMobileLayout = () => {
            leftSideNanCont.current.style.width = "100%";
            ChatOulatCont.current.style.width = "0";
            ChatOulatCont.current.style.overflow = "hidden";
            otherChatOptions.current.style.width = "0";
            otherChatOptions.current.style.overflow = "hidden";
        };

        const adjustChatOnlyLayout = () => {
            leftSideNanCont.current.style.width = "0";
            otherChatOptions.current.style.width = "0";
            leftSideNanCont.current.style.overflow = "hidden";
            otherChatOptions.current.style.overflow = "hidden";
            ChatOulatCont.current.style.width = "100%";
        };

        const adjustChatThreadLayout = () => {
            leftSideNanCont.current.style.width = "0";
            leftSideNanCont.current.style.overflow = "hidden";
            otherChatOptions.current.children[0].children[0].style.width = "100%";
            ChatOulatCont.current.style.width = "0";
            ChatOulatCont.current.style.overflow = "hidden";
            otherChatOptions.current.style.width = "100%";
        };

        const adjustDesktopLayout = () => {
            leftSideNanCont.current.style = {};
            ChatOulatCont.current.style = {};
            otherChatOptions.current.style = {};
            otherChatOptions.current.children[0].children[0].style.width = "100%";
            otherChatOptions.current.style.transition = "0.2s";

            if (thread === null && discussion_sidebar === null) {
                otherChatOptions.current.style.width = "0";
            }
            else {
                otherChatOptions.current.style.width = "364px";
                otherChatOptions.current.style.overflow = "none";

            }
        };

        adjustLayout();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth, searchParams.get('chatID'), searchParams.get('thread_message_id'), searchParams.get('discussion_sidebar')]);

    const otherChatOptionsFuncClose = async () => {
        if (otherChatOptions.current) {
            otherChatOptions.current.style.overflow = "hidden";
            otherChatOptions.current.style.width = "0";
        }
        dispatch(perexodChatMenu("closeOther"));
        navigate(`?chatID=${chatID}`);

    }

    // console.log(getItemChat.getChatItemReducer.chatItems.data,"getItemChat.getChatItemReducer.chatItems.data");
    const handleSearchChat = (e) => {
        if (location.pathname == "/chat/conversations") {
            if (e.length > 0) {
                dispatch(getItemChatAction(`/getsearchChatByMainMenu/searchChatByMainMenuConversations/api?searchInputValue=${e}`));
            } else {
                dispatch(getItemChatAction("/getItemChat/api?filter=Conversations"));
            }
        }
        // public
        else if (location.pathname == "/chat/public") {
            if (e.length > 0) {
                dispatch(getItemChatAction(`/getsearchChatByMainMenu/searchChatByMainMenuPublic/api?searchInputValue=${e}`));
            } else {
                dispatch(getItemChatAction("/getItemChat/api?filter=Public"));
            }
        }


        // if (location.pathname == "/chat/conversations") {
        //     console.log("conversations");
        //     axios.get(`/getsearchChatByMainMenu/searchChatByMainMenuConversations/api?searchInputValue=${e}`)
        //         .then(function (response) {
        //             // handle success
        //             console.log(response.data.data,"searchData");
        //         })
        //         .catch(function (error) {
        //             // handle error
        //             console.log(error);
        //         })
        //         .finally(function () {
        //             // always executed
        //         });
        // }


    }

    // useEffect(() => {
    //     if (chatID !== null && chatID !== "null") {
    //         dispatch(getChatInfoByIDAction(`/getChatInfoByID/api?ID=${chatID}`))
    //         dispatch(getMessageItem(`/getItemMessages/api?chatID=${chatID}`));
    //     }

    // }, [chatID])

    const getChatItem = (item) => {
      


    };

    const closediscussion_sidebar = () => {
        if (otherChatOptions.current) {
            otherChatOptions.current.style.overflow = "hidden";
            otherChatOptions.current.style.width = "0";
        }
        dispatch(perexodChatMenu("closeOther"));

        navigate(`?chatID=${chatID}`);

    }
    useEffect(() => {
        function onFooEvent() {
           
            if (chatID !== null && chatID !== "null") {
                dispatch(getMessageItem(`/getItemMessages/api?chatID=${chatID}`));
                dispatch(getChatInfoByIDAction(`/getChatInfoByID/api?ID=${chatID}`))
                if (thread !== null) {
                    dispatch(getCurrentSubMessageAction(`/getMessageByID/api?thread=${thread}`))
                }
              
            }
        }

        if (socket) {
            socket.on('updateCreatMessage', onFooEvent);

            return () => {
                socket.off('updateCreatMessage', onFooEvent);
            };
        }
    }, [socket, chatID, thread]); // Добавляем socket в зависимости
    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "chatNavFilterContDark" : "chatNavFilterContLight"}>

            <div className="leftSideNanCont" ref={leftSideNanCont}>



                <div className="filterCont">

                    <NavLink to={"public"} onClick={otherChatOptionsFuncClose}>Публичный</NavLink>
                    <NavLink to={"conversations"} onClick={otherChatOptionsFuncClose}>Беседы</NavLink>
                    <NavLink to={"private"} onClick={otherChatOptionsFuncClose}>
                        <div className="svgCont">
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.00002 6.125C4.24062 6.125 3.62502 6.74061 3.62502 7.5C3.62502 8.25939 4.24062 8.875 5.00002 8.875C5.75941 8.875 6.37502 8.25939 6.37502 7.5C6.37502 6.74061 5.75941 6.125 5.00002 6.125ZM4.37502 7.5C4.37502 7.15482 4.65484 6.875 5.00002 6.875C5.34519 6.875 5.62502 7.15482 5.62502 7.5C5.62502 7.84518 5.34519 8.125 5.00002 8.125C4.65484 8.125 4.37502 7.84518 4.37502 7.5Z" fill="#858C99" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.00002 0.625C3.4122 0.625 2.12502 1.91218 2.12502 3.5V3.7614L2.11159 3.76571C1.23633 4.0501 0.550118 4.73632 0.265729 5.61158C0.124792 6.04534 0.124874 6.56594 0.125008 7.41908V7.58092C0.124874 8.43406 0.124792 8.95466 0.265729 9.38842C0.550118 10.2637 1.23633 10.9499 2.11159 11.2343C2.54535 11.3752 3.06595 11.3751 3.91909 11.375H6.08095C6.93408 11.3751 7.45468 11.3752 7.88844 11.2343C8.7637 10.9499 9.44991 10.2637 9.7343 9.38842C9.87524 8.95467 9.87516 8.43406 9.87502 7.58093V7.41907C9.87516 6.56594 9.87524 6.04533 9.7343 5.61158C9.44991 4.73632 8.7637 4.0501 7.88844 3.76571L7.87502 3.7614V3.5C7.87502 1.91218 6.58784 0.625 5.00002 0.625ZM3.95803 3.625C3.51843 3.62498 3.16757 3.62497 2.87502 3.64153V3.5C2.87502 2.3264 3.82641 1.375 5.00002 1.375C6.17362 1.375 7.12502 2.3264 7.12502 3.5V3.64153C6.83246 3.62497 6.48161 3.62498 6.04201 3.625H3.95803ZM2.56231 4.42665C2.86111 4.37631 3.26409 4.375 4.00002 4.375H6.00002C6.73594 4.375 7.13892 4.37631 7.43772 4.42665C7.52034 4.44057 7.59159 4.45786 7.65668 4.47901C8.30361 4.68921 8.81081 5.19641 9.02101 5.84334C9.12039 6.14919 9.12502 6.54017 9.12502 7.5C9.12502 8.45983 9.12039 8.85081 9.02101 9.15666C8.81081 9.80359 8.30361 10.3108 7.65668 10.521C7.35082 10.6204 6.95985 10.625 6.00002 10.625H4.00002C3.04019 10.625 2.64921 10.6204 2.34336 10.521C1.69642 10.3108 1.18922 9.80359 0.979021 9.15666C0.879643 8.85081 0.875017 8.45983 0.875017 7.5C0.875017 6.54017 0.879643 6.14919 0.979021 5.84334C1.18922 5.19641 1.69642 4.68921 2.34336 4.47901C2.40844 4.45786 2.47969 4.44057 2.56231 4.42665Z" fill="#858C99" />
                            </svg>
                        </div>
                        Приватный

                    </NavLink>

                </div>

                <SearchChat handleSearchChat={handleSearchChat} />
                {
                    location.pathname == "/chat/private" ?
                        <CreatPrivateChat />
                        :
                        <CreatChatModal />
                }

                <div className="listChatItem">
                    {
                        getItemChat.getChatItemReducer.chatItems.data?.map((item, index) => {
                            if (item.chatOptions.chatType == "canal" || item.chatOptions.chatType == "Conversation" || item.chatOptions.chatType == "public") {
                                if (item._id == chatID) {
                                    return <ChatItem key={index} item={item} thisClass={"active"} getChatItem={getChatItem} />
                                } else {
                                    return <ChatItem key={index} item={item} thisClass={""} getChatItem={getChatItem} />
                                }
                            } else if (item.chatOptions.chatType == "private") {


                                if (item._id == chatID) {

                                    return <ChatItemPrivate key={index} item={item} thisClass={"active"} getChatItem={getChatItem} />
                                } else {
                                    return <ChatItemPrivate key={index} item={item} thisClass={""} getChatItem={getChatItem} />
                                }
                            }

                        })
                    }





                </div>
            </div>

            <div className="ChatOulatCont" ref={ChatOulatCont}>
                <Outlet />
            </div>
            <div className="otherChatOptions" ref={otherChatOptions}>


                {
                    thread && <Tarde otherChatOptionsFuncClose={otherChatOptionsFuncClose} />
                }
                {
                    discussion_sidebar && <DiscussionSidebar otherChatOptionsFuncClose={closediscussion_sidebar} />
                }

                <div><p></p></div>
            </div>
        </div>
    )
}