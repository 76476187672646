import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router"
import ChilMNodalEdit from "../childModalEdit/childModalEdit";
import { useToast } from '@chakra-ui/react'
import PerfomerEdit from "../perfomerEdit/perfomerEdit";
import DropdownMobilePioritet from "../tableTask/editPrioritetForMobile/editPrioritetForMobile";
import Prioritet from "../prioritet/prioritet";
import DropdownMobileStatus from "../tableTask/EditStatusForMobile/editStatusForMobile";
import Status from "../status/status";
import MetkaEdit from "../metka/metka";
import UploadFileTaskMenegerEditChilds from "../uploadFile/uploadFileChildEdit";
import CommentBox from "../commentBox/commentBox";
import sockets from "../../../../socket/socket";
import StartDateModalEdit from "../startDateModalEdit/startDateModalEdit";
import EndDateModalEdit from "../endDateModalEdit/endDateModalEdit";
import CommentBoxChildEdit from "../commentBoxChildEdit/commentBoxChildEdit";

const socket = sockets

export default function EditChild() {

  const [item, setItem] = useState(null)
  let getCompanyID = useSelector(compID => compID.checkedSessionReducer)
  let getmyinfo = useSelector(compID => compID.getMayInfoReducer)
  const [adminInfo, setadminInfo] = useState("")
  const [load, setload] = useState(false)
  const [loadBTN, setloadloadBTN] = useState(false)
  const [taskInfo, setTasktaskInfo] = useState("");
  const [Taskfiles, setTaskfiles] = useState([]);
  const [price,setPrice]=useState(0);
  const toast = useToast()
  let { id, index } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post('/findTaskByUrl', {
        taskID: id
      })
      .then(function (response) {
        setItem(response.data.data);
        setadminInfo(response.data.adminIfno);
        console.log(response.data.data, "itemaaaaaaaaaaaaaaaaaaaaa"); // Log 'item' after it has been set
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);



  function update(params) {
    socket.emit('getallTaskforUpdateServer', { room: getCompanyID.checkedSession });
  }

  useEffect(() => {


    // update all task for my data
    socket.on('getAllTaskANDupdate', () => {

      setload(true)
      console.log("UPDATEtASK");


      axios.post('/findTaskByUrl', {
        taskID: id
      })
        .then(function (response) {
          console.log(response.data.data, "getCurrentData");
          setItem(response.data.data)
        })
        .catch(function (error) {
          console.log(error);
        });

    });


    return () => {

      socket.off('getAllTaskANDupdate');

    };
  }, []);

  const handleGetNotify = () => {
    socket.emit('getNotifyEmit');
  }

  const sendServer = () => {

  }
  const handleClose = () => { }


  const [comments, setComments] = useState([]);

  const currentDate = new Date();
  const adjustedDate = new Date(currentDate.getTime() - currentDate.getTimezoneOffset() * 60000);
  const formattedDate = adjustedDate.toISOString().slice(0, 16);
  const [startDate, setStartDate] = useState(formattedDate);
  const [endDate, setEndDate] = useState(formattedDate);
  const [status, setStatus] = useState("Запланирована");
  const [prioritet, setPrioritet] = useState("Низший");
  const [metka, setMetka] = useState([]);
  const [performer, setperformer] = useState("");
  const [aboutTask, setAboutTask] = useState("");
  const [file, setfile] = useState([]);
  const [perfomerID, setperfomerID] = useState([]);
  const [endDateConfirm, setDndDateConfirm] = useState("");
  const [allitem, setAllitem] = useState()
  const [editAllTask, seteditAllTask] = useState()
  const [nameCurrentSubTask, setnameCurrentSubTask] = useState("")
  
  const getDarkModoe = useSelector(darkModes => darkModes)
  const modalRef = useRef(null);


  const deleteItemByIndex = () => { }




  useEffect(() => {


    setComments(item?.alltask[0]?.child[index]?.comments)
    setStartDate(item?.alltask[0]?.child[index]?.startDate)
    setEndDate(item?.alltask[0]?.child[index]?.endDate)
    setAboutTask(item?.alltask[0]?.child[index]?.taskInfo)
    setPrioritet(item?.alltask[0]?.child[index]?.prioritet)
    setStatus(item?.alltask[0].child[index]?.status)
    setperformer(item?.alltask[0]?.child[index].performer)
    setfile(item?.alltask[0]?.child[index]?.file)
    setMetka(item?.alltask[0]?.child[index]?.metka)
    setTasktaskInfo(item?.alltask[0]?.child[index]?.taskInfo)
    setnameCurrentSubTask(item?.alltask[0]?.child[index]?.msg)
    setTaskfiles(item?.alltask[0]?.child[index]?.Taskfiles)
    setPrice(item?.alltask[0]?.child[index]?.price)
    console.log(item?.alltask[0]?.child[index].performer,"item?.alltask[0]?.child[index]?.performer");

  }, [item?.alltask[0]?.child[index]?.comments, item?.alltask[0]?.child[index]?.startDate, item?.alltask[0]?.child[index]?.endDate, item?.alltask[0]?.child[index]?.taskInfo, item?.alltask[0]?.child[index]?.prioritet, item?.alltask[0]?.child[index]?.status, item?.alltask[0]?.child[index]?.performer, item?.alltask[0]?.child[index]?.file, item?.alltask[0]?.child[index]?.metka,item?.alltask[0]?.child[index]?.price])



  //     setComments(item?.alltask[0].child[index].comments)
  //     setStartDate(item?.alltask[0].child[index].startDate)
  //     setEndDate(item?.alltask[0].child[index].endDate)
  //     setAboutTask(item?.alltask[0].child[index].aboutTask)
  //     setPrioritet(item?.alltask[0].child[index].prioritet)
  //     setStatus(item?.alltask[0].child[index].status)
  //     setperformer(item?.alltask[0].child[index].performer)
  //     setfile(item?.alltask[0].child[index].file)
  //     setMetka(item?.alltask[0].child[index].metka)


  // }, [item?.alltask[0].child[index].comments, item?.alltask[0].child[index].startDate, item?.alltask[0].child[index].endDate, item?.alltask[0].child[index].aboutTask, item?.alltask[0].child[index].prioritet, item?.alltask[0].child[index].status, item?.alltask[0].child[index].performer, item?.alltask[0].child[index].file, item?.alltask[0].child[index].metka])







  // добавляет состояние и отправлять на сервер
  const uploadChildImg = async (task, taskID) => {
    console.log(taskID, "taskID");
  
    if (!task || task.length === 0) {
      console.error('Task is undefined or empty');
      return;
    }
  
    if (!task[0].child || task[0].child.length === 0) {
      console.error('Task has no children');
      return;
    }
  
    for (let i = 0; i < task[0].child.length; i++) {
      const child = task[0].child[i];
      
      if (!child.file || child.file.length === 0) {
        console.warn(`Child ${i} has no files`);
        continue; // Skip this iteration if there are no files
      }
  
      const formData = new FormData(); // Create a new FormData object for each child
      for (let j = 0; j < child.file.length; j++) {
        formData.append('files', child.file[j]);
        console.log(child.file[j]);
      }
      formData.append('indexChild', i);
      formData.append('task', JSON.stringify(task[0]));
      formData.append('taskID', taskID);
  
      try {
        const response = await axios.post('/uploadTaskMenegerChild/api', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Upload successful:', response.data);
      } catch (error) {
        console.error('Error uploading files:', error);
      }
    }
  };
  
  const sendserver = async () => {
    if (performer.length === 0) {
      toast({
        position:"top",
        title: 'Ошибка',
        description: "Для  создания подзадач вам необходимо выбрать исполнителя",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return ;
     }
    await sendSeverCreatNotifyForTask();
    setloadloadBTN(true)
    item.alltask[0].child[index].comments = comments
    item.alltask[0].child[index].performer = performer
    item.alltask[0].child[index].startDate = startDate
    item.alltask[0].child[index].endDate = endDate
    item.alltask[0].child[index].status = status
    item.alltask[0].child[index].prioritet = prioritet
    item.alltask[0].child[index].taskInfo = taskInfo
    item.alltask[0].child[index].file = file
    item.alltask[0].child[index].metka = metka
    item.alltask[0].child[index].price = price
    console.log(performer);
    if (performer.email) {
      console.log(performer.email);
      axios.post('/sendEmail/api', {
        perfomerEmail: performer.email
      })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    item.perfomerID = perfomerID
    item.allmetka = metka


    axios.post('/findAndUpdateTask', {
      itemID: item._id,
      currentTask: item.alltask,
      item,
      perfomerID: item.alltask[0],
      allmetka: metka,
      creaTorTask: item.creatorTasks,
      isLooping: item.isLooping,
      selectDays: item.selectDays,
      loopingStartDate: item.loopingStartDate,
      everythreedays: item.everythreedays
    })
      .then(function (response) {
        console.log(response);
        update()
        uploadChildImg(item.alltask, item._id);
        handleClose()
        setloadloadBTN(false)

      })
      .catch(function (error) {
        console.log(error);
        setload(false)
      });

    // sendServer()
    // handleClose()
    // handleCloseChild()
  }

  // обрабатывать добавить исполнителя 
  const HandleSetPerfomerId = (e) => {

    setperfomerID([e])
    setperformer(e)
    item.performer = performer
  }

  // обработать удалить Исполнитель 
  const HandleDeletePerfomerId = (e) => {
    setperformer("")
    setperfomerID([])
    item.performer = performer
  }
  // этот функция отправляет для подтверждения дату

  const handleDate = () => {

    axios.post('/notifyConfirm', {
      eidtTaskID: item._id,
      isChild: true,
      adminIDTask: item.creatorTasks.toString(),
      typenotify: "date",
      indexTask: index,
      senderName: getmyinfo.myinfo.name,
      senderTel: getmyinfo.myinfo.tel,
      senderEmail: getmyinfo.myinfo.email,
      text: "",
      messageConfirm: endDateConfirm,
      taskName: nameCurrentSubTask,
      oldDate: item.alltask[0].child[index].endDate

    })
      .then(function (response) {
        console.log(response);
        handleGetNotify()
        toast({
          position: "top",
          title: 'Отправлено на подтверждение постановщику задачи',
          description: "Отправлено на подтверждение постановщику задачи",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // этот функция отправляет для подтверждения статус 
  const handleStatus = () => {
    axios.post('/notifyConfirm', {
      eidtTaskID: item._id,
      isChild: true,
      adminIDTask: item.creatorTasks.toString(),
      typenotify: "status",
      indexTask: index,
      senderName: getmyinfo.myinfo.name,
      senderTel: getmyinfo.myinfo.tel,
      senderEmail: getmyinfo.myinfo.email,
      text: "",
      messageConfirm: endDate,
      taskName: nameCurrentSubTask,
      oldDate: item.alltask[0].child[index].endDate

    })
      .then(function (response) {
        console.log(response);
        handleGetNotify()
        toast({
          position: "top",
          title: 'Отправлено на подтверждение постановщику задачи',
          description: "Отправлено на подтверждение постановщику задачи",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  // обрабатывать файл изменений
  const handleChangeFile = (e) => {

    setfile(e)

  }


  async function sendSeverCreatNotifyForTask(e) {
    const taskID = await axios.get(`/findTaskById/api?taskID=${item._id}`);

    if (taskID.data.data) {
      if (new Date(taskID.data.data.alltask[0].child[index].endDate).getTime() != new Date(endDate).getTime()) {
        if (item.alltask[0].performer) {
          // получаем информацию о пользователе
          const userInfo = await axios.get(`/getuserByID/api?userID=${item.creatorTasks}`);
          let mount = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
          const covertDate = new Date(endDate).getDay() + " " + mount[new Date(endDate).getMonth()] + " " + new Date(endDate).getFullYear()
          let notifyText = `${userInfo.data.data.name} изменил срок окончания <a target="_blank" class="taskName" href="getCurrentTask/${item._id}">${item.alltask[0].taskName}</a> на ${covertDate}  конца.`
          axios.post('/creatNotifyForTaskInfo/api', {
            // здесь не проверяем Это задача и подзадача
            isChild: true,
            // здесь мы отправляем полное задание
            task: item,
            //  // если это подзадача тогда получаем индекс этого подзадачу
            index,
            // здесь мы отправляем текст для уведомлений
            notifyText,
            // кому нужно отправить этот уведомление мы берём идентификатор этого пользователя
            notificationRecipientID: item.alltask[0].child[index].performer.workerID,
            // информация пользователя для отправления уведомлений на электронную почту
            emailInfo: {
              // здесь мы отправляем идентификатор пользователя
              userIdForEmail: item.alltask[0].child[index].performer.workerID,
              // текст для заголовки
              subject: "Смена срока окончания задачи",
              // текст для электронную почту
              textEmail: `${userInfo.data.data.name} изменил срок окончания <a target="_blank" class="taskName" href="https://xn--h1alceggc.xn--p1ai/editChild/${item._id}/${index}">${item.alltask[0].taskName}</a> на ${covertDate}  конца.`
            }
          })
            .then(function (response) {
              console.log(response);
              socket.emit('getNotifyEmit');
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    }




  }

  const setTaskInfoFunc = (e) => {

    setTasktaskInfo(e)
  }
  const handleDeleteFile = (e) => {
    setTaskfiles((prevFiles) => prevFiles.filter((_, index) => index !== e));
  }
  const handleCloseChildModal = (e) => {


  }

  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "CreatTaskDark" : "CreatTask"}>
          <div className="backTask">
        <div onClick={()=>navigate("/task")}> 
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M15 6L9 12L15 18" stroke="#474747" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <p>Назад</p>
        </div>
      </div>
    <div className="headerTask">

      <div>

        {
          prioritet ?
            <DropdownMobilePioritet isChild={true} prioritetGet={prioritet} allitemForStatus={item} index={index} update={update} />
            :
            <Prioritet setPrioritet={setPrioritet} prioritet={prioritet} />
        }
        {
          status ?
            <DropdownMobileStatus isChild={true} statusGet={status} allitemForStatus={item} index={index} update={update} />
            :
            <Status setStatus={setStatus} status={status} />
        }
      </div>
      <div>
       
      </div>
    </div>
    <div className="choosePerformerDiv">
     
      <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "selectPerformerDark" : "selectPerformerLight"} >
        <PerfomerEdit performerS={performer} setperformerS={setperformer} HandleSetPerfomerId={HandleSetPerfomerId} HandleDeletePerfomerId={HandleDeletePerfomerId} />
      </div>
      
    </div>
    <div className="creatTaskButton" style={{ gap: "10px" }}>
      <button colorScheme="blue" onClick={(e) => sendserver(e)}> <p>Сохранить подзадачу</p></button>
    </div>
    <div className="sectionOtherTask">
      <div>
        <div>
          <label htmlFor="taskNameInput">
            <p>Выберите название задачи </p>
            <input type="text"
              id="taskNameInput"
              placeholder="Введите название задачи"
              className="nameTaskInput"
              value={nameCurrentSubTask}
              disabled

            />
          </label>
        </div>
        <div>
          <label htmlFor="priceCreatInput">
            <p>Цена</p>
            <input type="number"
              id="priceCreatInput"
              placeholder="Цена"
              className="nameTaskInput"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </label>
        </div>

        <div className="dateCreatDiv">
          <div>
            <div>
              <div>
                <p>Сроки: начало</p>
                <StartDateModalEdit startDate={startDate} setStartDate={setStartDate} handleDate={handleDate} />
              </div>
              <div>
                <p>Сроки: конец</p>
                <EndDateModalEdit endDate={endDate} setEndDate={setEndDate} handleDate={handleDate} setDndDateConfirm={setDndDateConfirm} allitem={item} endDateConfirm={endDateConfirm} />
              </div>
            </div>
          </div>
        </div>
        <div>
          <label>
            <p>Добавьте описание задачи</p>
            <textarea placeholder='Добавьте описание задачи' value={taskInfo} onChange={(e) => setTaskInfoFunc(e.target.value)} />
          </label>

          <UploadFileTaskMenegerEditChilds files={file} setFile={setfile} Taskfiles={Taskfiles} setTaskfiles={setTaskfiles} allitem={item} index={index} isChild={true} handleChangeFile={handleChangeFile} handleDeleteFile={handleDeleteFile} key={index} />
        </div>
      </div>
      <div>
       
         <MetkaEdit setMetka={setMetka} metka={metka} />
        <CommentBoxChildEdit setComments={setComments} comments={comments} index={index} isChild={true} allitem={item} editAllTask={editAllTask} update={update} /> 
      </div>
    </div>
  
  </div>
  )
}