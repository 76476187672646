import "./messageItemsLight.css";
import "./messageItemsDark.css";
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react';
import { useSelector } from "react-redux";
import SuppliedEmoticons from "../supplied emoticons/suppliedEmoticons";

import OpenModalForSubComANDSmile from "../openModalForSubComANDSmile/openModalForSubComANDSmile";
import { useEffect, useState } from "react";
import FileModal from "../fileModal/fileModal";
import WhoReadThisMessage from "../who read this message/whoReadThisMessage";




export default function MessageItems({ item, handleNavigate, child, index }) {
    const getDarkModoe = useSelector(darkModes => darkModes);
    const [isOpenModal, setisOpenModal] = useState(false);




    const handleContextMenu = (e) => {
        e.preventDefault();

        // Закрыть все модальные окна
        const modalPrefix = "openModalForSubComANDSmile-";
        const modalIds = document.querySelectorAll(`[id^="${modalPrefix}"]`);
        modalIds.forEach(modal => {
            modal.style.display = "none";
        });

        // Закрыть текущее модальное окно
        setisOpenModal(!isOpenModal);
        const modalToClose = document.getElementById(`openModalForSubComANDSmile-${index}`);
        if (modalToClose) {
            modalToClose.style.display = "none";
        }
    };




    function convertDate(params) {
        // Парсим строку в объект Date
        const dateString = item.createdAt;
        const dateObject = new Date(dateString);

        // Получаем компоненты времени
        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();
        const period = hours >= 12 ? "PM" : "AM";

        // Конвертируем часы в 12-часовой формат
        const twelveHourFormat = hours % 12 || 12;

        // Форматируем минуты, чтобы было две цифры
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        // Собираем результат в нужный формат
        const formattedTime = `${twelveHourFormat}:${formattedMinutes} ${period}`;

        return formattedTime

    }

    // useEffect(() => {
    //     const text = item.messageText;
    //     const strong = /\*\*(.*?)\*\*/g; // Регулярное выражение для поиска текста, заключенного в двойные звездочки
    //     const matchesStrong = text.match(strong); // Ищем совпадения в тексте
    //     if (matchesStrong) {
    //         const newText = text.replace(strong, '<strong>$1</strong>'); // Заменяем текст внутри двойных звездочек на <strong> элементы
    //         console.log("Измененный текст:", newText);

    //         // Устанавливаем новый текст внутрь <p> элемента
    //         const messageTextElement = document.querySelector(`.messageText-${item._id}`);
    //         console.log(messageTextElement, "messageTextElement");
    //         messageTextElement.innerHTML = newText;
    //     }
    //     const civiy = /\*\b(\w+)\b\*/g;// Регулярное выражение для поиска текста, заключенного в двойные звездочки
    //     const matchesciviy = text.match(civiy); // Ищем совпадения в тексте
    //     if (matchesciviy) {
    //         const newText = text.replace(civiy, '<i>$1</i>'); // Заменяем текст внутри двойных звездочек на <strong> элементы
    //         console.log("Измененный текст:", newText);

    //         // Устанавливаем новый текст внутрь <p> элемента
    //         const messageTextElement = document.querySelector(`.messageText-${item._id}`);
    //         console.log(messageTextElement, "messageTextElement");
    //         messageTextElement.innerHTML = newText;
    //     }







    // }, [item]);
    useEffect(() => {
        const text = item.messageText;

        // Регулярное выражение для поиска текста, заключенного в двойные звездочки
        const doubleStarRegex = /\*\*(.*?)\*\*/g;

        // Регулярное выражение для поиска текста, заключенного в одинарные звездочки
        const singleStarRegex = /\*\b(\w+)\b\*/g;

        // Регулярное выражение для поиска текста, заключенного в тильды
        const crossedoutRegex = /\~\b(\w+)\b\~/g;

        // Замена текста внутри двойных звездочек на тег <strong>
        const newTextWithStrong = text.replace(doubleStarRegex, '<strong>$1</strong>');

        // Замена текста внутри одинарных звездочек на тег <i>
        const newTextWithItalic = newTextWithStrong.replace(singleStarRegex, '<i>$1</i>');

        // Замена текста внутри тильд на тег <s>
        const newTextWithCrossedOut = newTextWithItalic.replace(crossedoutRegex, '<s>$1</s>');

        // Замена текста ссылки на HTML-код ссылки
        const newTextWithLink = newTextWithCrossedOut.replace(/\((.*?)\)\[(.*?)\]/, '<a target="_blank"   href="$2">$1</a>');

        // Устанавливаем новый текст внутрь <p> элемента
        const messageTextElement = document.querySelector(`.messageText-${item._id}`);
        messageTextElement.innerHTML = newTextWithLink;

    }, [item]);






    return (

        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "messageItemDarkCont" : "messageItemLightCont"} onClick={handleNavigate} onContextMenu={handleContextMenu} id={`message-${item._id}`}>
            <div className="openModalForAddSubCommentAndSmileContRelative">
                {/* DROWER для того чтобы открыть остальные функционал для сообщений */}
                <OpenModalForSubComANDSmile isOpenModal={isOpenModal} setisOpenModal={setisOpenModal} item={item} child={child} index={index} />
            </div>
            <Avatar size={"xs"} src={item?.senderInfo?.img ? `/${item?.senderInfo?.img}` : "https://bit.ly/dan-abramov"} />
            <div className="messageInfoCont">
                <div className="frame19">
                    <p className="name">{item?.senderInfo?.name ? item?.senderInfo?.name : "анонимный"}</p>
                    {
                        item.isImportantMessage ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M10.7751 2.85007C9.90121 0.160654 6.0964 0.160654 5.22256 2.85007C4.96564 3.64079 4.22878 4.17615 3.39737 4.17615C0.569552 4.17615 -0.606198 7.79473 1.68156 9.45688C2.35418 9.94557 2.63564 10.8118 2.37872 11.6025C1.50487 14.2919 4.58302 16.5283 6.87078 14.8662C7.5434 14.3775 8.45421 14.3775 9.12684 14.8662C11.4146 16.5283 14.4927 14.2919 13.6189 11.6025C13.362 10.8118 13.6434 9.94557 14.3161 9.45688C16.6038 7.79473 15.4281 4.17615 12.6002 4.17615C11.7688 4.17615 11.032 3.64079 10.7751 2.85007Z" fill="#F4E02B" />
                            </svg>
                            :
                            ""
                    }

                    <p className="clock">{convertDate()}</p>
                    <div>
                        {/* кто читал это сообщение */}
                        <WhoReadThisMessage item={item} />
                    </div>
                </div>
                <div className="frame41">
                    <p className={`messageText-${item?._id}`}>{item?.messageText}</p>
                </div>
                < SuppliedEmoticons item={item} child={child} />
                <FileModal item={item} />
            </div>



        </div>
    )
}