import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "react-bootstrap/Modal";
import { Input } from "@chakra-ui/react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { useToast } from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useDisclosure,
  Textarea

} from "@chakra-ui/react";

import ChilMNodal from "../childModal/childModal";
import CommentBox from "../commentBox/commentBox.jsx";
import Shablon from "../shablon/shablon.jsx";

import Metka from "../metka/metka.jsx";
import Status from "../status/status.jsx";
import SelectMarketPlaceEdit from "../selectMarketPlaceEdit/SelectMarketPlaceEdit";
import ChilMNodalEdit from "../childModalEdit/childModalEdit";
import { getMyCompany } from "../../../../redux/reducer/getCompany/action/action";
import UploadFileTaskMenegerEdit from "../uploadFile/uploadFileEdit";
import Perfomer from "../perfomer/perfomer";
import PerfomerEdit from "../perfomerEdit/perfomerEdit";
import axios from "axios";
import ChilMNodalEditShablon from "../ShablonCreatTaskChild/ShablonCreatTaskChild";
import CommentBoxCreatTask from "../commentCreatTask/commentCreatTask";
import sockets from "../../../../socket/socket.js";


const socket = sockets
function ShablonCreatTask({  editAllTask,itemID,update,allitem ,data,handleGetNotify,sendMessage,closeParent}) {
  const dispatch = useDispatch();
  const getmyinfo = useSelector((allWorkers) => allWorkers);
  let getmyinfoid = useSelector(compID => compID.getMayInfoReducer)
  const [show, setShow] = useState(false);
  const [child, setChild] = useState([]);
  const [message, setMessage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [currentTask, setCurrentTask] = useState([]);
  const [comments, setComments] = useState([]);
  const [performer, setperformer] = useState([]);
  const [templates, satTemplates] = useState("");
  const [taskName, satTtaskName] = useState("");
  const [templatesArray, satTemplatesArray] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endDateConfirm, setDndDateConfirm] = useState("");
  const [status, setStatus] = useState("");
  const [prioritet, setPrioritet] = useState("");
  const [metka, setMetka] = useState([]);
  const [ozon, setOzon] = useState([]);
  const [wb, setBw] = useState([]);
  const [classNameTask, setclassNameTask] = useState("greyClass");
  const [file, setfile] = useState(null);
  const subTaskRed = useRef(null);
  const t = useRef(null);
  const dipatch = useDispatch();
  const [perfomerID, setperfomerID] = useState([]);
  const [taskInfo, setTasktaskInfo] = useState("");
const [load,setload]= useState(false)
const toast = useToast()

let getCompanyID = useSelector(compID => compID.checkedSessionReducer)


  useEffect(() => {
      
       

    dipatch(getMyCompany("/mycopany/getallcompany"));
    switch (prioritet) {
      case "Критический":
     
        setclassNameTask("urgentAndImportant");
        break;
      case "Важно":
      
        setclassNameTask("LilacClass");
        break;
      case "Срочно":
      
        setclassNameTask("yellowClass");
        break;
      default:
     
        setclassNameTask("greyClass");
    }
    setCurrentTask(editAllTask[0]);
    setCurrentTask([
      {
        child,
        taskName,
        msg: message,
        comments,
        performer,
        startDate,
        endDate,
        status,
        prioritet,
        ozon,
        wb,
        classNameTask,
        file,
        perfomerID,
        taskInfo
      },
    ]);
   
    
  }, [
    comments,
    performer,
    templates,
    templatesArray,
    startDate,
    endDate,
    status,
    prioritet,
    metka,
    child,
    wb,
    ozon,
    taskName,
    file,
    taskInfo
  ]);

  let array = [];

  const setSubStack = (e) => {
    setComments([])
    setperformer([])
    setStartDate("")
    setEndDate("")
    setPrioritet("")
    setStatus("")
    if (e.key === "Enter") {

      setChild([
        ...child,
        {
          child: [],
          taskName,
          msg: message,
          comments,
          performer,
          startDate,
          endDate,
          status,
          prioritet,
          
        },
      ]);

      setMessage("");
      subTaskRed.current.style.overflow = "auto";
      console.log(child);
    }
  };



const setSubStackBTN = (e) =>{
  setComments([])
  setperformer([])
  setStartDate("")
  setEndDate("")
  setPrioritet("")
  setStatus("")
  if (message != "") {
      
    setChild([
      ...child,
      {
        child: [],
        taskName,
        msg: message,
        comments,
        performer,
        startDate,
        endDate,
        status,
        prioritet,
        taskInfo
      },
    ]);

    setMessage("");
    subTaskRed.current.style.overflow = "auto";
    console.log(child);
  }

}
  

useEffect(()=>{
  setCurrentTask(editAllTask[0]);
  setBw(editAllTask[0].wb);
  setOzon(editAllTask[0].ozon);
  setChild(editAllTask[0].child);
  setComments(editAllTask[0].comments);
  setStartDate(editAllTask[0].startDate);
  setEndDate(editAllTask[0].endDate);
  setPrioritet(editAllTask[0].prioritet);
  setStatus(editAllTask[0].status);
  setperformer(editAllTask[0].performer);
  setclassNameTask(editAllTask[0].classNameTask);
  satTtaskName(editAllTask[0].taskName);
  setfile(editAllTask[0].file);
  setperfomerID(allitem.perfomerID)
  setTasktaskInfo(editAllTask[0].taskInfo);
},[data])


  const getarraa = (item, index) => {
    if (item.child.length === 0) {
      array = [];
      array.push(child);
      array[0][index].child.push();
    }
  };



  const showModal = () => {
    handleShow();
    // console.log(editAllTask[0].performer);
    setCurrentTask(editAllTask[0]);
    setBw(editAllTask[0].wb);
    setOzon(editAllTask[0].ozon);
    setChild(editAllTask[0].child);
    setComments(editAllTask[0].comments);
    setStartDate(editAllTask[0].startDate);
    setEndDate(editAllTask[0].endDate);
    setPrioritet(editAllTask[0].prioritet);
    setStatus(editAllTask[0].status);
    setperformer(editAllTask[0].performer);
    setclassNameTask(editAllTask[0].classNameTask);
    satTtaskName(editAllTask[0].taskName);
    setfile(editAllTask[0].file);
    setperfomerID(allitem.perfomerID)
      setTasktaskInfo(editAllTask[0].taskInfo)
  };
  const sendServer = (task,metka) => {

  
    setperfomerID([...perfomerID,getmyinfo.myinfo])
    axios.post('/createTask/api', {
      CompanyID:getCompanyID.checkedSession,
       message ,
       task:currentTask,
       creatorTask:getmyinfoid.myinfo._id,
       perfomerID,
       allmetka:metka
    })
    .then(function (response) {
  
       socket.emit('message', { room:getCompanyID.checkedSession, message ,currentTask});
      
    })
    .catch(function (error) {
      console.error(error);
      
    });
  
    setMessage('');
  };


  const HandleSetPerfomerId=(e)=>{
 
    setperfomerID([e])
      setperformer(e)
  }

  const HandleDeletePerfomerId=(e)=>{
    setperformer("")
    setperfomerID([])
  }

  function deleteItemByIndex(index) {
    setChild(prevArray => {
      const newArray = [...prevArray]; // Create a new array copy
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the modified array
    });
  }



const handleDate=()=>{
  
  axios.post('/notifyConfirm', {
    eidtTaskID: allitem._id,
    isChild:false,
  
    adminIDTask:allitem.creatorTasks.toString(),
    typenotify:"date",
    indexTask:0,
    senderName:getmyinfo.getMayInfoReducer.myinfo.name,
    senderTel:getmyinfo.getMayInfoReducer.myinfo.tel,
    senderEmail:getmyinfo.getMayInfoReducer.myinfo.email,
    text:"",
    messageConfirm:endDateConfirm,
    taskName,
    oldDate:editAllTask[0].endDate
    
  })
  .then(function (response) {
    console.log(response);
    handleGetNotify()
    toast({
      position:"top",
    title: 'Отправлено на подтверждение постановщику задачи',
    description: "Отправлено на подтверждение постановщику задачи",
    status: 'success',
    duration: 9000,
    isClosable: true,
  })
  })
  .catch(function (error) {
    console.log(error);
  });
}

const handleStatus=()=>{
axios.post('/notifyConfirm', {
  eidtTaskID: allitem._id,
  isChild:false,
  adminIDTask:allitem.creatorTasks.toString(),
  typenotify:"status",
  indexTask:0,
  senderName:getmyinfo.getMayInfoReducer.myinfo.name,
  senderTel:getmyinfo.getMayInfoReducer.myinfo.tel,
  senderEmail:getmyinfo.getMayInfoReducer.myinfo.email,
  text:"",
  messageConfirm:endDate,
  taskName,
  oldDate:editAllTask[0].endDate
  
})
.then(function (response) {

  handleGetNotify()
  toast({
    title: 'Account created.',
    description: "We've created your account for you.",
    status: 'success',
    duration: 9000,
    isClosable: true,
  })
})
.catch(function (error) {
  console.log(error);
});
}

  return (
    <div className="modalCreatTask">
      {/* <Button colorScheme="blue" onClick={showModal} style={{ width: "100%" }}>
        <span class="material-symbols-outlined" style={{ width: "100%" }}>
          edit
        </span>
      </Button> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none" onClick={showModal}>
  <g filter="url(#filter0_d_1521_4027)">
    <ellipse cx="12.7778" cy="11" rx="9.77778" ry="10" fill="white"/>
  </g>
  <path d="M14.2696 8.5787L14.8374 9.15951L9.24558 14.8792H8.67776V14.2984L14.2696 8.5787ZM16.4915 4.7782C16.3372 4.7782 16.1767 4.84133 16.0594 4.96128L14.93 6.11658L17.2445 8.48401L18.3739 7.3287C18.6146 7.08249 18.6146 6.68476 18.3739 6.43855L16.9297 4.96128C16.8063 4.83502 16.652 4.7782 16.4915 4.7782ZM14.2696 6.79209L7.44336 13.7744V16.1418H9.75785L16.5841 9.15951L14.2696 6.79209Z" fill="#3B79F6"/>
  <defs>
    <filter id="filter0_d_1521_4027" x="0" y="0" width="25.5557" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="1.5"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1521_4027"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1521_4027" result="shape"/>
    </filter>
  </defs>
</svg>

      <Modal show={show} onHide={handleClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Параметры задачи</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inputCreatTask">
            
            <div className="taskinputsDiv">
              <Input
                  placeholder="Введите название задачи"
                  className="nameTask"
                  value={taskName}
                  onChange={(e) => satTtaskName(e.target.value)}
                />
              <SelectMarketPlaceEdit
                setBw={setBw}
                setOzon={setOzon}
                wbs={wb}
                ozons={ozon}
              />
             
             
              <Textarea placeholder="Добавьте описание задачи" defaultValue={taskInfo} onChange={(e) => setTasktaskInfo(e.target.value)}/>
              <Accordion allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        Подзадачи
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <div
                      className={"subTask"}
                      style={{ overflow: "auto" }}
                      ref={subTaskRed}
                    >
                      {child?.map((item, index) => {
                        return (
                          <div onClick={() => getarraa(item, index)} className="childTaskCont" key={index}>
                              <div className="itemChild">
                                  <p>{item.msg}</p>
                                <ChilMNodalEditShablon
                                  item={item}
                                  allaray={currentTask}
                                  nameCurrentSubTask={item.msg}
                                  sendServer={sendServer}
                                  load={load}
                                  editAllTask={editAllTask}
                                  allitem={allitem}
                                  index={index}
                                  handleGetNotify={handleGetNotify}
                                  key={item + index}
                                  sendMessage={sendMessage}
                                  handleClose={handleClose}
                                  
                                />
                              </div>
                              <span class="material-symbols-outlined deletSubTusk" onClick={()=> deleteItemByIndex(index)}>delete</span>
                          </div>
                        );
                      })}
                    </div>
                    <div className="SubTask">
                    <input
                     className="SubTaskInput"
                        placeholder="Новая подзадача"
                        value={message}
                        onKeyDown={setSubStack}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                        <Button  background={"#3182CE"} color={"white"} onClick={(e)=>setSubStackBTN(e.target.value)}>Добавить подзадачу</Button>
                   </div>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <UploadFileTaskMenegerEdit file={file} setfile={setfile} />
             
              <CommentBoxCreatTask setComments={setComments} comments={comments} />
            </div>

            <div className="taskinputsDiv2">
            <PerfomerEdit performerS={performer} setperformerS={setperformer} HandleSetPerfomerId={HandleSetPerfomerId} HandleDeletePerfomerId={HandleDeletePerfomerId}/>
              <div className="dateicon">
                <span className="material-symbols-outlined">
                  calendar_month
                </span>
                <p>Сроки</p>
              </div>
                               {(() => {
                                  if (allitem.creatorTasks === getmyinfo.getMayInfoReducer.myinfo._id) {
                                    return (
                                      <div className="date">
                                      <label htmlFor="">
                                        с
                                        <Input
                                          size="sm"
                                          type="datetime-local"
                                          colorScheme="grey"
                                          value={startDate}
                                          onChange={(e) => setStartDate(e.target.value)}
                                          
                                        />
                                      </label>
                                      <label htmlFor="">
                                        до
                                        <Input
                                          size="sm"
                                          type="datetime-local"
                                          value={endDate}
                                          onChange={(e) => setEndDate(e.target.value)}
                                        />
                                       
                                      </label>
                                    </div>
                                    )
                                  }else{
                                    return (
                                      <div className="date">
                                      <label htmlFor="">
                                        с
                                        <Input
                                          size="sm"
                                          type="datetime-local"
                                          colorScheme="grey"
                                          value={startDate}
                                          onChange={(e) => setStartDate(e.target.value)}
                                          disabled
                                          
                                        />
                                      </label>
                                      <label htmlFor="">
                                        до
                                        <Input
                                          size="sm"
                                          type="datetime-local"
                                          defaultValue={endDate}
                                          onChange={(e) => setDndDateConfirm(e.target.value)}
                                          key={endDate}
                                        />
                                        <button onClick={handleDate} className="confirmEditDate">Запросить подтверждение</button>
                                      </label>
                                    </div>
                                      )
                                  }
                                })()}

                                {/* ====================== */}
                                
                        
                              <Status setStatus={setStatus} status={status}/>
                              {(() => {
                                  if (allitem.creatorTasks !== getmyinfo.getMayInfoReducer.myinfo._id) {
                                    return (
                                      <button onClick={handleStatus} className="confirmEditDate">Сдать работу</button> 
                                    )
                                  }
                                })()}         
             

              <Metka setMetka={setMetka} metka={metka} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => [handleClose()]}>
            Закрыть
          </Button>
          {
            load?
            <Button
            isLoading
            loadingText='Загрузка'
            colorScheme='teal'
            variant='outline'
          >
            Submit
          </Button>
            :
            <Button colorScheme="blue" onClick={sendServer}> Создать через шаблон</Button>
          }
          
         
          
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ShablonCreatTask;
