import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useToast } from '@chakra-ui/react'

import CommentBox from "../commentBox/commentBox.jsx";
import Shablon from "../shablon/shablon.jsx";
import Metka from "../metka/metka.jsx";
import { getMyCompany } from "../../../../redux/reducer/getCompany/action/action";
import UploadFileTaskMenegerEdit from "../uploadFile/uploadFileEdit";
import axios from "axios";
import DropdownMobilePioritet from "../tableTask/editPrioritetForMobile/editPrioritetForMobile";
import DropdownMobileStatus from "../tableTask/EditStatusForMobile/editStatusForMobile";
import { useNavigate } from "react-router";
import LoopingTask from "../loopingTask/loopingTask.jsx";
import { fetchUsers } from "../../../../redux/reducer/postFetcReducer/action/action.js";
import sockets from "../../../../socket/socket.js";
import EditPerformerAndDirector from "../editPerformerAndDirector/editPerformerAndDirector.jsx";
import StartDateModalEdit from "../startDateModalEdit/startDateModalEdit.jsx";
import EndDateModalEdit from "../endDateModalEdit/endDateModalEdit.jsx";
import SubTaskContEdit from "../subTaskCont/subTaskContEdit.jsx";
import { MarketPlaceSelectCreat } from "../marketPlaceSelectCreat/marketPlaceSelectCreat.jsx";


const socket = sockets
function TaskEdit({ editAllTask, itemID, update, allitem, data, handleGetNotify, index, adminInfo }) {
  const dispatch = useDispatch();
  const getmyinfo = useSelector((allWorkers) => allWorkers);
  const myinfos = useSelector(data => data.getMyImageReducer);
  const [show, setShow] = useState(false);
  const [child, setChild] = useState([]);
  const [message, setMessage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const myModalEditTask = useRef(null)
  const [currentTask, setCurrentTask] = useState([]);
  const [comments, setComments] = useState([]);
  const [performer, setperformer] = useState("");
  const [templates, satTemplates] = useState("");
  const [taskName, satTtaskName] = useState("");
  const [templatesArray, satTemplatesArray] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endDateConfirm, setDndDateConfirm] = useState("");
  const [status, setStatus] = useState("Новая");
  const [prioritet, setPrioritet] = useState("Низший");
  const [metka, setMetka] = useState([]);
  const [ozon, setOzon] = useState([]);
  const [wb, setBw] = useState([]);
  const [yandex, setYandex] = useState([]);
  const [megaMarket, setmegaMarket] = useState([]);
  const [classNameTask, setclassNameTask] = useState("greyClass");
  const [file, setfile] = useState([]);
  const [Taskfiles, setTaskfiles] = useState([]);
  const dipatch = useDispatch();
  const [perfomerID, setperfomerID] = useState([]);
  const [load, setload] = useState(false)
  const toast = useToast()
  const [taskInfo, setTasktaskInfo] = useState("");
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [creaTorTask, setcreaTorTask] = useState("")
  let getmyinfoForAdd = useSelector(compID => compID.getMayInfoReducer)
  const getDarkModoe = useSelector(darkModes => darkModes)
  const gerChekcedCompany = useSelector((allWorkers) => allWorkers);
  const navigate = useNavigate()
  // это состояние для повторяющихся задач
  const [checked, setChecked] = useState(false)
  const [selectDays, setselectDays] = useState("everyDay")
  const startLoopDay = new Date();
  const adjustedDateLoop = new Date(startLoopDay.getTime() - startLoopDay.getTimezoneOffset() * 60000);
  const formattedDateLoop = adjustedDateLoop.toISOString().slice(0, 16);
  const [loopingStartDate, setloopingStartDate] = useState(formattedDateLoop);
  const [everythreedays, seteverythreedays] = useState(new Date())
  // ===============================================================//


  const [creatorTaskinfo, setcreatorTaskinfo] = useState("")
  let onlineUSers = useSelector(onlineUSers => onlineUSers);
  const [price, setPrice] = useState(0);
  const [childPerfomer, setchildPerfomer] = useState([])
  const [receivedMessages, setReceivedMessages] = useState([])
  const getmyinfoCompany = useSelector(compID => compID.getMayInfoReducer);
  let getCompanyID = useSelector(compID => compID.checkedSessionReducer);
  const [delLoad, setDelload] = useState(false)
  useEffect(() => {
    // console.log(allitem.creatorTasks);
    // console.log(getmyinfoForAdd.myinfo._id);

    if (getmyinfoForAdd?.myinfo?._id == allitem.creatorTasks) {
      setcreatorTaskinfo(getmyinfoForAdd?.myinfo)
    } else {
      gerChekcedCompany.rootReducer.users.data?.forEach(finduser => {
        if (finduser.workerID == allitem.creatorTasks) {
          setcreatorTaskinfo(finduser)
          return
        } else {
          setcreatorTaskinfo("")
        }
      });
    }
  }, [""])



  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  useEffect(() => {
    dipatch(getMyCompany("/mycopany/getallcompany"));
    switch (prioritet) {
      case "Критический":

        setclassNameTask("urgentAndImportant");
        break;
      case "Важно":

        setclassNameTask("LilacClass");
        break;
      case "Срочно":

        setclassNameTask("yellowClass");
        break;
      default:

        setclassNameTask("greyClass");
    }
    setCurrentTask(editAllTask[0]);
    setCurrentTask([
      {
        child,
        taskName,
        msg: message,
        comments,
        performer,
        startDate,
        endDate,
        status,
        prioritet,
        ozon,
        wb,
        yandex,
        megaMarket,
        classNameTask,
        perfomerID,
        taskInfo,
        metka,
        Taskfiles,
        price
      },
    ]);


  }, [
    comments,
    performer,
    templates,
    templatesArray,
    startDate,
    endDate,
    status,
    prioritet,
    metka,
    child,
    wb,
    ozon,
    yandex,
    megaMarket,
    taskName,
    file,
    taskInfo,
    Taskfiles,
    price

  ]);

  let array = [];

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const setSubStack = (e) => {

    if (e.key === "Enter") {
      if (performer.length === 0) {
        toast({
          position: "top",
          title: 'Ошибка',
          description: "Для  создания подзадач вам необходимо выбрать исполнителя",
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        return;
      }

      setChild([
        ...child,
        {
          child: [],
          taskName,
          msg: message,
          comments: [],
          performer,
          startDate: getCurrentDateTime(),
          endDate: getCurrentDateTime(),
          status: "",
          prioritet: "",
          files: [],
          taskInfo: "",
          metka,
          Taskfiles: [],
          key: getRandomInt(1000000000000000000),
          price: 0
        },
      ]);

      setMessage("");


    }
  };



  const setSubStackBTN = (e) => {
    var d = new Date();

    if (message != "") {
      if (performer.length === 0) {
        toast({
          position: "top",
          title: 'Ошибка',
          description: "Для  создания подзадач вам необходимо выбрать исполнителя",
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
        return;
      }
      dispatch(fetchUsers())
      const currentDate = new Date();
      setChild([
        ...child,
        {
          child: [],
          taskName,
          msg: message,
          comments: [],
          performer,
          startDate: getCurrentDateTime(),
          endDate: getCurrentDateTime(),
          status: "Новая",
          prioritet: "Низший",
          files: [],
          taskInfo: "",
          metka,
          Taskfiles: [],
          key: getRandomInt(1000000000000000000),
          price: 0
        },
      ]);

      setMessage("");


    }

  }


  useEffect(() => {
    setcreaTorTask(allitem.creatorTasks)
    setCurrentTask(editAllTask[0]);
    setBw(editAllTask[0].wb);
    setOzon(editAllTask[0].ozon);
    setYandex(editAllTask[0]?.yandex);
    setmegaMarket(editAllTask[0]?.megaMarket);
    setChild(editAllTask[0].child);
    setComments(editAllTask[0].comments);
    setStartDate(editAllTask[0].startDate);
    setEndDate(editAllTask[0].endDate);
    setPrioritet(editAllTask[0].prioritet);
    setStatus(editAllTask[0].status);
    setperformer(editAllTask[0].performer);
    setclassNameTask(editAllTask[0].classNameTask);
    satTtaskName(editAllTask[0].taskName);
    setperfomerID(allitem.perfomerID)
    setTasktaskInfo(editAllTask[0].taskInfo)
    setMetka(editAllTask[0].metka)
    setChecked(allitem.isLooping)
    setselectDays(allitem.typeLooping)
    setloopingStartDate(new Date(allitem.loopingStartDate).toISOString().slice(0, 16))
    setTaskfiles(editAllTask[0].Taskfiles)
    setPrice(editAllTask[0].price)

  }, [data])


  const getarraa = (item, index) => {
    //     console.log(item.alltask[0],"getarraas");
    // if (item.alltask[0].child.length === 0) {
    //   array = [];
    //   array.push(child);
    //   array[0][index].child.push();
    // }
  };



  const showModal = () => {

    handleShow();


    setCurrentTask(editAllTask[0]);
    setBw(editAllTask[0].wb);
    setOzon(editAllTask[0].ozon);
    setChild(editAllTask[0].child);
    setComments(editAllTask[0].comments);
    setStartDate(editAllTask[0].startDate);
    setEndDate(editAllTask[0].endDate);
    setPrioritet(editAllTask[0].prioritet);
    setStatus(editAllTask[0].status);
    setperformer(editAllTask[0].performer);
    setclassNameTask(editAllTask[0].classNameTask);
    satTtaskName(editAllTask[0].taskName);
    setperfomerID(allitem.perfomerID)
    setMetka(editAllTask[0].metka)
    setTaskfiles(editAllTask[0].Taskfiles)
    setPrice(editAllTask[0].price)

  };
  // загрузка файла для задач
  function files(taskID, isChild) {
    const formData = new FormData();

    // Добавляем все файлы в FormData
    for (let i = 0; i < file.length; i++) {
      formData.append('files', file[i]);
    }

    axios.post('/uploadFileEdit/api', formData, {
      params: {
        taskID,
        isChild
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        // Вы можете обновить интерфейс или отобразить прогресс здесь
        console.log(`Upload progress: ${percentCompleted}%`);
      },
    })
      .then(response => {
        // Обработка ответа если необходимо
        console.log(response.data);
      })
      .catch(error => {
        // Обработка ошибок если есть
        console.error(error);
      });

  }
  async function uploadCommentsFiles(taskID) {
    const formData = new FormData();
    console.log(comments, 'comments');
    try {
      comments.forEach((comment, index) => {
        // Фильтруем изображения, чтобы оставить только файлы или Blob объекты
        const validImages = comment.images.filter(image => image instanceof Blob);

        // Добавляем отфильтрованные изображения в formData с уникальными именами
        validImages.forEach((image, imgIndex) => {
          formData.append('files', image, `comment-${index}-image-${imgIndex}`);
        });
      });

      // Добавляем данные комментариев и taskID
      formData.append('comments', JSON.stringify(comments)); // Преобразуем массив комментариев в JSON-строку
      formData.append('taskID', taskID); // Добавляем идентификатор задачи в FormData

      // Отправляем запрос на сервер
      await axios.post('/addCommentsFilesEdit/api', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Error uploading comments with images:', error);
      throw error; // Пробрасываем ошибку дальше для обработки на верхнем уровне
    }
  }

  const uploadChildImg = async (task, taskID) => {
    if (task && task.length > 0 && task[0].child && task[0].child.length > 0) {
      for (let i = 0; i < task[0].child.length; i++) {
        const formData = new FormData(); // Create a new FormData object for each child
        if (task[0].child[i].file && task[0].child[i].file.length > 0) {
          for (let j = 0; j < task[0].child[i].file.length; j++) {
            formData.append('files', task[0].child[i].file[j]);
          }
        }
        formData.append('indexChild', i);
        formData.append('task', JSON.stringify(task[0]));
        formData.append('taskID', taskID);

        try {
          const response = await axios.post('/uploadTaskMenegerChildEdit/api', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          console.log(response.data.message); // Выводим сообщение об успешной загрузке
        } catch (error) {
          console.error('Error uploading files:', error);
        }
      }
    } else {
      console.error('No child tasks or files to upload.');
    }
  };





  const sendServer = () => {

    if (performer.length === 0) {
      toast({
        position: "top",
        title: 'Ошибка',
        description: "Для  создания подзадач вам необходимо выбрать исполнителя",
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
      return;
    }
    if (taskName == "") {
      toast({
        position: "top",
        title: 'Ошибка',
        description: "Имя задачи пустое пожалуйста, заполните это поле",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      return;
    }
    if (editAllTask[0] && editAllTask[0].perfomerID && editAllTask[0].perfomerID[0]) {
      if (editAllTask[0].perfomerID[0].workerID && perfomerID[0] && perfomerID[0].workerID) {
        if (editAllTask[0].perfomerID[0].workerID == perfomerID[0].workerID) {

        } else {
          if (perfomerID[0] && perfomerID[0].workerID) {
            if (perfomerID[0].email) {


              axios.post('/sendEmail/api', {
                perfomerEmail: perfomerID[0].email
              })
                .then(function (response) {


                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {

              toast({
                position: "top",
                title: 'У этого пользователя нет электронной почты',
                description: "У этого пользователя нет электронной почты",
                status: 'error',
                duration: 2000,
                isClosable: true,
              });
            }

          }
        }
      }
    } else {
      if (perfomerID[0] && perfomerID[0].workerID) {
        if (perfomerID[0].email) {


          axios.post('/sendEmail/api', {
            perfomerEmail: perfomerID[0].email
          })
            .then(function (response) {

            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("no Email");
          toast({
            position: "top",
            title: 'У этого пользователя нет электронной почты',
            description: "У этого пользователя нет электронной почты",
            status: 'error',
            duration: 2000,
            isClosable: true,
          });
        }

      }
    }

    if (editAllTask[0].child.length > 0) {

      console.log(editAllTask[0].child);
    } else {

    }
    setload(true)
    allitem.perfomerID = perfomerID
    allitem.allmetka = metka
    console.log(allitem.allPerformers, "allitemallitemallitem");
    axios.post('/findAndUpdateTask', {
      itemID,
      currentTask,
      allitem,
      perfomerID: allitem.perfomerID,
      allmetka: metka,
      creaTorTask,
      isLooping: checked,
      selectDays,
      loopingStartDate,
      everythreedays,
      performer: performer
    })
      .then(function (response) {

        files(allitem._id, false)
        uploadCommentsFiles(allitem._id);
        uploadChildImg(editAllTask, allitem._id);
        sendSeverCreatNotifyForTask();
        update();
        setload(false);
        socket.emit('getNotifyEmit');
        handleClose();
        navigate("/task");

      })
      .catch(function (error) {
        console.log(error);
        setload(false);
      });
  };


  const HandleSetPerfomerId = (e) => {

    setperfomerID([e])
    setperformer(e)
  }

  const HandleDeletePerfomerId = (e) => {
    setperformer("")
    setperfomerID([])
  }

  function deleteItemByIndex(index, e) {
    e.stopPropagation()
    setChild(prevArray => {
      const newArray = [...prevArray]; // Create a new array copy
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the modified array
    });
  }



  const handleDate = () => {

    axios.post('/notifyConfirm', {
      eidtTaskID: allitem._id,
      isChild: false,
      adminIDTask: allitem.creatorTasks.toString(),
      typenotify: "date",
      indexTask: 0,
      senderName: getmyinfo.getMayInfoReducer.myinfo.name,
      senderTel: getmyinfo.getMayInfoReducer.myinfo.tel,
      senderEmail: getmyinfo.getMayInfoReducer.myinfo.email,
      text: "",
      messageConfirm: endDateConfirm,
      taskName,
      oldDate: editAllTask[0].endDate,
      myAvatar: myinfos.users.myavatar

    })
      .then(function (response) {
        console.log(response);
        handleGetNotify()
        toast({
          position: "top",
          title: 'Отправлено на подтверждение постановщику задачи',
          description: "Отправлено на подтверждение постановщику задачи",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleStatus = () => {
    axios.post('/notifyConfirm', {
      eidtTaskID: allitem._id,
      isChild: false,
      adminIDTask: allitem.creatorTasks.toString(),
      typenotify: "status",
      indexTask: 0,
      senderName: getmyinfo.getMayInfoReducer.myinfo.name,
      senderTel: getmyinfo.getMayInfoReducer.myinfo.tel,
      senderEmail: getmyinfo.getMayInfoReducer.myinfo.email,
      text: "",
      messageConfirm: endDate,
      taskName,
      oldDate: editAllTask[0].endDate,
      myAvatar: myinfos.users.myavatar

    })
      .then(function (response) {

        handleGetNotify()
        toast({
          position: "top",
          title: 'Отправлено на подтверждение постановщику задачи',
          description: "Отправлено на подтверждение постановщику задачи",
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangeFile = (e) => {
    setfile(e)
  }



  const handleCloseModal = () => {
    myModalEditTask.current.style.display = "none"
  }


  function dateisCurrent() {
    let currentDate = new Date();

    if (currentDate >= new Date(endDate)) {
      return true;
    } else {
      return false;
    }
  }

  // этот функция для того чтобы создать уведомление для задач
  async function sendSeverCreatNotifyForTask(e) {
    if (new Date(endDate).getTime() != new Date(allitem.alltask[0].endDate).getTime()) {
      if (allitem.alltask[0].performer) {
        // получаем информацию о пользователе
        const userInfo = await axios.get(`/getuserByID/api?userID=${allitem.creatorTasks}`);
        let mount = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
        const covertDate = new Date(endDate).getDay() + " " + mount[new Date(endDate).getMonth()] + " " + new Date(endDate).getFullYear()
        let notifyText = `${userInfo.data.data.name} изменил срок окончания <a target="_blank" class="taskName" href="getCurrentTask/${allitem._id}">${allitem.alltask[0].taskName}</a> на ${covertDate}  конца.`
        axios.post('/creatNotifyForTaskInfo/api', {
          // здесь не проверяем Это задача и подзадача
          isChild: false,
          // здесь мы отправляем полное задание
          task: allitem,
          //  // если это подзадача тогда получаем индекс этого подзадачу
          index: 1234,
          // здесь мы отправляем текст для уведомлений
          notifyText,
          // кому нужно отправить этот уведомление мы берём идентификатор этого пользователя
          notificationRecipientID: allitem.alltask[0].performer.workerID,
          // информация пользователя для отправления уведомлений на электронную почту
          emailInfo: {
            // здесь мы отправляем идентификатор пользователя
            userIdForEmail: allitem.alltask[0].performer.workerID,
            // текст для заголовки
            subject: "Смена срока окончания задачи",
            // текст для электронную почту
            textEmail: `${userInfo.data.data.name} изменил срок окончания <a target="_blank" class="taskName" href="https://xn--h1alceggc.xn--p1ai/getCurrentTask/${allitem._id}">${allitem.alltask[0].taskName}</a> на ${covertDate}  конца.`
          }
        })
          .then(function (response) {
            console.log(response);
            socket.emit('getNotifyEmit');
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }


  }

  const handleDeleteFile = (e) => {
    setTaskfiles((prevFiles) => prevFiles.filter((_, index) => index !== e));
  }


  const sendMessage = (task, perfomerID, metka) => {

    perfomerID.push(getmyinfo.myinfo)
    axios.post('/createTask/api', {
      CompanyID: getmyinfoCompany.checkedSession,
      message,
      task,
      creatorTask: getmyinfo.myinfo._id,
      perfomerID,
      allmetka: metka
    })
      .then(function (response) {

        socket.emit('message', { room: getCompanyID.checkedSession, message, task });
      })
      .catch(function (error) {
        console.log(error);
      });

    setMessage('');
  };

  const handleDelet = (card) => {
    // setDelload(true)

    axios.post('/taskmeneger/delete/api', {
      companyID: card.companyID,
      cardID: card._id
    })
      .then(function (response) {

        socket.emit('getAllTask', { companyIDS: getCompanyID.checkedSession });
        socket.emit('getNotifyEmit');
        socket.emit('message', { room: getCompanyID.checkedSession });
        // setDelload(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    const postData = async () => {
      try {
        console.log('%c Кто прочитал это сообщение ', 'background: #222; color: #bada55');
        const response = await axios.post('/whoReadThisComment/api', {
          taskID: allitem._id,
          isChild: false
        });
        update()

      } catch (error) {
        console.error(error);
      }
    };

    postData();
  }, []);
  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "CreatTaskDark" : "CreatTask"}>
      <div className="backTask">
        <div onClick={() => navigate("/task")}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M15 6L9 12L15 18" stroke="#474747" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <p>Назад</p>
        </div>
      </div>
      <div className="headerTask">

        <div>
          <DropdownMobilePioritet isChild={false} prioritetGet={allitem.alltask[0].prioritet} allitemForStatus={allitem} index={index} update={update} />
          <DropdownMobileStatus isChild={false} statusGet={allitem.alltask[0].status} allitemForStatus={allitem} index={index} update={update} />
          {/* <Status setStatus={setStatus} status={status} /> */}
        </div>
        <div>
          <Shablon sendMessage={sendMessage} currentTask={currentTask} perfomerID={perfomerID} receivedMessages={receivedMessages} handleDelet={handleDelet} delLoad={delLoad} update={update} handleGetNotify={handleGetNotify} closeParent={handleClose} />
        </div>
      </div>
      <div className="choosePerformerDiv">
        <EditPerformerAndDirector creaTorTask={creaTorTask}
          setcreaTorTask={setcreaTorTask}
          HandleSetPerfomerId={HandleSetPerfomerId}
          HandleDeletePerfomerId={HandleDeletePerfomerId}
          onlineUsers={onlineUSers.getOnlineUserViaRouterReducer.users.data}
          adminInfo={adminInfo}
          performerS={performer} setperformerS={setperformer} allitem={allitem}
        />

        {/* <ChangeDirector creaTorTask={creaTorTask} setcreaTorTask={setcreaTorTask} HandleSetPerfomerId={HandleSetPerfomerId} HandleDeletePerfomerId={HandleDeletePerfomerId} onlineUsers={onlineUSers.getOnlineUserViaRouterReducer.users.data} adminInfo={adminInfo} /> */}
        {/* <PerfomerEdit performerS={performer} setperformerS={setperformer} HandleSetPerfomerId={HandleSetPerfomerId} HandleDeletePerfomerId={HandleDeletePerfomerId} onlineUsers={onlineUsers} allitem={allitem} /> */}
      </div>
      <div className="creatTaskButton">

        {
          load ?
            <button colorScheme="blue" disabled>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                </svg>
              </div>
              <p>Загрузка</p>
            </button>
            :
            <button colorScheme="blue" onClick={() => sendServer(currentTask, metka)}>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                </svg>
              </div>
              <p>Сохранить задачу</p>
            </button>
        }
      </div>
      <div className="sectionOtherTask">
        <div>
          <div>
            <label htmlFor="taskNameInput">
              <p>Выберите название задачи </p>
              <input type="text"
                id="taskNameInput"
                placeholder="Введите название задачи"
                className="nameTaskInput"
                value={taskName}
                onChange={(e) => satTtaskName(e.target.value)}

              />
            </label>
          </div>
          <div>
            <label htmlFor="priceCreatInput">
              <p>Цена</p>
              <input type="number"
                id="priceCreatInput"
                placeholder="Цена"
                className="nameTaskInput"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </label>
          </div>

          <div className="dateCreatDiv">
            <div>
              <div>
                <div>
                  <p>Сроки: начало</p>
                  <StartDateModalEdit startDate={startDate} setStartDate={setStartDate} handleDate={handleDate} />
                </div>
                <div>
                  <p>Сроки: конец</p>
                  <EndDateModalEdit endDate={endDate} setEndDate={setEndDate} handleDate={handleDate} setDndDateConfirm={setDndDateConfirm} allitem={allitem} endDateConfirm={endDateConfirm} />
                </div>
              </div>
              <LoopingTask setChecked={setChecked} checked={checked} selectDays={selectDays} setselectDays={setselectDays}
                loopingStartDate={loopingStartDate} setloopingStartDate={setloopingStartDate} everythreedays={everythreedays} seteverythreedays={seteverythreedays} creatTaskadminInfo={creatorTaskinfo} taskName={taskName} />
            </div>
          </div>
          <div>
            <label>
              <p>Добавьте описание задачи</p>
              <textarea name="" id="" placeholder="Добавьте описание задачи" defaultValue={taskInfo} onChange={(e) => setTasktaskInfo(e.target.value)}></textarea>
            </label>
            <MarketPlaceSelectCreat ozon={ozon} setOzon={setOzon} wb={wb} setBw={setBw} setYandex={setYandex} yandex={yandex} megaMarket={megaMarket} setmegaMarket={setmegaMarket} />

            <UploadFileTaskMenegerEdit files={file} setFile={setfile} Taskfiles={Taskfiles} setTaskfiles={setTaskfiles} allitem={allitem} index={0} isChild={false} handleChangeFile={handleChangeFile} handleDeleteFile={handleDeleteFile} />
          </div>
        </div>
        <div>
          <Metka setMetka={setMetka} metka={metka} />
          <SubTaskContEdit
            allaray={currentTask}
            sendServer={sendServer}
            setperfomerID={setperfomerID}
            setchildPerfomer={setchildPerfomer}
            handleClose={handleClose}
            deleteItemByIndex={deleteItemByIndex}
            metkas={metka}
            load={load}
            currentTask={currentTask}
            getarraa={getarraa}
            setSubStackBTN={setSubStackBTN}
            message={message}
            setMessage={setMessage}
            setSubStack={setSubStack}
            child={child}
            editAllTask={editAllTask}
            allitem={allitem}
            handleGetNotify={handleGetNotify}
            update={update}
          />
          {/* <CommentBoxCreatTask setComments={setComments} comments={comments} /> */}
          <CommentBox setComments={setComments} comments={comments} key={"ASDASDASDASD"} isChild={false} allitem={allitem} editAllTask={editAllTask} update={update} onlineUsers={onlineUsers} sendServer={sendServer} />
        </div>

      </div>



    </div>
  )
}

export default TaskEdit;
