import React, { useState, useEffect } from 'react';
import "./editStatusForMobile.css"
import "./editStatusForMobileDark.css"
import axios from 'axios';
import { useToast } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import sockets from '../../../../../socket/socket';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    useDisclosure
} from '@chakra-ui/react'
const socket = sockets;

const DropdownMobileStatus = ({ isChild, statusGet, allitemForStatus, index, update }) => {

    let getmyinfo = useSelector(compID => compID.getMayInfoReducer);
    const myinfos = useSelector(data => data.getMyImageReducer);
    const getDarkModoe = useSelector(darkModes => darkModes);
    const toast = useToast();

    const { onOpen, onClose, isOpen } = useDisclosure();
    const [statusColor, setStatusColor] = useState("#FFEC8B");
    const [fakeShow, setfakeShow] = useState("Новая");


    useEffect(() => {
        if (statusGet == "Запланирована" || statusGet == "Новая") {
            setfakeShow("Новая")
            setStatusColor("#FFEC8B")
        } else if (statusGet == "Выполнена") {
            setfakeShow("Выполнена")
            setStatusColor("#9FE8FF")
        } else if (statusGet == "Принята" || statusGet == "Закрыта") {
            setfakeShow("Закрыта")
            setStatusColor("#B4FFA8")
        } else if (statusGet == "возвращена" || statusGet == "Возвращена") {
            setfakeShow("возвращена")
            setStatusColor("#FDA9FF")
        } else if (statusGet == "Выполняется") {
            setfakeShow("Выполняется")
            setStatusColor("#9CA6FF")
        } else if (statusGet == "Отменено") {
            setfakeShow("Отменено")
            setStatusColor("#FF9FCB")
        }


    }, [statusGet])

    const handleGetNotify = () => {
        socket.emit('getNotifyEmit');
    }
    //эта функция для того чтобы создать уведомление чтобы изменил что-то в задаче 
    async function sendInfoNotify(allitemForStatus, isChild, whatIsNotofy, index, currentTask) {
        console.log(currentTask);

        axios.post('/sendInfoNotify/api', {
            allitemForStatus,
            isChild,
            whatIsNotofy,
            index,
            currentTask
        })
            .then(function (response) {
                console.log(response);
                handleGetNotify()
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const handleUpdateStatus = (e) => {
        if (isChild) {
            allitemForStatus.alltask[0].child[index].status = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    sendInfoNotify(allitemForStatus, isChild, "status", index, allitemForStatus.alltask[0].child[index])
                    update()
                    toast({
                        position: "top",
                        title: response.data.message,
                        description: response.data.message,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })


                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        } else {
            allitemForStatus.alltask[0].status = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    console.log(response);
                    sendInfoNotify(allitemForStatus, isChild, "status", index, allitemForStatus.alltask[0])
                    update()
                    toast({
                        position: "top",
                        title: response.data.message,
                        description: response.data.message,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })

                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        }

    }

    const handleStatus = () => {
        if (isChild) {

            // update Child
            axios.post('/notifyConfirm', {
                eidtTaskID: allitemForStatus._id,
                isChild: true,
                adminIDTask: allitemForStatus?.creatorTasks.toString(),
                typenotify: "status",
                indexTask: index,
                senderName: getmyinfo.myinfo.name,
                senderTel: getmyinfo.myinfo.tel,
                senderEmail: getmyinfo.myinfo.email,
                text: "",
                messageConfirm: allitemForStatus.alltask[0].endDate,
                taskName: allitemForStatus.alltask[0].child[index].msg,
                oldDate: allitemForStatus.alltask[0].child[index].endDate,
                myAvatar: myinfos.users.myavatar

            })
                .then(function (response) {

                    handleGetNotify()
                    toast({
                        position: "top",
                        title: 'Отправлено на подтверждение постановщику задачи',
                        description: "Отправлено на подтверждение постановщику задачи",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            // update parent
            axios.post('/notifyConfirm', {
                eidtTaskID: allitemForStatus._id,
                isChild: false,
                adminIDTask: allitemForStatus?.creatorTasks.toString(),
                typenotify: "status",
                indexTask: 0,
                senderName: getmyinfo.myinfo.name,
                senderTel: getmyinfo.myinfo.tel,
                senderEmail: getmyinfo.myinfo.email,
                text: "",
                messageConfirm: allitemForStatus.alltask[0].endDate,
                taskName: allitemForStatus.alltask[0].taskName,
                oldDate: allitemForStatus.alltask[0].endDate,
                myAvatar: myinfos.users.myavatar

            })
                .then(function (response) {

                    handleGetNotify()
                    toast({
                        position: "top",
                        title: 'Отправлено на подтверждение постановщику задачи',
                        description: "Отправлено на подтверждение постановщику задачи",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                })
                .catch(function (error) {
                    console.log(error);
                });
        }


    }

    // этот функция для того чтобы создать уведомление для задач
    function sendSeverCreatNotifyForTask(e) {

        if (isChild) {
            let notifyText = `${getmyinfo.myinfo.name}  взял <a target="_blank" class="taskName" href="editChild/${allitemForStatus._id}/${index}">${allitemForStatus.alltask[0].child[index].taskName} </a> в работу.`
            axios.post('/creatNotifyForTaskInfo/api', {
                // здесь не проверяем Это задача и подзадача
                isChild,
                // здесь мы отправляем полное задание
                task: allitemForStatus,
                // если это подзадача тогда получаем индекс этого подзадачу
                index,
                // здесь мы отправляем текст для уведомлений
                notifyText,
                // кому нужно отправить этот уведомление мы берём идентификатор этого пользователя
                notificationRecipientID: allitemForStatus?.creatorTasks,
                // информация пользователя для отправления уведомлений на электронную почту
                emailInfo: {
                    // здесь мы отправляем идентификатор пользователя
                    userIdForEmail: allitemForStatus?.creatorTasks,
                    // текст для заголовки
                    subject: "Задача в работе",
                    // текст для электронную почту
                    textEmail: ""
                }
            })
                .then(function (response) {
                    console.log(response);
                    socket.emit('getNotifyEmit');
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            // для подзадач=================================================================================================================================================================================================
            let notifyText = `${getmyinfo.myinfo.name} взял  <a target="_blank" class="taskName" href="getCurrentTask/${allitemForStatus._id}">Задачу ${allitemForStatus.alltask[0].taskName} </a>  в работу.`
            axios.post('/creatNotifyForTaskInfo/api', {
                // здесь не проверяем Это задача и подзадача
                isChild,
                // здесь мы отправляем полное задание
                task: allitemForStatus,
                //  // если это подзадача тогда получаем индекс этого подзадачу
                index: 1234,
                // здесь мы отправляем текст для уведомлений
                notifyText,
                // кому нужно отправить этот уведомление мы берём идентификатор этого пользователя
                notificationRecipientID: allitemForStatus?.creatorTasks,
                // информация пользователя для отправления уведомлений на электронную почту
                emailInfo: {
                    // здесь мы отправляем идентификатор пользователя
                    userIdForEmail: allitemForStatus?.creatorTasks,
                    // текст для заголовки
                    subject: "Задача в работе",
                    // текст для электронную почту
                    textEmail: ""
                }
            })
                .then(function (response) {
                    console.log(response);
                    socket.emit('getNotifyEmit');
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    const handleStatusAndSendNotify = (e) => {
        if (isChild) {

            allitemForStatus.alltask[0].child[index].status = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    // send notify
                    sendSeverCreatNotifyForTask(e)

                    toast({
                        position: "top",
                        title: response.data.message,
                        description: response.data.message,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                    update();

                })
                .catch(function (error) {
                    update();
                });

        } else {
            allitemForStatus.alltask[0].status = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    sendSeverCreatNotifyForTask(e)
                    update()
                    toast({
                        position: "top",
                        title: response.data.message,
                        description: response.data.message,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })

                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        }
    }
    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "dropdownStatusCreatDark" : "dropdownStatusCreatLight"}>

            <Popover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}>
                <PopoverTrigger>
                    <div >
                        <div >
                            <div >
                                <p>Статус:</p>
                                <div>
                                    <button style={{ backgroundColor: statusColor }} >
                                        {statusGet}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                                        </svg>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </PopoverTrigger>
                <PopoverContent borderRadius={"0 0 12px 12px"} overflow={"hidden"} width={"230px"}>
                    <PopoverBody className={getDarkModoe.ToggleDarkModeReducer.chekced ? "popoverForCreatStatusDark" : "popoverForCreatStatusLight"} borderEndRadius={"0 0 12px 12px"} overflow={"hidden"}>
                        {
                            (() => {
                                if (getmyinfo.myinfo._id === allitemForStatus?.creatorTasks) {
                                    return <>
                                        <div onClick={() => [handleUpdateStatus("Новая"), onClose()]} >
                                            <div>
                                                <div>
                                                    <p>Новая</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => [handleUpdateStatus("Выполнена"), onClose()]}>
                                            <div>
                                                <div>
                                                    <p>Выполнена</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => [handleUpdateStatus("Закрыта"), onClose()]}>
                                            <div>
                                                <div>
                                                    <p>Закрыта</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => [handleUpdateStatus("возвращена"), onClose()]}>
                                            <div>
                                                <div>
                                                    <p>Возвращена</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => [handleUpdateStatus("Выполняется"), onClose()]}>
                                            <div>
                                                <div>
                                                    <p>Выполняется</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => [handleUpdateStatus("Отменено"), onClose()]}>
                                            <div>
                                                <div>
                                                    <p>Отменена</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                } else {
                                    return <>
                                        <div onClick={() => [handleUpdateStatus("Новая"), onClose()]}>
                                            <div>
                                                <div>
                                                    <p>Новая</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => [handleUpdateStatus("Выполнена"), handleStatus()]}>
                                            <div>
                                                <div>
                                                    <p>Выполнена</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => [onClose()]}>
                                            <div>
                                                <div>
                                                    <p>Закрыта</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => [handleUpdateStatus("возвращена"), onClose()]}>
                                            <div>
                                                <div>
                                                    <p>Возвращена</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => [handleUpdateStatus("Выполняется"), onClose()]}>
                                            <div>
                                                <div>
                                                    <p>Выполняется</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={() => [onClose()]}>
                                            <div>
                                                <div>
                                                    <p>Отменена</p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            })()
                        }

                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </div>
    );
};

export default DropdownMobileStatus;
