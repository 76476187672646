import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { Navigate } from "react-router";
import { NavLink } from "react-router-dom";
import { Avatar, Textarea, AvatarBadge, Img, shouldForwardProp } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import Mydata from "../../settingsUser/settingsPage/mydata/mydata";
import FileRenderComment from "../commentBox/fileRenderComment";
import InputEmoji from 'react-input-emoji'
import { getLinkPreview, getPreviewFromContent } from "link-preview-js";
import UrlPreview from "../UrlPreview/urlPreview";
import { useToast } from '@chakra-ui/react'
import sockets from "../../../../socket/socket";
import EmojiPicker from 'emoji-picker-react';

import CommentPopover from "../commentPopover/commentPopover";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'
import FileRenderCommentCreat from "../fileRenderCommentCreat/fileRenderCommentCreat";

const socket = sockets
function getDate(params) {
  return new Date().toLocaleString()
}

export default function CommentBoxCreatTask({ comments, setComments }) {



  const [commentsVal, setcommentsVal] = useState("");
  const [date, setDate] = useState("")
  const getmyinfo = useSelector(info => info)
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});
  const [images, setImages] = useState([])
  const fileInputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [toggle, setToggle] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [indexFile, setindexFile] = useState(0)
  const [deleteFiles, setdeleteFiles] = useState([])
  const [text, setText] = useState('')
  const toast = useToast()
  const getimg = useSelector(getImg => getImg)
  let onlineUSers = useSelector(onlineUSers => onlineUSers)
  const getDarkModoe = useSelector(darkModes => darkModes);


  const textAreaRef = useRef(null);
  const canchelEdit = () => {

    //  setImages((prev) => [...prev, ...deleteFiles]);


    setImages(prev => [...prev, ...deleteFiles])
    setdeleteFiles([])
    setcommentsVal("")
    setImages([])
    setIsEdit(false)

  }
  // upload server and set image
  function resetFileInput() {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the value of the file input
    }
  }

  const handleImageChange = (e) => {
    setFiles([])
    const selectedFiles = Array.from(e.target.files); // Convert FileList to an array
    setFiles([...files, ...selectedFiles]);
    setImages([...files, ...selectedFiles])


  };

  // ==========================

  useEffect(() => {
    socket.on('online', (users) => {
      setOnlineUsers(users);
    });
    return () => {

      socket.off('online');
      socket.off('disconnect');
    };
  }, [])

  // добавить комментарий с помощью Enter
  const addComment = (e) => {
    setDate(new Date().toLocaleString())
    if (e.key === "Enter") {

      setComments(comments => [...comments, {
        commentsVal,
        date,
        tel: getmyinfo.getMayInfoReducer.myinfo.tel,
        name: getmyinfo.getMayInfoReducer.myinfo.name,
        myid: getmyinfo.getMayInfoReducer.myinfo._id,
        images,
        whoReadThisComment: []
      }])

      setcommentsVal("")
      setImages([])
      setFiles([])
      resetFileInput()

    }

  }


  const placeCursorAtBeginning = () => {
    if (textAreaRef.current) {
      textAreaRef.current.setSelectionRange(0, 0);
      textAreaRef.current.focus();
    }
  };


  // add comment widht button

  const addCommentBtn = (e) => {
    if (e.key === 'Enter') {
      setDate(new Date().toLocaleString())
      setComments(comments => [...comments, {
        myid: getmyinfo.getMayInfoReducer.myinfo._id,
        commentsVal,
        date,
        tel: getmyinfo.getMayInfoReducer.myinfo.tel,
        name: getmyinfo.getMayInfoReducer.myinfo.name,
        myImg: getmyinfo.getMayInfoReducer.myinfo.img,
        images

      }])
      placeCursorAtBeginning();
      setcommentsVal("")
      setImages([])
      resetFileInput()

    }

  }

  // удалить комментарий
  function deleteComment(item, index) {
    setComments(prevArray => {
      const newArray = [...prevArray]; // Create a new array copy
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the modified array
    });

  }

  // установить смайлы setcommentsVal
  const handleEmojiClick = (emoji) => {
    setcommentsVal(val => val + emoji.emoji);
  };


  // dropdown toggle
  const handleShoweDropDown = (e) => {
    setToggle(!toggle);
  }
  const handleAll = (e) => {
    try {
      const parentElement = document.getElementById('dropdownCommentEmojy'); // Replace 'parentElement' with the actual parent element's ID
      if (parentElement != undefined && parentElement != null) {
        const isChild = parentElement?.contains(e.target);
        if (!isChild) {
          setToggle(false);;
        }
      }
    } catch (error) {
      console.log(error);
    }

  }
  // 
  window.addEventListener('click', handleAll);



  // получить все комментарии для редактирования

  const handleAllComments = (item, index) => {
    setcommentsVal("")
    setImages([])
    setcommentsVal(item.commentsVal)
    setImages(item.images)
    setIsEdit(true)
    setindexFile(index)
  }

  // отправить все комментарии для редактирования
  const submitEditComment = (e) => {
    if (e.key === "Enter") {
      canchelEdit()
      // Create a copy of the existing comments array
      const updatedComments = [...comments];
      // Update the specific comment object at indexFile
      updatedComments[indexFile] = {
        ...updatedComments[indexFile], // Preserve other properties
        commentsVal: commentsVal,
        images: images,
      };
      // Set the updated comments state
      setComments(updatedComments);
      setcommentsVal("")
      setImages([])
      setIsEdit(false)
    }

  }


  // удалить файл
  const deleteFileInLocal = (e) => {
    setImages(prevState => prevState.filter((_, i) => i !== e))
    setdeleteFiles(prevState => [...prevState, images[e]])
    setFiles(prevState => prevState.filter((_, i) => i !== e))

  }



  function handleOnEnter(text) {
    console.log('enter', text)
  }
  // эта функция для автоматической высоты для textarea
  const adjustHeight = (el) => {
    el.style.height = 'auto';
    el.style.height = el.scrollHeight + 'px';
  };
  // эта функция для автоматической высоты для textarea
  useEffect(() => {
    if (textAreaRef.current) {
      adjustHeight(textAreaRef.current);
    }
  }, [commentsVal]);
  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "CommentAllContDark" : "CommentAllContLight"}>
      {/* <div>
        <div>
          <p className="CommentTextp" contentEditable={true}>Комментарии</p>
          <div className="commentBox">
            <div className="ContFiles">
              {
                images?.map((item, index) => {
                  return <FileRenderComment files={item} showButton={true} deletFile={deleteFileInLocal} index={index} allFiles={images} />
                })
              }
            </div>
            <textarea rows="3" placeholder="Здесь вы можете оставить комментарий к задаче" onKeyDown={addComment} value={commentsVal} onChange={(e) => setcommentsVal(e.target.value)} />
            <div className="CommentIcons">
              <label htmlFor="uploadFileForComment">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4452 12.0909H7.46248C4.89784 12.0909 2.61543 10.1436 2.37001 7.49636C2.08778 4.46727 4.38246 1.90909 7.25387 1.90909H22.4208C24.0283 1.90909 25.4886 3.10545 25.6481 4.76C25.8322 6.66909 24.3965 8.27273 22.5926 8.27273H9.70807C9.03317 8.27273 8.48097 7.7 8.48097 7C8.48097 6.3 9.03317 5.72727 9.70807 5.72727H20.4452C20.9483 5.72727 21.3655 5.29455 21.3655 4.77273C21.3655 4.25091 20.9483 3.81818 20.4452 3.81818H9.87987C8.27236 3.81818 6.81212 5.01454 6.65259 6.66909C6.46853 8.57818 7.90423 10.1818 9.70807 10.1818H22.384C24.9487 10.1818 27.2311 8.23454 27.4765 5.58727C27.7587 2.54545 25.464 0 22.5926 0H7.5361C4.01433 0 0.86068 2.67273 0.529363 6.31273C0.161233 10.5 3.30261 14 7.25387 14H20.4452C20.9483 14 21.3655 13.5673 21.3655 13.0455C21.3655 12.5236 20.9483 12.0909 20.4452 12.0909Z" fill="#C3CAD9" />
                </svg>
                <input type="file" id="uploadFileForComment" style={{ display: "none" }} multiple onChange={handleImageChange} ref={fileInputRef} />
              </label>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="23" viewBox="0 0 26 23" fill="none" onClick={isEdit ? submitEditComment : addCommentBtn}>
                <path d="M10.416 15.1585L10.0007 21.5801C10.5949 21.5801 10.8522 21.2995 11.1608 20.9626L13.9467 18.0358L19.7192 22.683C20.7778 23.3315 21.5237 22.99 21.8093 21.6123L25.5984 2.09461L25.5994 2.09346C25.9352 0.373064 25.0335 -0.299682 24.002 0.122366L1.73002 9.49597C0.210003 10.1446 0.233018 11.0761 1.47163 11.4981L7.16568 13.445L20.3918 4.34744C21.0143 3.89435 21.5802 4.14505 21.1147 4.59814L10.416 15.1585Z" fill="#3B79F6" />
              </svg>
             
              <div className="dropdownCommentEmojy" id="dropdownCommentEmojy">
                <InputEmoji
                  keepOpened
                  onChange={setcommentsVal}
                  disableRecent
                  onEnter={() => handleEmojiClick(commentsVal)}
                  placeholder="Type a message"
                />
              </div>
            </div>
          </div>
         
          {
            isEdit ?
              <div className="Editcanchel">
                <div>
                  <p>Редактируемое сообщение</p>
                  <p>{commentsVal}</p>
                </div>
                <span class="material-symbols-outlined" onClick={canchelEdit}>close</span>
              </div>
              :
              ""
          }
          <div className="comments">
            {
              comments.map((item, index) => {
                console.log(item, "item");
                return (
                  <div className="senderCommentInfoCont" key={index} >
                    <div className="senderCommentInfoCont">
                      <div>
                        {
                          onlineUSers.getOnlineUserViaRouterReducer.users.data?.includes(item.myid) ?
                            <Avatar size="lg" name="Christian Nwamba" src={getimg.getMyImageReducer.users.myavatar}>
                              <AvatarBadge boxSize='0.9em' bg='green.500' />
                            </Avatar>
                            :
                            <Avatar size="lg" name="Christian Nwamba" src={getimg.getMyImageReducer.users.myavatar} />
                        }
                      </div>
                      <div className="senderCommentInfo">
                        <div className="senderNameCont">
                          <p className="senderName"> {item.name ? item.name : "без имени"}</p>
                          <p className="commentTime">{item.date}</p>
                        </div>
                      
                        <p className="CommentMessage">
                          <UrlPreview url={`${item.commentsVal}`} />

                        </p>
                        <div className="ContFiles">
                          {
                            item.images?.map((itemChild, indexChild) => {
                              return <FileRenderComment files={itemChild} showButton={false} deletFile={deleteFileInLocal} index={indexChild} allFiles={item.images} />
                            })
                          }
                        </div>
                        <div className="deletAndEditButton">
                          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none" onClick={() => handleAllComments(item, index)}>
                            <path d="M7.54953 4.2742L8.17752 4.9274L1.99318 11.36H1.3652V10.7068L7.54953 4.2742ZM10.0069 0C9.83623 0 9.65876 0.071 9.52906 0.2059L8.27991 1.5052L10.8396 4.1677L12.0888 2.8684C12.355 2.5915 12.355 2.1442 12.0888 1.8673L10.4915 0.2059C10.355 0.0639 10.1844 0 10.0069 0ZM7.54953 2.2649L0 10.1175V12.78H2.55974L10.1093 4.9274L7.54953 2.2649Z" fill="#3B79F6" />
                          </svg>

                          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none" onClick={() => deleteComment(index)}>
                            <path d="M3.08594 13.2509C2.73145 13.2509 2.42798 13.1466 2.17554 12.9379C1.9231 12.7293 1.79688 12.4785 1.79688 12.1855V2.06481H0.916016V0.999467H4.95508V0.466797H10.627V0.999467H14.666V2.06481H13.7852V12.1855C13.7852 12.4696 13.6562 12.7182 13.3984 12.9313C13.1406 13.1444 12.8398 13.2509 12.4961 13.2509H3.08594ZM12.4961 2.06481H3.08594V12.1855H12.4961V2.06481ZM5.36328 10.6586H6.65234V3.57404H5.36328V10.6586ZM8.92969 10.6586H10.2187V3.57404H8.92969V10.6586Z" fill="#E62E7B" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>


   
      </div> */}

      <div>
        <div >
          <div>
            <div>

              {
                isEdit ?
                  <p>Редактируемое сообщение</p>
                  :
                  <p>Комментарии</p>
              }
              <div>
                <div>
                  <textarea name="" id="" onKeyDown={(e) => isEdit ? submitEditComment(e) : addComment(e)} value={commentsVal} ref={textAreaRef} onChange={(e) => [setcommentsVal(e.target.value), setDate(new Date().toLocaleString())]}></textarea>
                  <div className="editIamgeCont">
                    {
                      images?.map((item, index) => {
                        return <FileRenderCommentCreat files={item} showButton={true} deletFile={deleteFileInLocal} index={index} allFiles={images} key={index} />
                      })
                    }
                  </div>
                </div>

                <div className="commentOptions">
                  <label  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24" fill="none">
                      <path d="M19.0899 11.5008L11.9558 18.635C11.0818 19.509 9.8964 20 8.66039 20C7.42437 20 6.23899 19.509 5.365 18.635C4.491 17.761 4 16.5756 4 15.3396C4 14.1035 4.491 12.9182 5.365 12.0442L12.4992 4.90999C13.0819 4.32733 13.8721 4 14.6961 4C15.5201 4 16.3104 4.32733 16.893 4.90999C17.4757 5.49266 17.8031 6.28292 17.8031 7.10692C17.8031 7.93093 17.4757 8.72119 16.893 9.30385L9.75109 16.438C9.45976 16.7294 9.06462 16.893 8.65263 16.893C8.24062 16.893 7.84549 16.7294 7.55416 16.438C7.26283 16.1467 7.09916 15.7516 7.09916 15.3396C7.09916 14.9276 7.26283 14.5324 7.55416 14.2411L14.1449 7.65809" stroke="#767676" stroke-width="1.16445" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <input type="file" id="uploadFileForComment" style={{ display: "none" }} multiple onChange={handleImageChange} ref={fileInputRef} />
                  </label>
                  <div>
                    <Popover placement='auto'>
                      <PopoverTrigger>
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                          <path d="M9.5 17.5C5.08172 17.5 1.5 13.9182 1.5 9.5C1.5 5.08172 5.08172 1.5 9.5 1.5C13.9182 1.5 17.5 5.08172 17.5 9.5C17.5 13.9182 13.9182 17.5 9.5 17.5Z" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M13.0984 11.5C13.0984 11.5 11.8984 13.1 9.49844 13.1C7.09844 13.1 5.89844 11.5 5.89844 11.5" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M12.2984 7.09688C12.0776 7.09688 11.8984 6.91779 11.8984 6.69688C11.8984 6.47596 12.0776 6.29688 12.2984 6.29688C12.5193 6.29688 12.6984 6.47596 12.6984 6.69688C12.6984 6.91779 12.5193 7.09688 12.2984 7.09688Z" fill="#767676" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M6.69688 7.09688C6.47596 7.09688 6.29688 6.91779 6.29688 6.69688C6.29688 6.47596 6.47596 6.29688 6.69688 6.29688C6.91779 6.29688 7.09688 6.47596 7.09688 6.69688C7.09688 6.91779 6.91779 7.09688 6.69688 7.09688Z" fill="#767676" stroke="#767676" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </PopoverTrigger>
                      <PopoverContent width={"auto"} padding={0} className="smileCommentContent">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody padding={0}> <EmojiPicker className="smileCommentContentEmoji" onEmojiClick={handleEmojiClick} allowExpandReactions={true} /></PopoverBody>
                      </PopoverContent>
                    </Popover>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="commentScrollDiv">

            {
              comments.map((item, index) => {
                return (
                  <div key={index} className="CommentListCont">
                    <div className="CommentItem">
                      <div>
                        {
                          onlineUSers.getOnlineUserViaRouterReducer.users.data?.includes(item.myid) ?
                            <Avatar size="md" name="Christian Nwamba" src={`/${getmyinfo.getMayInfoReducer.myinfo.img}`} zIndex={0}>
                              <AvatarBadge boxSize='0.9em' bg='green.500' zIndex={0} />
                            </Avatar>
                            :
                            <Avatar size="md" name="Christian Nwamba" src={`/${getmyinfo.getMayInfoReducer.myinfo.img}`} zIndex={0} />
                        }
                        <div className="firstDiv">
                          <div>
                            <div>
                              <p>{item.name}</p>
                              <p className="commentItemTime">{item.date} PM</p>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 13L9 17L19 7" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                            <div>
                              <UrlPreview url={`${item.commentsVal}`} />
                            </div>
                          </div>
                        </div>
                        <div className="lastDiv">
                          <CommentPopover deleteComment={deleteComment} handleAllComments={handleAllComments} item={item} index={index} />


                        </div>
                      </div>
                    </div>
                    <div className="localFilesRender">
                      {
                        item.images?.map((itemChild, indexChild) => {

                          return <FileRenderCommentCreat files={itemChild} showButton={false} key={index} deletFile={deleteFileInLocal} index={indexChild} allFiles={item.images} />
                        })
                      }
                    </div>
                  </div>
                )

              })

            }



          </div>
        </div>

      </div>

    </div>
  )
}