import { Radio, RadioGroup, Stack } from '@chakra-ui/react'

import "./checkedrdaio.css"
import { checkedSessionAction } from '../../../redux/reducer/getCheckedCompanySession/action/action';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getCompanyForChangeAPI } from '../../../redux/reducer/myCompanyAPI/action/action';
import { getWaldberisListAction } from '../../../redux/reducer/getwildberrieslIST/action/action';
import { useToast } from '@chakra-ui/react'
import { getOzonPorductList } from '../../../redux/reducer/ozonGetProductLIst/action/action';
import { useLocation } from 'react-router-dom'
import sockets from '../../../socket/socket';
import { getItemChatAction } from '../../../redux/reducer/getChatItem/action/action';
import { getWorkersForChat } from '../../../redux/reducer/получить сотрудников для чата/action/action';


export default function CheckedRadioBTN({ index, checked, children }) {
  const location = useLocation();
  const socket = sockets;
  const dispatch = useDispatch()
  const getLisProduct = useSelector(getLisProduct => getLisProduct)
  const allList = getLisProduct.getWaldberisListReducer
  const toast = useToast()
  let getCompanyID = useSelector(compID => compID.checkedSessionReducer);
  const getWorkersForChatinfo = useSelector(getWorkersForChatS => getWorkersForChatS)
  // здесь вы можете увидеть информацию о выбранной компании
  useEffect(() => {
    // socket.emit('joinRoom', getCompanyID.checkedSession);
  }, [])
  const handleChange = () => {

    dispatch(checkedSessionAction("/getCheckedCompanySession"))

    dispatch(getCompanyForChangeAPI("/changeapi/api"))
    dispatch(getWorkersForChat("/alldeploy/api"))
    socket.emit('joinRoom', getCompanyID.checkedSession);
    if (location.pathname == "/ozon") {
      dispatch(getOzonPorductList("/ozon/getProduct/api"))
    } else if (location.pathname == "/wildberries") {
      dispatch(getWaldberisListAction("/wildberis/getprodcutlist/api"))

      if (getLisProduct.getWaldberisListReducer.error) {
        toast({
          position: "top",
          title: 'проблема Wildberries API пожалуйста, перезагрузите страницу',
          description: getLisProduct.getWaldberisListReducer.error,
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    }



    //error Waldberis get product list



  }

  useEffect(() => {

    if (location.pathname == "/chat/conversations") {
      dispatch(getItemChatAction("/getItemChat/api?filter=Conversations"));


    } else if (location.pathname == "/chat/public") {
      dispatch(getItemChatAction("/getItemChat/api?filter=Public"));


    } else if (location.pathname == "/chat/private") {
      dispatch(getItemChatAction("/getItemChatPrivate/api?filter=private"));


    }
    else {
      dispatch(getItemChatAction("/getItemChatPrivate/api"));
    }

  }, [getCompanyID])


  if (checked) {
    return <div className='myCheckBoxContChecked'>
      <input type="radio" name="chekedCompany" id={`${"chekedCompany"}` + index} onClick={handleChange} defaultChecked={true} style={{ display: 'none' }} />
      <div >
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <rect x="4" y="4" width="12" height="12" rx="2" fill="#006AF5" />
      </svg>
      </div>

    </div>
  } else {
    return <div className='myCheckBoxCont'>
      <input type="radio" name="chekedCompany" id={`${"chekedCompany"}` + index} onClick={handleChange} defaultChecked={false} style={{ display: 'none' }} />
        <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M3.5 7C3.5 5.067 5.067 3.5 7 3.5H17C18.933 3.5 20.5 5.067 20.5 7V17C20.5 18.933 18.933 20.5 17 20.5H7C5.067 20.5 3.5 18.933 3.5 17V7Z" stroke="#1C304A" strokeOpacity="0.52" />
      </svg>
        </div>
    </div>
  }

}