import { useEffect, useState, useId, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import { fetchUsers } from "../../../../redux/reducer/postFetcReducer/action/action";
import "./perfomer.css"
import "./perfomerDark.css"

import axios from "axios";
import sockets from "../../../../socket/socket";

const socket = sockets
export default function Perfomer({  HandleSetPerfomerId, HandleDeletePerfomerId, onlineUsers, getperformer, bydefault }) {

  const dispatch = useDispatch();
  // здесь не получаем всех сотрудников на этой компании
  const gerChekcedCompany = useSelector((allWorkers) => allWorkers);
  const [users, setUsers] = useState([]);
  const [performer, setperformer] = useState(null);
  const openModalRef = useRef(null);
  const [show, setShow] = useState(false);
  const getDarkModoe = useSelector(darkModes => darkModes);
  const [adminIfno, setadminIfno] = useState()
  const [load, setLoda] = useState(false)

  // здесь мы добавляем на состояние всех сотрудников

  useEffect(() => {
    setperformer(bydefault)

  }, [bydefault])

  useEffect(() => {
    setUsers(gerChekcedCompany.rootReducer.users.data)

    setLoda(gerChekcedCompany.rootReducer.loading)

  }, [])



  // здесь ищем сотрудника по номеру телефона и имени
  const searchPerfomer = (e) => {

    const regexPattern = new RegExp(e, 'i');
    let x = gerChekcedCompany.rootReducer.users.data.filter(item => regexPattern.test(item.name) || regexPattern.test(item.tel))
    setUsers(x)
    if (e == "") {
      dispatch(fetchUsers())
      setUsers(gerChekcedCompany.rootReducer.users.data)
    }

  }

  // здесь мы генерируем новый ключ при создании заданий и добавление для исполнителя чтобы найти при редактирование этого  исполнителя
  const generadteID = (ids) => {
    return Math.random(Math.floor() * 10000).toString() + ids
  }

  // После нажатия кнопку добавляем нового сотрудника и обновляем состояние
  const perfomer = (e) => {
    console.log(e);
    setperformer(e);
    HandleSetPerfomerId(e)

  };

  const openModal = () => {
    setLoda(true)
    axios({
      method: 'get',
      url: '/getAdminInfo/api',

    })
      .then(function (response) {

        setadminIfno(response.data.data)

        setLoda(false)
      });
    openModalRef.current.style.display = "flex"

  }

  const closeModal = () => {
    openModalRef.current.style.display = "none"

  }
  const handleChanchel = () => {
    setperformer("");
    HandleSetPerfomerId("")
    openModalRef.current.style.display = "none"
  }

  useEffect(() => {

    openModalRef.current.style.display = "none"
  }, [])

  const handleClickOutside = (event) => {
    if (event.target.id === 'myModalPerforem') {
      openModalRef.current.style.display = "none"
    }
  }

  return (
    <>

      <div id="myModalPerforem" ref={openModalRef} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "modalPerfomerDark" : "modalPerfomer"} onClick={(e) => handleClickOutside(e)}>
        <div class="modal-contentPerfomer">
          <div class="modal-headerPerfomer">
            <div>
              <p>Выберите исполнителя</p>
              <div onClick={closeModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M1 1L15 15M1.00003 15L8.00003 8L15 1" stroke="#393939" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </div>
            </div>
            <label htmlFor="searchPerformer">

              <input type="text" placeholder="Поиск" onChange={(e) => searchPerfomer(e.target.value)} id="searchPerformer" />
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10Z" fill="#A8A8A8" />
                  <path d="M18.5304 17.4698C18.2375 17.1769 17.7626 17.1769 17.4697 17.4698C17.1768 17.7626 17.1768 18.2375 17.4697 18.5304L21.4696 22.5304C21.7625 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L18.5304 17.4698Z" fill="#A8A8A8" />
                </svg>
              </div>
            </label>
          </div>
          <div class="modal-bodyPerfomer">
            <div className="executorCont">
              {/* admin ========================== info */}
              {
                (() => {
                  if (adminIfno) {

                    return (
                      <div className="executor" onClick={(e) => perfomer(adminIfno)}>
                        <div>
                          {
                            (() => {
                              if (adminIfno.workerID == performer?.workerID) {
                                return (
                                  <div className="chekedDivTrue">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                                      <path d="M1.94635 3.96898C1.65345 3.67608 1.17858 3.67608 0.885686 3.96898C0.592792 4.26187 0.592792 4.73674 0.885686 5.02963L3.60804 7.75199C3.90093 8.04488 4.3758 8.04488 4.6687 7.75199L11.113 1.30767C11.4059 1.01478 11.4059 0.539907 11.113 0.247014C10.8201 -0.0458796 10.3452 -0.0458796 10.0524 0.247014L4.13837 6.161L1.94635 3.96898Z" fill="white" />
                                    </svg>
                                  </div>
                                )
                              } else {
                                return (
                                  <div className="chekedDivFalse"></div>

                                )
                              }
                            })()
                          }
                          {
                            onlineUsers?.includes(adminIfno.workerID) ?
                              <Avatar size="xs" src={adminIfno.img ? adminIfno.img : "https://bit.ly/broken-link"} >
                                <AvatarBadge boxSize='0.9em' bg='green.500' />
                              </Avatar>
                              :
                              <Avatar size="xs" src={adminIfno.img ? adminIfno.img : "https://bit.ly/broken-link"} />
                          }
                          <p className="perfName">{adminIfno.name} </p>
                          <p className="perfTel">  {adminIfno.tel}</p>
                          {/* <p className="perfEmail"> email: {adminIfno.email}</p>
                            <p className="perfEmail"> Роль: Руководитель</p> */}
                        </div>
                      </div>
                    )
                  }
                })()
              }


              {/* admin ========================== info */}
              {users?.map(
                (item, index) => {

                  item.key = generadteID(item.workerID)

                  return (
                    <div
                      key={index}
                      className="executor"
                      onClick={(e) => perfomer(item)}
                    >
                      <div>
                        {
                          (() => {
                            if (item.workerID == performer?.workerID) {
                              return (
                                <div className="chekedDivTrue">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
                                    <path d="M1.94635 3.96898C1.65345 3.67608 1.17858 3.67608 0.885686 3.96898C0.592792 4.26187 0.592792 4.73674 0.885686 5.02963L3.60804 7.75199C3.90093 8.04488 4.3758 8.04488 4.6687 7.75199L11.113 1.30767C11.4059 1.01478 11.4059 0.539907 11.113 0.247014C10.8201 -0.0458796 10.3452 -0.0458796 10.0524 0.247014L4.13837 6.161L1.94635 3.96898Z" fill="white" />
                                  </svg>
                                </div>
                              )
                            } else {
                              return (
                                <div className="chekedDivFalse"></div>

                              )
                            }
                          })()
                        }
                        {
                          onlineUsers?.includes(item.workerID) ?
                            <Avatar size="xs" src={item.img ? item.img : "https://bit.ly/broken-link"} >
                              <AvatarBadge boxSize='0.9em' bg='green.500' />
                            </Avatar>
                            :
                            <Avatar size="xs" src={item.img ? item.img : "https://bit.ly/broken-link"} />
                        }
                        <p className="perfName">{item.name ? item.name : "анонимный"}</p>
                        <p className="perfTel"> {item.tel}</p>
                        {/* <p className="perfEmail"> email: {item.email}</p> */}
                        {/* <p className="perfEmail"> Роль: {item.rols}</p> */}

                      </div>


                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="modal-footerPerfomer">
            <button onClick={handleChanchel}>
              Пропустить
            </button>
            <button onClick={closeModal}>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white" />
                </svg>
              </div>
              <div><p> Добавить</p></div>
            </button>
          </div>
        </div>
      </div>


      {/* ========================================================= */}

      <div  className={getDarkModoe.ToggleDarkModeReducer.chekced ? "selectPerformerDark" : "selectPerformerLight"}>
        <div>
 
          <div>
            {performer?.tel ? (
              <div className={"performerTag"} onClick={openModal}>
                {onlineUsers?.includes(performer.workerID) ? (
                  <Avatar size="sm" src={performer.img ? performer.img : "https://bit.ly/broken-link"} style={{ marginRight: "5px", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)" }} >
                    <AvatarBadge boxSize='0.9em' bg='green.500' />
                  </Avatar>
                ) : (
                  <Avatar size="sm" src={performer.img ? performer.img : "https://bit.ly/broken-link"} style={{ marginRight: "5px", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)" }} />
                )}
                {
                  performer.name ?
                    <p className="perfName">{performer.name}</p>
                    :
                   <p className="perfName">{performer.tel}</p>
                }
                {/* {
                  performer.tel ?
                    <p className="perfTel">{performer.tel}</p>
                    :
                    ""
                }
                {
                  performer.email ?
                    <p className="perfEmail">{performer.email}</p>
                    :
                    ""
                } */}
                <div onClick={() => { HandleDeletePerfomerId(performer); setperformer(""); }}>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 6V3.5H15V6H8.5ZM7.75 7.5H15.75H20.75C21.1642 7.5 21.5 7.16421 21.5 6.75C21.5 6.33579 21.1642 6 20.75 6H16.5V3.35C16.5 2.60444 15.8956 2 15.15 2H8.35C7.60442 2 7 2.60442 7 3.35V6H2.75C2.33579 6 2 6.33579 2 6.75C2 7.16421 2.33579 7.5 2.75 7.5H7.75ZM5.5 10.75C5.5 10.3358 5.16421 10 4.75 10C4.33579 10 4 10.3358 4 10.75V20.15C4 20.8956 4.60444 21.5 5.35 21.5H18.15C18.8956 21.5 19.5 20.8956 19.5 20.15V10.75C19.5 10.3358 19.1642 10 18.75 10C18.3358 10 18 10.3358 18 10.75V20H5.5V10.75ZM10.5 10.75C10.5 10.3358 10.1642 10 9.75 10C9.33579 10 9 10.3358 9 10.75V16.75C9 17.1642 9.33579 17.5 9.75 17.5C10.1642 17.5 10.5 17.1642 10.5 16.75V10.75ZM13.75 10C14.1642 10 14.5 10.3358 14.5 10.75V16.75C14.5 17.1642 14.1642 17.5 13.75 17.5C13.3358 17.5 13 17.1642 13 16.75V10.75C13 10.3358 13.3358 10 13.75 10Z" fill="#DD5E98" />
                    </svg>
                  </div>
                  <p>Удалить</p>
                </div>

              </div>
            ) :

              ""}
            {
              performer ?
                ""
                :

                <button class="perfomerOpenModal" onClick={openModal}>
                  <div>
                    <div class="svgContBTN"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                      <path d="M11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8V10.25H8C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75H10.25V14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14V11.75H14C14.4142 11.75 14.75 11.4142 14.75 11C14.75 10.5858 14.4142 10.25 14 10.25H11.75V8Z" fill="white">
                      </path><path fill-rule="evenodd" clip-rule="evenodd" d="M11 0.25C5.06294 0.25 0.25 5.06294 0.25 11C0.25 16.9371 5.06294 21.75 11 21.75C16.9371 21.75 21.75 16.9371 21.75 11C21.75 5.06294 16.9371 0.25 11 0.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75C16.1086 1.75 20.25 5.89137 20.25 11C20.25 16.1086 16.1086 20.25 11 20.25C5.89137 20.25 1.75 16.1086 1.75 11Z" fill="white">
                      </path>
                    </svg>
                    </div>
                    <p>Выберите исполнителя</p>
                  </div>
                </button>
            }
          </div>

        </div>

      </div>



    </>
  )

}