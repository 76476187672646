import axios from "axios"
import { useState } from "react";
import "./uploadFile.css"
import "./uploadFileDark.css"
import { useSelector } from "react-redux";
import zipIcon from "./iconUpload/zipIcon.png"
import pdf from "./iconUpload/pdf.png"
import exel from "./iconUpload/exel.png"
import world from "./iconUpload/world.png"
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
const random = () => {
  return Math.random(Math.floor() * 100000)
}
export default function UploadFileTaskMeneger({ handleChangeFile }) {

  const [files, setFile] = useState([]);
  const [fileName, setFileName] = useState('');
  const [realfileData, setrealfileData] = useState('');
  const [params, setParams] = useState("");
  const [progress, setProgress] = useState(0);
  const getDarkModoe = useSelector(darkModes => darkModes);

  function generateUniqueId() {
    const timestamp = Date.now().toString(36); // Convert current timestamp to base36 string
    const randomChars = Math.random().toString(36).substr(2, 5); // Generate random characters

    return timestamp + randomChars;
  }

  let getCompanyID = useSelector(compID => compID.checkedSessionReducer)
  let getmyinfo = useSelector(compID => compID.getMayInfoReducer)

  const handleFileChange = (e) => {
    handleChangeFile(e.target.files);
    const selectedFiles = Array.from(e.target.files);

    setFile(selectedFiles)
  };

  const handleDeleteLocal = (e) => {
    setFile((prevFiles) => prevFiles.filter((_, index) => index !== e));
    // setFile(null)
    // setFileName("")
    // setrealfileData("")
    // console.log(params);
    // axios.post('/deletefileByKey/api', {
    //   allFileOption: params,
    //   idFiles: params.idFiles
    // })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });

  }

  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "uploadFileDark" : "uploadFileLight"}>
      {/* <label htmlFor="upload">
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
          <path opacity="0.3" d="M14.0003 4.66663C8.85533 4.66663 4.66699 8.85496 4.66699 14C4.66699 19.145 8.85533 23.3333 14.0003 23.3333C19.1453 23.3333 23.3337 19.145 23.3337 14C23.3337 8.85496 19.1453 4.66663 14.0003 4.66663ZM19.8337 15.1666H15.167V19.8333H12.8337V15.1666H8.16699V12.8333H12.8337V8.16663H15.167V12.8333H19.8337V15.1666Z" fill="white" />
          <path d="M15.1663 8.16671H12.833V12.8334H8.16634V15.1667H12.833V19.8334H15.1663V15.1667H19.833V12.8334H15.1663V8.16671ZM13.9997 2.33337C7.55967 2.33337 2.33301 7.56004 2.33301 14C2.33301 20.44 7.55967 25.6667 13.9997 25.6667C20.4397 25.6667 25.6663 20.44 25.6663 14C25.6663 7.56004 20.4397 2.33337 13.9997 2.33337ZM13.9997 23.3334C8.85467 23.3334 4.66634 19.145 4.66634 14C4.66634 8.85504 8.85467 4.66671 13.9997 4.66671C19.1447 4.66671 23.333 8.85504 23.333 14C23.333 19.145 19.1447 23.3334 13.9997 23.3334Z" fill="white" />
        </svg>
        <p>Добавить файлы</p>
        <input type="file" id="upload" style={{ display: "none" }} onChange={handleFileChange} />
      </label>
      {(() => {
        if (files) {
          if (files.type == "image/png" || files.type == "image/jpeg") {
            return (
              <div >
                <div className="imgUploadCont">
                  <img src={URL.createObjectURL(files)} alt="asd" />
                  <span class="material-symbols-outlined" onClick={handleDeleteLocal}>delete_forever</span>

                </div>
                <p>{realfileData.name}</p>
              </div>
            )


          } else if (files.type == "application/x-zip-compressed") {
            return (
              <div>
                <div className="ZipUploadCont"  >
                  <h1 style={{ cursor: "pointer" }} ><img src={zipIcon} alt="" /></h1>
                  <span class="material-symbols-outlined" onClick={handleDeleteLocal}>delete_forever</span>

                </div>
                <p>{realfileData.name}</p>
              </div>
            )
          }
          else if (files.type == "application/pdf") {
            return (
              <div>
                <div className="pdfUploadCont">
                  <h1 style={{ cursor: "pointer" }} ><img src={pdf} alt="" /></h1>
                  <span class="material-symbols-outlined" onClick={handleDeleteLocal}>delete_forever</span>
                </div>
                <p>{realfileData.name}</p>
              </div>
            )
          }
          else if (files.type == "video/mp4") {
            return (
              <div>
                <div className="pdfUploadCont" >
                  <video width="320" height="240" controls autoPlay>
                    <source src={URL.createObjectURL(files)} type={URL.createObjectURL(files)} />
                    <source src={URL.createObjectURL(files)} type={URL.createObjectURL(files)} />
                    Your browser does not support the video tag.
                  </video>
                  <span class="material-symbols-outlined" onClick={handleDeleteLocal}>delete_forever</span>
                </div>
                <p>{realfileData.name}</p>
              </div>
            )
          }
          else if (files.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return (
              <div>
                <div className="pdfUploadCont">
                  <h1 style={{ cursor: "pointer" }} ><img src={exel} alt="" /></h1>
                  <span class="material-symbols-outlined" onClick={handleDeleteLocal}>delete_forever</span>
                </div>
                <p>{realfileData.name}</p>
              </div>
            )
          }
          else if (files.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {

            return (
              <div>
                <div className="pdfUploadCont">
                  <h1 style={{ cursor: "pointer" }} ><img src={world} alt="" /></h1>
                  <span class="material-symbols-outlined" onClick={handleDeleteLocal}>delete_forever</span>
                </div>
                <p>{realfileData.name}</p>
              </div>
            )
          }
        }
      })()} */}
      <div>
        <div>
          <label htmlFor="upload">
            <p>Добавить файлы</p>
            <input type="file" id="upload" style={{ display: "none" }} onChange={handleFileChange} multiple />
          </label>
        </div>

        <div>


          {
            files.map((item, index) => {

              return (
                <div key={index} >
                  <div >
                    <div>
                      <div >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M7.5364 11.73C7.18492 11.3785 6.61508 11.3785 6.2636 11.73C5.91213 12.0814 5.91213 12.6513 6.2636 13.0027L9.53043 16.2696C9.8819 16.621 10.4517 16.621 10.8032 16.2696L18.5364 8.5364C18.8879 8.18492 18.8879 7.61508 18.5364 7.2636C18.1849 6.91213 17.6151 6.91213 17.2636 7.2636L10.1668 14.3604L7.5364 11.73Z" fill="#005FDB" />
                        </svg>
                        <p>{item.name}</p>
                      </div>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" onClick={() => handleDeleteLocal(index)}>
                          <path d="M11.1712 12L7.77521 8.60406C7.40826 8.23711 7.40826 7.64216 7.77521 7.27521C8.14216 6.90826 8.73711 6.90826 9.10406 7.27521L12.5 10.6712L15.8959 7.27521C16.2629 6.90826 16.8578 6.90826 17.2248 7.27521C17.5917 7.64216 17.5917 8.23711 17.2248 8.60406L13.8288 12L17.2248 15.3959C17.5917 15.7629 17.5917 16.3578 17.2248 16.7248C16.8578 17.0917 16.2629 17.0917 15.8959 16.7248L12.5 13.3288L9.10406 16.7248C8.73711 17.0917 8.14216 17.0917 7.77521 16.7248C7.40826 16.3578 7.40826 15.7629 7.77521 15.3959L11.1712 12Z" fill="#1C2E45" fill-opacity="0.6" />
                        </svg>
                      </div>
                    </div>
                    {item && (item.type === "image/png" || item.type === "image/jpeg") && (
                      <img src={URL.createObjectURL(item)} alt="Uploaded" />
                    )}
                    {item && (files.type === "video/mp4") && (
                      <video src={URL.createObjectURL(item)} alt="Uploaded" />
                    )}

                  </div>
                </div>
              )
            })
          }

        </div>
      </div>
    </div>
  )
}