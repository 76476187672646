import ChatBox from "../chatBox/chatBox"
import SubCommentItemMessage from "./subCommentItemMessage/subCommentItemMessage"

import "./tradeLight.css"
import "./tradeDark.css"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { perexodChatMenu } from "../../../../redux/reducer/perexodChatMenu/actiom/action"
import ChatBoxForSubMessage from "../chatBoxForSubMessage/chatBoxForSubMessage"
import { useEffect, useState } from "react"
import axios from "axios"
import { useSearchParams } from "react-router-dom"
import { getMessageItem } from "../../../../redux/reducer/getMessageItem/action/action"
import MessageItems from "../messageItems/messageItems"
import { getCurrentSubMessageAction } from "../../../../redux/reducer/getCurrentSubMessage/action/action"
import ReplyMessageItem from "../replyaMessageItem/replyaMessageItem"
import ReplaceSelectorForChatBodyTrade from "../replaceSelectorForChatBody/replaceSelectorForChatBodyTrade"

export default function Tarde({ otherChatOptionsFuncClose }) {
    const getDarkModoe = useSelector(darkModes => darkModes);
    const getCurrentSumMessage = useSelector(SumMessage => SumMessage).userReducerGerCurrentSubMessage;
    const [searchParams] = useSearchParams();
    const thread = searchParams.get('thread_message_id');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const otherMenuOptionsForMessageItemsReducer = useSelector((otherMenuOptionsForMessageItemsReducer) => otherMenuOptionsForMessageItemsReducer.TradeReducer
);
    const trade = useSelector(
        (trade) =>
          trade.TradeReducer
      );
    useEffect(() => {
           
            if (thread) {
                dispatch(getCurrentSubMessageAction(`/getMessageByID/api?thread=${thread}`))
            }
            
    }, [thread])
    

    const handleNavigate = async () => {

        const chat_filter = searchParams.get('chat_filter');
        console.log(chat_filter);
        if (chat_filter) {
            const chatID = searchParams.get('chatID');
            navigate(`?chatID=${chatID}`)
            dispatch(getMessageItem(`/getItemMessages/api?chatID=${chatID}`));
        }

    }
   
    
    const scrollToItem = async (item) => {
       
        // Получаем элемент по его id
        const element = document.getElementById(`message-${item.messageReplyID._id}`);
        // Проверяем, что элемент существует
        if (element) {
            // Прокручиваем к элементу
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
            if (getDarkModoe.ToggleDarkModeReducer.chekced) {
                element.style.backgroundColor = "#33363B"
            } else {
                element.style.backgroundColor = "#F4F8FE"

            }
            setTimeout(() => {

                element.style.backgroundColor = "";
            }, 2000)
        }
    };


    function convertDate() {
        // Парсим строку в объект Date
        const dateString = getCurrentSumMessage?.data.createdAt;
        const dateObject = new Date(dateString);

        // Получаем компоненты времени
        const hours = dateObject.getHours();
        const minutes = dateObject.getMinutes();
        const period = hours >= 12 ? "PM" : "AM";

        // Конвертируем часы в 12-часовой формат
        const twelveHourFormat = hours % 12 || 12;

        // Форматируем минуты, чтобы было две цифры
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        // Собираем результат в нужный формат
        const formattedTime = `${twelveHourFormat}:${formattedMinutes} ${period}`;

        return formattedTime

    }
    console.log(getCurrentSumMessage?.data);
    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "tradeDarktCont" : "tradeLightCont"}>
            <div className="tradeHeader">
                <div>
                    <div>
                        <p>Тред</p>
                        <p>Группа тестирование</p>
                    </div>
                    <div className="teadeCloseIconCont" onClick={otherChatOptionsFuncClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M1 1L15 15M1.00003 15L8.00003 8L15 1" stroke="#393939" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                    </div>
                </div>
                
            </div>

            <div className="tradeOtherChild">
                
            {
                Object.getOwnPropertyNames(getCurrentSumMessage?.data).length !== 0 &&
                    <div className="tardeMessageItem">
                        <img src={getCurrentSumMessage?.data?.senderInfo?.img?`/${getCurrentSumMessage?.data?.senderInfo?.img}`:"https://bit.ly/broken-link"} alt="" />
                        <div>
                            <div>
                                <div>
                                    <p>{getCurrentSumMessage?.data?.senderInfo?.name}</p>
                                    <p>{convertDate()}</p>
                                </div>
                                <div>
                                    <p>{getCurrentSumMessage?.data.messageText}</p>
                                </div>
                            </div>
                    </div>
                </div>
            }   
                    <button className="subCommentBtn">
                    
                    {
                       getCurrentSumMessage?.data?.subMessage &&  getCurrentSumMessage?.data?.subMessage.length + " коммтентарий"
                    }
                    </button>
                {
                   getCurrentSumMessage?.data?.subMessage?.map((item, index) => {
                    
                    if (item.typeMessage == "submessage") {
                        return (<>
                            <MessageItems item={item} key={index} handleNavigate={handleNavigate} child={true}/>
                         </>)
                    }else if(item.typeMessage == "subMessageReply")
                    return (<>
                             <ReplyMessageItem item={item} key={index} scrollToItem={scrollToItem}   child={true}/>
                     </>)
                    })
                }
                {
                    otherMenuOptionsForMessageItemsReducer && otherMenuOptionsForMessageItemsReducer.replyMessage && <ReplaceSelectorForChatBodyTrade />
                }
            </div>
            
            <ChatBoxForSubMessage isTrade={true} />
        </div>
    )
}