import React, { useEffect, useRef, useState } from "react";
import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'

import {


  useDisclosure,

  Button,
  Input
} from "@chakra-ui/react";

import ShablonCreatTask from "../ShablonCreatTask/shablonCreatTask";
import axios from "axios";
import { useSelector } from "react-redux";
import "./shablon.css"
import "./shablonDark.css"
import DeleteBtn from "./deleteShablon";
import { useToast } from '@chakra-ui/react'

export default function Shablon({ currentTask, perfomerID, receivedMessages, handleDelet, delLoad, update, handleGetNotify, sendMessage, closeParent }) {

  const [templates, satTemplates] = useState("");
  const [templatesArray, satTemplatesArray] = useState([]);
  const templatesRef = useRef(null);
  const [task, setTask] = useState([]);
  const [data, setData] = useState([]);
  const handleRef = useRef(null)

  const { isOpen, onToggle, onClose, onOpen } = useDisclosure();
  let getCompanyID = useSelector(compID => compID.checkedSessionReducer);
  let getmyinfo = useSelector(compID => compID.getMayInfoReducer);
  const getDarkModoe = useSelector(darkModes => darkModes);
  const toast = useToast()
  useEffect(() => {
    getMyShablon()
  }, [])


  useEffect(() => {
    setData(receivedMessages);
    handleRef.current.style.display = "none"
  }, []);

  let array = [];



  const getMyShablon = () => {
    axios({
      method: 'get',
      url: "/getShablone/api",
      params: {
        CompanyID: getCompanyID.checkedSession,
        creatorTask: getmyinfo.myinfo._id,
      }
    })
      .then(function (response) {

        setData([])
        setData(response.data.data)
      });
  }

  const handleTmpalte = (e) => {

    if (e.key === "Enter") {


      satTemplatesArray(templatesArray => [...templatesArray, templates])



      perfomerID.push(getmyinfo.myinfo)
      axios.post('/createTaskShablon/api', {
        CompanyID: getCompanyID.checkedSession,
        message: templates,
        task: currentTask,
        creatorTask: getmyinfo.myinfo._id,
        perfomerID,
        shablonName: templates
      })
        .then(function (response) {

          getMyShablon()
          satTemplates("")
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleTmpalteButton = (e) => {


    satTemplatesArray(templatesArray => [...templatesArray, templates])



    perfomerID.push(getmyinfo.myinfo)
    axios.post('/createTaskShablon/api', {
      CompanyID: getCompanyID.checkedSession,
      message: templates,
      task: currentTask,
      creatorTask: getmyinfo.myinfo._id,
      perfomerID,
      shablonName: templates
    })
      .then(function (response) {

        getMyShablon()
        satTemplates("")
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleShowModal = () => {
    // handleRef.current.style.display = "flex"
    toast({
      position:"top",
      title: 'данный функция находится на разработке',
      description: "данный функция находится на разработке.",
      status: 'warning',
      duration: 9000,
      isClosable: true,
    })
  }

  const handleCloseModal = () => {
    handleRef.current.style.display = "none"
  }

  const closeModal = (e) => {
    if (e.target.id === "myModalShablone") {
      handleRef.current.style.display = "none"
    }
  }
  return (
    <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "shablonDark" : "shablon"}>
      <button className="shablonBtn" onClick={handleShowModal}>
        <div>
          <div>
            <p>Выбрать шаблон</p>
          </div>
        </div>
      </button>

      <div id="myModalShablone" className="modalShablone" ref={handleRef} onClick={(e) => closeModal(e)}>


        <div className="modal-contentShablone">
          <div className="modal-HeadrShablon">
            <h2>Шаблоны</h2>
            <span className="closeShablone" onClick={handleCloseModal}>&times;</span>
          </div>
          <div className="ShablonContINpt">
            <input
              size="sm"
              type="text"
              placeholder="Имя шаблона"
              value={templates}


              placement="right"

              color={"#ACACB0"}
              onChange={(e) => satTemplates(e.target.value)}
              onKeyDown={handleTmpalte}
            />
            <button onClick={handleTmpalteButton}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path opacity="0.3" d="M15 5C9.4875 5 5 9.4875 5 15C5 20.5125 9.4875 25 15 25C20.5125 25 25 20.5125 25 15C25 9.4875 20.5125 5 15 5ZM21.25 16.25H16.25V21.25H13.75V16.25H8.75V13.75H13.75V8.75H16.25V13.75H21.25V16.25Z" fill="white" />
                <path d="M16.25 8.75H13.75V13.75H8.75V16.25H13.75V21.25H16.25V16.25H21.25V13.75H16.25V8.75ZM15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM15 25C9.4875 25 5 20.5125 5 15C5 9.4875 9.4875 5 15 5C20.5125 5 25 9.4875 25 15C25 20.5125 20.5125 25 15 25Z" fill="white" />
              </svg>
            </button>
          </div>
          <div className="searchShablonCont">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
              <path d="M12.1498 10.6918H11.3819L11.1098 10.4294C12.0623 9.32133 12.6358 7.88279 12.6358 6.3179C12.6358 2.82847 9.80732 0 6.3179 0C2.82847 0 0 2.82847 0 6.3179C0 9.80732 2.82847 12.6358 6.3179 12.6358C7.88279 12.6358 9.32133 12.0623 10.4294 11.1098L10.6918 11.3819V12.1498L15.5517 17L17 15.5517L12.1498 10.6918ZM6.3179 10.6918C3.89766 10.6918 1.94397 8.73814 1.94397 6.3179C1.94397 3.89766 3.89766 1.94397 6.3179 1.94397C8.73814 1.94397 10.6918 3.89766 10.6918 6.3179C10.6918 8.73814 8.73814 10.6918 6.3179 10.6918Z" fill="#C3CAD9" />
            </svg>
            <input placeholder='Искать' />
          </div>
          <div
            className={"shablonarray"}
            style={{ overflow: "auto" }}

          >


            <div className="shablonContAll">

              {
                data?.map((item, index) => {

                  return (
                    <div className="shablonCont" key={index}>
                      <p className="shablonNameSpan">{item.shablonName}</p>
                      <div className="shablonContChild">
                        <ShablonCreatTask closeParent={closeParent} editAllTask={item.alltask} itemID={item._id} update={update} sendMessage={sendMessage} allitem={item} data={data} handleGetNotify={handleGetNotify} />
                        <DeleteBtn item={item} setData={setData} />

                      </div>

                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}