
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ModalEditTask from '../modalEditTask/modalEditTask';

import { useSelector } from 'react-redux';
import TaskEdit from '../taskEdit/taskEdit';
import "./getCurrentTask.css"
import sockets from '../../../../socket/socket';
const socket = sockets;

export default function GetcurrentTask(params) {
  let { id } = useParams();
  const [item, setItem] = useState()
  let getCompanyID = useSelector(compID => compID.checkedSessionReducer)
  let getmyinfo = useSelector(compID => compID.getMayInfoReducer)
  const [adminInfo, setadminInfo] = useState("")
  const [load, setload] = useState(false)
  useEffect(() => {
    axios.post('/findTaskByUrl', {
      taskID: id
    })
      .then(function (response) {
       
        setItem(response.data.data)
        setadminInfo(response.data.adminIfno)

      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  useEffect(() => {


    // update all task for my data
    socket.on('getAllTaskANDupdate', () => {
      console.log("socket update : getAllTaskANDupdate");
      setload(true)
      console.log("UPDATEtASK");


      axios.post('/findTaskByUrl', {
        taskID: id
      })
        .then(function (response) {
          console.log(response.data.data, "getCurrentData");
          setItem(response.data.data)
        })
        .catch(function (error) {
          console.log(error);
        });

    });


    return () => {

      socket.off('getAllTaskANDupdate');

    };
  }, []);

  const handleGetNotify = () => {
    socket.emit('getNotifyEmit');
  }
  function update(params) {
    socket.emit('getallTaskforUpdateServer', { room: getCompanyID.checkedSession });
  }

  if (item) {
    return <TaskEdit editAllTask={item.alltask} itemID={item._id} update={update} allitem={item} data={item} handleGetNotify={handleGetNotify} key={"index"} adminInfo={adminInfo} />
  }

}