import { useEffect, useRef, useState } from "react"

import "./slider.css"
import slider1 from "../../../img/slideImg1.webp"
import slider2 from "../../../img/slideImg2.webp"
import slider3 from "../../../img/slideImg3.webp"
import slider4 from "../../../img/slideImg4.webp"

export default function SliderLending({ funcSetIndex }) {
      const [imgClassName, setimgClassName] = useState("img1")
      const [text1, setText1] = useState("Бесплатный мультипроектный таск-трекер с внутренним чатом для организации любых задач")
      const [text2, setText2] = useState("Трудно организовать распорядок задач внутри дня для сотрудников на нескольких проектах? Попробуйте бесплатный таск-треккер с внутренним чатом от Упрости.рф")
      const [index, setIndex] = useState(0); // Создаем состояние для индекса
      const [idTime, setIdTime] = useState(null)
      const [images, setImages] = useState([slider1, slider2, slider3, slider4]);
      const [loaded, setLoaded] = useState(false);
      const img1 = useRef(null);
      const img2 = useRef(null);
      const img3 = useRef(null);
      const img4 = useRef(null);
      const imgRefs = useRef([]);

      const [startX, setStartX] = useState(0);
      const [startY, setStartY] = useState(0);

      const handleTouchStart = (e) => {
            setStartX(e.touches[0].clientX);
            setStartY(e.touches[0].clientY);
      }
      const handleTouchEnd = (e) => {
            const endX = e.changedTouches[0].clientX;
            const endY = e.changedTouches[0].clientY;

            const diffX = startX - endX;
            const diffY = startY - endY;

            if (Math.abs(diffX) > Math.abs(diffY)) {
                  const classNames = ["img1", "img2", "img3", "img4"];
                  // Определение направления по горизонтали
                  if (diffX > 0) {
                        console.log('Swipe left');
                        if (index !== 3) {
                              const switchClass = () => {
                                    // Обновляем индекс в состоянии
                                    setIndex((prevIndex) => {
                                          const newIndex = (prevIndex + 1) % classNames.length;
                                          setimgClassName(classNames[newIndex]);
                                          return newIndex;
                                    });
                              };
                              switchClass()
                        } if (index > 2) {
                              const switchClass = () => {
                                    // Обновляем индекс в состоянии
                                    setIndex((prevIndex) => {
                                          const newIndex = (0) % classNames.length;
                                          setimgClassName(classNames[newIndex]);
                                          return newIndex;
                                    });
                              };
                              switchClass()

                        }
                        // Действие при свайпе влево
                  } else {
                        if (index !== 0) {
                              const switchClass = () => {
                                    // Обновляем индекс в состоянии
                                    setIndex((prevIndex) => {
                                          const newIndex = (prevIndex - 1) % classNames.length;
                                          setimgClassName(classNames[newIndex]);
                                          return newIndex;
                                    });
                              };
                              switchClass()
                        } else if (index == 0) {
                              const switchClass = () => {
                                    // Обновляем индекс в состоянии
                                    setIndex((prevIndex) => {
                                          const newIndex = (3) % classNames.length;
                                          setimgClassName(classNames[newIndex]);
                                          return newIndex;
                                    });
                              };
                              switchClass()
                        }

                  }
            }
      };

      function nextImg(className) {
            setimgClassName(className)
            if (className == "img1") {
                  img1.current.style.background = "white"
                  img2.current.style.background = "#656665"
                  img3.current.style.background = "#656665"
                  img4.current.style.background = "#656665"
                  setIndex(0)
            } else if (className == "img2") {
                  img1.current.style.background = "#656665"
                  img2.current.style.background = "white"
                  img3.current.style.background = "#656665"
                  img4.current.style.background = "#656665"
                  setIndex(1)
            } else if (className == "img3") {
                  img1.current.style.background = "#656665"
                  img2.current.style.background = "#656665"
                  img3.current.style.background = "white"
                  img4.current.style.background = "#656665"
                  setIndex(2)
            } else if (className == "img4") {
                  img1.current.style.background = "#656665"
                  img2.current.style.background = "#656665"
                  img3.current.style.background = "#656665"
                  img4.current.style.background = "white"
                  setIndex(3)
            }

      }

      useEffect(() => {
            const handleLoad = () => {
                  if (imgRefs.current.every((img) => img && img.complete)) {
                        setLoaded(true);
                  }
            };

            imgRefs.current.forEach((img) => {
                  if (img) {
                        img.addEventListener("load", handleLoad);
                  }
            });

            return () => {
                  imgRefs.current.forEach((img) => {
                        if (img) {
                              img.removeEventListener("load", handleLoad);
                        }
                  });
            };
      }, [images]);

      // set text slider 
      useEffect(() => {


            if (imgClassName === "img1") {
                  setText1("Бесплатный мультипроектный таск-трекер с внутренним чатом для организации любых задач")
                  setText2("Трудно организовать распорядок задач внутри дня для сотрудников на нескольких проектах? Попробуйте бесплатный таск-треккер с внутренним чатом от Упрости.рф")
            } else if (imgClassName === "img2") {
                  setText1("Простой, но функциональный таск-трекер, который сможет освоить каждый сотрудник проекта")
                  setText2("Наш таск-трекер не нужно долго внедрять и настраивать - управление интуитивно. Эффективное управление задачами: листай вниз, чтобы увидеть, как это работает.")
            } else if (imgClassName === "img3") {
                  setText1("Функциональный внутренний чат для рабочих задач с группами и обменом файлами")
                  setText2("Все ваши мессенджеры уже взрываются от рабочих чатов? Оставьте их для личных целей, а рабочие моменты уже давно стоит перенести в Упрости.рф!")
            } else if (imgClassName === "img4") {
                  setText1("Мобильная версия и мобильное приложение для удобного доступа в любой момент рабочего дня")
                  setText2("Используйте наш таск-трекер и чат, где бы не находились вы и сотрудники ваших проектов - задачи будут под рукой. Листай вниз и смотри, как это выглядит!")
            }
      }, [imgClassName])



      useEffect(() => {
            if (imgClassName == "img1") {
                  img1.current.style.background = "white"
                  img1.current.style.transition = "1s"
                  img2.current.style.background = "#656665"
                  img3.current.style.background = "#656665"
                  img4.current.style.background = "#656665"
            } else if (imgClassName == "img2") {
                  img1.current.style.background = "#656665"
                  img2.current.style.transition = "1s"
                  img2.current.style.background = "white"
                  img3.current.style.background = "#656665"
                  img4.current.style.background = "#656665"
            } else if (imgClassName == "img3") {
                  img1.current.style.background = "#656665"
                  img2.current.style.background = "#656665"
                  img3.current.style.background = "white"
                  img3.current.style.transition = "1s"
                  img4.current.style.background = "#656665"
            } else if (imgClassName == "img4") {
                  img1.current.style.background = "#656665"
                  img2.current.style.background = "#656665"
                  img3.current.style.background = "#656665"
                  img4.current.style.background = "white"
                  img4.current.style.transition = "1s"
            }
      }, [imgClassName])
      // timer
      useEffect(() => {
            if (images[index]) {
                  setLoaded(true);
            }
      }, [index]);
      useEffect(() => {
            if (loaded) {
                  const classNames = ["img1", "img2", "img3", "img4"];
                  const switchClass = () => {
                        setIndex((prevIndex) => {
                              const newIndex = (prevIndex + 1) % classNames.length;
                              setimgClassName(classNames[newIndex]);
                              return newIndex;
                        });
                  };

                  const intervalId = setInterval(switchClass, 6000);
                  setIdTime(intervalId);

                  return () => clearInterval(intervalId);
            }
      }, [loaded]);

      // // nav click set slider
      useEffect(() => {

            if (funcSetIndex() !== undefined) {
                  const classNames = ["img1", "img2", "img3", "img4"];
                  setIndex((prevIndex) => {
                        const newIndex = (funcSetIndex()) % classNames.length;
                        setimgClassName(classNames[newIndex]);
                        clearInterval(idTime)
                        return newIndex;
                  });
            }


      }, [funcSetIndex()]);

      return (
            <div className="sliderCont">
                  {images.map((image, idx) => (
                        <img
                              style={{ transition: "1s" }}
                              key={idx}
                              src={image}
                              alt=""
                              ref={(el) => (imgRefs.current[idx] = el)}
                              className={index === idx && loaded ? "active" : ""}
                              onTouchStart={handleTouchStart}
                              onTouchEnd={handleTouchEnd}
                        />
                  ))}
                  <div className="textContForMobile">
                        <div>
                              <div>
                                    <p>{text1}</p>
                                    <p>{text2}</p>
                              </div>
                        </div>
                  </div>
                  <div className="link">
                        <div>
                              <p>{text1}</p>
                              <p>{text2}</p>
                        </div>
                  </div>
                  <div className="pagitation">
                        <div id="img1" ref={img1} onClick={() => nextImg("img1")} ></div>
                        <div id="img2" ref={img2} onClick={() => nextImg("img2")}></div>
                        <div id="img3" ref={img3} onClick={() => nextImg("img3")}></div>
                        <div id="img4" ref={img4} onClick={() => nextImg("img4")}></div>
                  </div>
            </div>
      )
}
