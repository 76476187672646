import { useSelector } from "react-redux"
import { NavLink } from "react-router-dom"


export default function ForMobileOtherMenu(params) {
    const getDarkModoe = useSelector(darkModes => darkModes);
    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "otherNavItemsDarkContMobile" : "otherNavItemsLightContMobile"}>
            <div>
                <div className="row1">
                    <NavLink to={"/task"}>
                        <p>Задачи</p>
                        <div className="iconContOtherMenuTask">

                        </div>
                    </NavLink>
                    <NavLink to={"/chat"}>
                        <p>Мессенджер</p>
                        <div className="iconContOtherMenuMessanger">

                        </div>
                    </NavLink>
                    <NavLink to={"/settings/mydata"}>
                        <p>Настройки</p>
                        <div className="settingImgContSettings">

                        </div>
                    </NavLink>
                </div>
                <div className="row2">
                    <NavLink to={"/settings/AddNewUser"}>
                        <p>Сотрудники</p>
                        <div className="iconContOtherMenuEmploys">

                        </div>
                    </NavLink>
                    {/* <NavLink to={"/changeApi"}>
                        <p>API ключи</p>
                        <div className="iconContOtherMenuApi">

                        </div>
                    </NavLink> */}
                    {/* <NavLink to={"/ozon"}>
                        <p>OZON</p>
                        <div className="iconContOtherMenuOzon">

                        </div>
                    </NavLink> */}
                </div>
                <div className="row3">
                    {/* <NavLink to={"/wildberries"}>
                        <p>Wildberries</p>
                        <div className="iconContOtherMenuWB">

                        </div>
                    </NavLink>
                    <NavLink to={"/yandex"}>
                        <p>Яндекс Маркет</p>
                        <div className="iconContOtherMenuYandex">

                        </div>
                    </NavLink> */}
                    <NavLink className="empty">

                    </NavLink>
                </div>
            </div>
            {/* <div className="lastChild"></div> */}
        </div>
    )
}