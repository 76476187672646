import { useDispatch, useSelector } from "react-redux";
import MessageItems from "../messageItems/messageItems";
import "./chatBodyLight.css";
import "./chatBodyDark.css";
import { useEffect, useRef, useState } from "react";
import WhoCreatedTheConversation from "../Who created the conversation/WhoCreatedTheConversation";
import JoinTheGroup from "../joinTheGroup/joinTheGroupButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ReplyMessageItem from "../replyaMessageItem/replyaMessageItem";
import { getMessageItem } from "../../../../redux/reducer/getMessageItem/action/action";
import ChangeChatNameInfo from "../changeChatNameInfo/changeChatNameInfo";
import moment from 'moment';
import 'moment/locale/ru';


export default function ChatBody() {
    const containerRef = useRef(null);
    const getMessageItems = useSelector(messageItem => messageItem);
    const getDarkModoe = useSelector(darkModes => darkModes);
    const getChatInfo = useSelector(chatInfi => chatInfi.getChatInfoByIDReducer);
    const getMyInfo = useSelector(getMyInfo => getMyInfo.getMayInfoReducer);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const prevHrefRef = useRef(window.location.href);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [data, setData] = useState([]);
    const chatID = searchParams.get('chatID');
    const scrollToBottom = () => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };
    useEffect(() => {
        scrollToBottom();

    }, [])

    const scrollToItem = async (item, e) => {
        e.stopPropagation();
        const chatID = searchParams.get('chatID');
        navigate(`?chatID=${chatID}`)
        dispatch(getMessageItem(`/getItemMessages/api?chatID=${chatID}`));
        // Получаем элемент по его id
        const element = document.getElementById(`message-${item.messageReplyID._id}`);
        // Проверяем, что элемент существует
        if (element) {
            // Прокручиваем к элементу
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
            if (getDarkModoe.ToggleDarkModeReducer.chekced) {
                element.style.backgroundColor = "#33363B"
            } else {
                element.style.backgroundColor = "#F4F8FE"

            }
            setTimeout(() => {

                element.style.backgroundColor = "";
            }, 2000)
        }

    };

    const handleNavigate = async () => {

        const chat_filter = searchParams.get('chat_filter');
        if (chat_filter) {
            const chatID = searchParams.get('chatID');
            navigate(`?chatID=${chatID}`)
            dispatch(getMessageItem(`/getItemMessages/api?chatID=${chatID}`));
        }

    }
    // для того чтобы проверить скролл внизу или нет но это 100% не работает то есть нужно переделать
    // useEffect(() => {
    //         console.log(isAtBottom,"isAtBottomisAtBottomisAtBottom");
    //     const handleScroll = () => {
    //       const container = containerRef.current;
    //       if (container) {
    //         const { scrollTop, scrollHeight, clientHeight } = container;
    //         setIsAtBottom(scrollHeight - scrollTop === clientHeight);
    //       }
    //     };

    //     const container = containerRef.current;
    //     if (container) {
    //       container.addEventListener('scroll', handleScroll);
    //     }

    //     return () => {
    //       if (container) {
    //         container.removeEventListener('scroll', handleScroll);
    //       }
    //     };
    //   }, [isAtBottom]);   

    function convertDate(datas) {
        const currentDate = moment();
        const year = currentDate.year();
        const month = currentDate.month() + 1; // JavaScript months are zero-based
        const day = currentDate.date();
        const currentAllDate = `${year}-${month}-${day}`;
        const num = datas
    
        const chatYear = moment(num).year();
        const chatMonth = moment(num).month() + 1; // JavaScript months are zero-based
        const chatDay = moment(num).date();
        const chatAllDate = `${chatYear}-${chatMonth}-${chatDay}`;
    
        if (currentAllDate === chatAllDate) {
            return "Сегодня";
        } else if (currentAllDate < chatAllDate) {
            return moment(num).locale('ru').format('D MMMM YYYY г.');
        } else if (currentAllDate > chatAllDate) {
            return moment(num).locale('ru').format('D MMMM YYYY г.');
        }
    }

    const handleSaveScrollPosition = (chatID) => {
        const element = document.getElementById("chatBodyChildCont");
        let scrollTop = element.scrollTop;
        let chatIDS = searchParams.get('chatID')
        if (scrollTop && chatIDS) {
                if (localStorage.getItem("scrollPositions")) {
                     const getILocalStorageChatID = JSON.parse(localStorage.getItem("scrollPositions"));          
                     const findChatID = getILocalStorageChatID.find(item => item.chatID == chatIDS);
                       if (!findChatID) {
                            
                       }else{
                        findChatID.posItitonScroll = scrollTop; 
                        getILocalStorageChatID.push({chatID:chatIDS,posItitonScroll:scrollTop})
                         localStorage.setItem('scrollPositions', JSON.stringify(getILocalStorageChatID));
                       }
                   
                }else{
                        if (chatIDS && scrollTop) {
                            localStorage.setItem('scrollPositions', JSON.stringify([{chatID:chatIDS,posItitonScroll:scrollTop}]));
                        }
                    
                }
        }
    };

    useEffect(()=>{
        let chatIDS = searchParams.get('chatID')
        const getILocalStorageChatID = JSON.parse(localStorage.getItem("scrollPositions"));
            if (getILocalStorageChatID) {
                const findChatID = getILocalStorageChatID.find(item => item.chatID == chatIDS);
                const chatBodyChildCont = document.getElementById("chatBodyChildCont");
          
                  if (findChatID) {
                          console.log(findChatID.posItitonScroll);
                            chatBodyChildCont.scrollTop = findChatID.posItitonScroll; 
                          console.log("hi");
                  }else{
                      console.log("no");
                  }  
            }
 
    },[chatID,getMessageItems.userReducerMessageItem.data])
    
    
    
    
    return (
        <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "chatBodyDarkCont" : "chatBodyLightCont"} >

            <div ref={containerRef} id="chatBodyChildCont" onScrollCapture={handleSaveScrollPosition}>

                <WhoCreatedTheConversation />
                {
                    getMessageItems.userReducerMessageItem.data?.map((item, index) => {

                        if (item.typeMessage == "message") {
                            return (
                                <div key={index}>
                                   
                                    {

                                        item.gropuDate && <div className="datemessageCont">
                                            <div>
                                                <p>{convertDate(item.gropuDate)}</p>
                                            </div>
                                        </div>
                                    }

                                    <MessageItems item={item} handleNavigate={handleNavigate} index={index} child={false} />

                                </div>
                            )
                        } else if (item.typeMessage == "reply") {
                            return (
                                <div key={index}>

                                    {

                                        item.gropuDate && <div className="datemessageCont">
                                            <div>
                                                <p>{convertDate(item.gropuDate)}</p>
                                            </div>
                                        </div>
                                    }
                                    

                                    <ReplyMessageItem item={item} scrollToItem={scrollToItem} index={index} child={false} />
                                </div>
                            )
                        } else if (item.typeMessage == "changeChatName") {
                            return (
                                <div key={index}>
                                    <ChangeChatNameInfo item={item} scrollToItem={scrollToItem} index={index} child={false} />
                                </div>
                            )
                        }

                    })
                }


                {
                    getChatInfo.data && getChatInfo.data.chatOptions && getChatInfo.data.chatOptions.chatParticipantsID &&
                    getChatInfo.data.chatOptions.chatParticipantsID.find(myid => myid !== getMyInfo.myinfo._id && chatID) && 

                    <JoinTheGroup />
                }




            </div>

        </div>
    )
}