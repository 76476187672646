import React, { useState, useEffect, useRef } from "react";

import Modal from "react-bootstrap/Modal";
import { Input } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import {

  Textarea

} from "@chakra-ui/react";



import "./childModal.css"
import "./childModalDark.css"

import CommentBox from "../commentBox/commentBox";
import Status from "../status/status";
import Metka from "../metka/metka";
import Perfomer from "../perfomer/perfomer";
import UploadFileTaskMenegerChild from "../uploadFile/uploadFileChild";
import CommentBoxCreatTask from "../commentCreatTask/commentCreatTask";
import Prioritet from "../prioritet/prioritet";
import { useSelector } from "react-redux";
import StartDateModal from "../startDateModal/startDateModal";
import EndDateModal from "../endDateModal/endDateModal";
import UploadFileTaskMeneger from "../uploadFile/uploadFile";




function ChilMNodal({ item, allaray, nameCurrentSubTask, sendServer, setperfomerID, setchildPerfomer, handleClose, deleteItemByIndex, index, metkas, load }) {




  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const handleCloseChild = () => setShow(false);
  const handleShow = () => setShow(true);
  const [comments, setComments] = useState([]);
  const [templates, satTemplates] = useState("");
  const [taskName, satTtaskName] = useState("");
  const [templatesArray, satTemplatesArray] = useState([]);
  const currentDate = new Date();
  const nextDayDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);
  const adjustedDate = new Date(nextDayDate.getTime() - nextDayDate.getTimezoneOffset() * 60000);
  const formattedDate = adjustedDate.toISOString().slice(0, 16);
  const [startDate, setStartDate] = useState(formattedDate);  
  const [endDate, setEndDate] = useState(formattedDate);
  const [status, setStatus] = useState("Новая");
  const [prioritet, setPrioritet] = useState("Низший");
  const [metka, setMetka] = useState([]);
  const [performer, setperformer] = useState("");
  const [aboutTask, setAboutTask] = useState("");
  const [file, setfile] = useState([]);
  const [taskInfo, setTasktaskInfo] = useState("");
  const modalRef = useRef(null)
  const gerChekcedCompany = useSelector((allWorkers) => allWorkers);
  const getDarkModoe = useSelector(darkModes => darkModes);
  // получить элемент и установить состояние
  useEffect(() => {
    item.comments = comments
    item.performer = performer
    item.startDate = startDate
    item.endDate = endDate
    item.status = status
    item.prioritet = prioritet
    item.aboutTask = aboutTask
    item.file = file
    item.taskInfo = taskInfo
  
  }, [comments, performer, templates, templatesArray, startDate, endDate, status, prioritet, metka, taskName, aboutTask, file, taskInfo])

  // добавить исполнителя

  useEffect(() => {
    if (performer == "") {
       setperformer(allaray[0].performer)   
     
      
    }

      
  }, [allaray])

  const HandleSetPerfomerId = (e) => {
    setperfomerID([setperformer])
    setperformer(e)
    setchildPerfomer(performer)


  }
  // удалить исполнителя
  const HandleDeletePerfomerId = (e) => {

    setperformer("")
    setperfomerID([])
  }
  // отправить дочерний сервер
  const senderServerChild = () => {
    sendServer(allaray, metkas)


  }
  // изменить файл
  const handleChangeFile = (e) => {
    setfile(e)
  }

  useEffect(() => {
    modalRef.current.style.display = "none"
  }, [])

  const handleShowChildModal = () => {
    modalRef.current.style.display = "flex"
  }


  const handleCloseChildModal = (e) => {
    e.stopPropagation();
    modalRef.current.style.display = "none"

  }


  return (
    <div className="modalCreatTaskChild" onClick={handleShowChildModal} key={index}>
      <div>
        <p>{item.msg}</p>
        <div onClick={(e) => deleteItemByIndex(index, e)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path d="M7.6115 7.9974L5.34754 5.73344C5.1029 5.4888 5.1029 5.09217 5.34754 4.84754C5.59217 4.6029 5.9888 4.6029 6.23344 4.84754L8.4974 7.1115L10.7614 4.84754C11.006 4.6029 11.4026 4.6029 11.6473 4.84754C11.8919 5.09217 11.8919 5.4888 11.6473 5.73344L9.38329 7.9974L11.6473 10.2614C11.8919 10.506 11.8919 10.9026 11.6473 11.1473C11.4026 11.3919 11.006 11.3919 10.7614 11.1473L8.4974 8.88329L6.23344 11.1473C5.9888 11.3919 5.59217 11.3919 5.34754 11.1473C5.1029 10.9026 5.1029 10.506 5.34754 10.2614L7.6115 7.9974Z" fill="#1C2E45" fill-opacity="0.6" />
          </svg>
        </div>
      </div>

      <div class="modalChildModal" ref={modalRef} id="childModalCont">


        <div class="modal-contentChildModal">
          <div className={getDarkModoe.ToggleDarkModeReducer.chekced ? "CreatTaskDark" : "CreatTask"}>
            <div className="headerTask">

              <div>

                <Prioritet setPrioritet={setPrioritet} prioritet={prioritet} />
                <Status setStatus={setStatus} status={status} />
              </div>
              <div>
                {/* <Shablon sendMessage={sendMessage} currentTask={currentTask} perfomerID={perfomerID} receivedMessages={receivedMessages} handleDelet={handleDelet} delLoad={delLoad} update={update} handleGetNotify={handleGetNotify} closeParent={handleClose} /> */}
              </div>
            </div>
            <div className="choosePerformerDiv">
              <Perfomer HandleSetPerfomerId={HandleSetPerfomerId} HandleDeletePerfomerId={HandleDeletePerfomerId} bydefault={performer} />
            </div>
            <div className="sectionOtherTask">
              <div>
                <div>
                  <label htmlFor="taskNameInput">
                    <p>Выберите название задачи </p>
                    <input type="text"
                      id="taskNameInput"
                      placeholder="Введите название задачи"
                      className="nameTaskInput"
                      value={item.msg}
                      disabled
                      onChange={(e) => satTtaskName(e.target.value)}
                    />
                  </label>
                </div>
                <div>
                  <label htmlFor="priceCreatInput">
                    <p>Цена</p>
                    <input type="number"
                      id="priceCreatInput"
                      placeholder="Цена"
                      className="nameTaskInput"
                      value={0}
                    />
                  </label>
                </div>

                <div className="dateCreatDiv">
                  <div>
                    <div>
                      <div>
                        <p>Сроки: начало</p>
                        <StartDateModal startDate={startDate} setStartDate={setStartDate} />
                      </div>
                      <div>
                        <p>Сроки: конец</p>
                        <EndDateModal endDate={endDate} setEndDate={setEndDate} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <label>
                    <p>Добавьте описание задачи</p>
                    <textarea placeholder='Добавьте описание задачи' value={taskInfo} onChange={(e) => setTasktaskInfo(e.target.value)} />
                  </label>
                  <UploadFileTaskMenegerChild setfile={setfile} file={file} handleChangeFile={handleChangeFile} index={index} allaray={allaray} />
                </div>
              </div>
              <div>
                <CommentBoxCreatTask setComments={setComments} comments={comments} />

              </div>
            </div>

            <div className="creatTaskButton">

              <button colorScheme="blue" onClick={(e) => handleCloseChildModal(e)}>

                <p>Закрыть</p></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChilMNodal;
