import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from '@chakra-ui/react'
import {getOzonPorductList} from "../../.././../redux/reducer/ozonGetProductLIst/action/action.js"
import {getWaldberisListAction} from "../../.././../redux/reducer/getwildberrieslIST/action/action.js"
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import axios from "axios";

import OzoModalEdit from "../../ozoEdit/ozonModalEdit/ozomodalEdit.jsx"
import EditModalwb from "../../wildberries/wbeditmodal/wbeditmodal.jsx";

import { 
    Input,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box

} from '@chakra-ui/react'
import ModalForView from "../openModalForview/modalForView.jsx";

export default function SelectMarketPlaceEdit({setBw,setOzon,wbs,ozons,show}) {
        
        const dispatch = useDispatch()
        const ozon = useRef(null)
        const wb = useRef(null)
        const [ozonLists,setOzonLists] = useState([])
        const [wbLists,setWbLists] = useState([])
        const [selectedOzon,setSelectedOzon] = useState([])
        const [selectedWb,setselectedWb] = useState([])
        const [load,setLoad] = useState(false)
        const [isMarketPlace,setisMarketPlace]=useState("")
        const getDarkModoe = useSelector(darkModes => darkModes )
  const showModal = useRef(null)


  useEffect(()=>{
    wb.current.style.display = "none";
    ozon.current.style.display = "none";
    showModal.current.style.display = "none"
   

  },[])

  // useEffect(()=>{
  //   setWbLists([])
  //   setOzonLists([])
  //   axios({
  //       method: 'get',
  //       url: '/ozon/getozonfotTask/api',
       
  //     })
  //       .then(function (response) {
  //               if (response.data.ozonList) { 
  //                   setOzonLists(response.data.ozonList.allProduct[0])
  //               }         
  //       });
  //       axios({
  //           method: 'get',
  //           url: '/wb/getozonfotTask/api',
  //         })
  //           .then(function (response) {  
  //                 setWbLists(response.data.wbList.product)
  //           });
  // },[show])
  
  useEffect(()=>{
    setselectedWb(wbs)
    setSelectedOzon(ozons)
  },[selectedOzon,selectedWb])
  
  const handleSelect =(e)=>{
    setLoad(true)
        if (e == "Ozon") {
          setisMarketPlace("Ozon")
            wb.current.style.display = "none";
            ozon.current.style.display = "block";
            wb.current.style.heigth = "0"
            setWbLists([])
            axios({
                method: 'get',
                url: '/ozon/getozonfotTask/api',
               
              })
                .then(function (response) {
                   
                        if (response.data.ozonList) {
                          
                            setOzonLists(response.data.ozonList.allProduct[0])
                            setLoad(false)
                        }
                       
                    

                            
                });
          
        }else if (e == "Wildberries") {
          setisMarketPlace("Wildberries")
            wb.current.style.display = "block";
            ozon.current.style.display = "none";
            ozon.current.style.heigth = "0"
            setOzonLists([])
            axios({
                method: 'get',
                url: '/wb/getozonfotTask/api',
               
              })
                .then(function (response) {
                   
                    setWbLists(response.data.wbList.product)
                    setLoad(false)
                });
           
        }else{
          setisMarketPlace("")
        }
  }

  const handleSearchOzon=(e)=>{
    if (e == "") {
        handleSelect("Ozon")
    }

    const regex = new RegExp(e, "i"); 
    const filterozon = ozonLists.filter(item => regex.test(item.price) ||  regex.test(item.catigoryName) || regex.test(item.productName) || regex.test(item.arcticule));
    setOzonLists(filterozon)
       
 
  }


  const handleSearchWB=(e)=>{
    if (e == "") {
        handleSelect("Wildberries")
    }

    const regex = new RegExp(e, "i"); 
    const filterwb = wbLists.filter(item => regex.test(item.alllistss.brand) ||  regex.test(item.alllistss.object) || regex.test(item.alllistss.vendorCode) ||
     Number(regex.test(item.price.price)) || Number(regex.test(item.price.nmId)));

        
    setWbLists(filterwb)

  }


  const deleteOzonSelect=(e)=>{
       
      const deleteOz = selectedOzon.filter(oz => oz.arcticule != e.arcticule)
      
        setSelectedOzon(deleteOz)
        setOzon(deleteOz)
  }


const deletWb=(e)=>{
             
     const deletwb = selectedWb.filter(wb => wb.alllistss.imtID !==  e.alllistss.imtID)
    console.log(deletwb);
    setselectedWb(deletwb)
    setBw(deletwb)
}
// add parent
const handleaddOzon = (item)=>{
  
  
    let findedSelected =   selectedOzon.find(elem => elem.arcticule == item.arcticule )
        if (!findedSelected) {
              setSelectedOzon(selectedOzon=>[...selectedOzon,item])
        
    setOzon(selectedOzon=>[...selectedOzon,item])
        }
   
}


const handleWb= (item) =>{

 let findedSelected =   selectedWb.find(elem => elem.price.nmId == item.price.nmId )
        if (!findedSelected) {
            setselectedWb(selectedWb=>[...selectedWb,item])
             setBw(selectedWb=>[...selectedWb,item])
        }
       
        
}

const handleShowModal=()=>{
  showModal.current.style.display = "flex"
}

const handleCloseModal=()=>{
  showModal.current.style.display = "none"
}

  return(
    <div  className={getDarkModoe.ToggleDarkModeReducer.chekced?"SelectMarketplaceDark":"SelectMarketplace"}>
      <p className="selecProdP">Выбрать товар</p>
        <Accordion defaultIndex={[1]} allowMultiple>
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box as="span" flex='1' textAlign='left'>
        Поиск товаров
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
         
            <button id="myBtn" onClick={handleShowModal} className="selectMarketplaceBTn">Выбрать маркетплейс</button>


<div id="myModal" className="modalMarketPlace" ref={showModal}>


  <div className="modal-contentMarketPlace">
    <div className="modal-headerSelectMarketPlace">
      <span className="close" onClick={handleCloseModal}>&times;</span>
     
    </div>
    <div className="modal-body">
    <div className="selectAndSearchMarketplace">
             <div>
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
                <path d="M17.188 14.4654H16.1491L15.7809 14.1103C17.0696 12.6112 17.8455 10.665 17.8455 8.54774C17.8455 3.82676 14.0187 0 9.29774 0C4.57676 0 0.75 3.82676 0.75 8.54774C0.75 13.2687 4.57676 17.0955 9.29774 17.0955C11.415 17.0955 13.3612 16.3196 14.8603 15.0309L15.2154 15.3991V16.438L21.7906 23L23.75 21.0406L17.188 14.4654ZM9.29774 14.4654C6.0233 14.4654 3.38007 11.8222 3.38007 8.54774C3.38007 5.2733 6.0233 2.63007 9.29774 2.63007C12.5722 2.63007 15.2154 5.2733 15.2154 8.54774C15.2154 11.8222 12.5722 14.4654 9.29774 14.4654Z" fill="#C3CAD9"/>
              </svg>
                  {
                    (()=>{
                        if (isMarketPlace == "Ozon") {
                              return <input type="text" placeholder="Поиск" onChange={(e)=> handleSearchOzon(e.target.value)}/>
                        }else if (isMarketPlace == "Wildberries") {
                          return  <input type="text" placeholder="Поиск" onChange={(e)=> handleSearchWB(e.target.value)} />
                    }else if (isMarketPlace == "") {
                      return <input type="text" placeholder="Поиск" />
                }
                    })()
                  }
                    
                  </div>
                  <select onChange={(e)=> handleSelect(e.target.value)}>
                      <option value="">Выберите маркетплейс</option>
                      <option value='Wildberries'>Wildberries</option>
                      <option value='Ozon'>Ozon</option>
                    
                  </select>
                 
             </div>
            <div className="MarketPlaceCont">
                     <div  ref={ozon} className="MarketPlaceContList">
                     
                        {ozonLists?.map((item,index)=>{
                            let findedSelected =   selectedOzon.find(elem => elem.arcticule == item.arcticule )
                                if (item.images.length > 0) {
                                 
                                        if (findedSelected) {
                                          return (
                                            <div key={index}  onClick={()=> handleaddOzon(item)}>
                                               <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
  <path d="M13.5 17.8538L19.3388 12.015L17.8875 10.5975L13.5 14.985L9.1125 10.5975L7.66125 12.015L13.5 17.8538ZM13.5 27C11.655 27 9.91125 26.6456 8.26875 25.9369C6.62625 25.2281 5.19187 24.2606 3.96563 23.0344C2.73938 21.8081 1.77188 20.3738 1.06313 18.7313C0.354375 17.0888 0 15.345 0 13.5C0 11.6325 0.354375 9.8775 1.06313 8.235C1.77188 6.5925 2.73938 5.16375 3.96563 3.94875C5.19187 2.73375 6.62625 1.77188 8.26875 1.06313C9.91125 0.354375 11.655 0 13.5 0C15.3675 0 17.1225 0.354375 18.765 1.06313C20.4075 1.77188 21.8363 2.73375 23.0513 3.94875C24.2663 5.16375 25.2281 6.5925 25.9369 8.235C26.6456 9.8775 27 11.6325 27 13.5C27 15.345 26.6456 17.0888 25.9369 18.7313C25.2281 20.3738 24.2663 21.8081 23.0513 23.0344C21.8363 24.2606 20.4075 25.2281 18.765 25.9369C17.1225 26.6456 15.3675 27 13.5 27ZM13.5 24.975C16.695 24.975 19.4062 23.8556 21.6337 21.6169C23.8612 19.3781 24.975 16.6725 24.975 13.5C24.975 10.305 23.8612 7.59375 21.6337 5.36625C19.4062 3.13875 16.695 2.025 13.5 2.025C10.3275 2.025 7.62188 3.13875 5.38312 5.36625C3.14437 7.59375 2.025 10.305 2.025 13.5C2.025 16.6725 3.14437 19.3781 5.38312 21.6169C7.62188 23.8556 10.3275 24.975 13.5 24.975Z" fill="#3B79F6"/>
</svg>
                                                <p>{item.productName}</p>
                                                <p >{item.arcticule}</p>
                                                <Avatar name='Dan Abrahmov' src={item.images[0].file_name} />
                                                {/* <OzoModalEdit item={item}/> */}
                                               
                                            </div>
                                        )
                                        }else{
                                          return (
                                            <div key={index}  onClick={()=> handleaddOzon(item)}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
  <path d="M13.5 27C11.655 27 9.91125 26.6456 8.26875 25.9369C6.62625 25.2281 5.19187 24.2606 3.96563 23.0344C2.73938 21.8081 1.77188 20.3738 1.06313 18.7313C0.354375 17.0888 0 15.345 0 13.5C0 11.6325 0.354375 9.8775 1.06313 8.235C1.77188 6.5925 2.73938 5.16375 3.96563 3.94875C5.19187 2.73375 6.62625 1.77188 8.26875 1.06313C9.91125 0.354375 11.655 0 13.5 0C15.3675 0 17.1225 0.354375 18.765 1.06313C20.4075 1.77188 21.8363 2.73375 23.0513 3.94875C24.2663 5.16375 25.2281 6.5925 25.9369 8.235C26.6456 9.8775 27 11.6325 27 13.5C27 15.345 26.6456 17.0888 25.9369 18.7313C25.2281 20.3738 24.2663 21.8081 23.0513 23.0344C21.8363 24.2606 20.4075 25.2281 18.765 25.9369C17.1225 26.6456 15.3675 27 13.5 27ZM13.5 24.975C16.695 24.975 19.4062 23.8556 21.6338 21.6169C23.8613 19.3781 24.975 16.6725 24.975 13.5C24.975 10.305 23.8613 7.59375 21.6338 5.36625C19.4062 3.13875 16.695 2.025 13.5 2.025C10.3275 2.025 7.62188 3.13875 5.38313 5.36625C3.14438 7.59375 2.025 10.305 2.025 13.5C2.025 16.6725 3.14438 19.3781 5.38313 21.6169C7.62188 23.8556 10.3275 24.975 13.5 24.975Z" fill="#DADEE6"/>
</svg>
                                                <p>{item.productName}</p>
                                                <p >{item.arcticule}</p>
                                                <Avatar name='Dan Abrahmov' src={item.images[0].file_name} />
                                                {/* <OzoModalEdit item={item}/> */}
                                        
                                            </div>
                                        )
                                        }
                                   
                                }
                                return (
                                    <div key={index}  onClick={()=> handleaddOzon(item)}>
                                       {
                                          findedSelected?
                                          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                          <path d="M13.5 17.8538L19.3388 12.015L17.8875 10.5975L13.5 14.985L9.1125 10.5975L7.66125 12.015L13.5 17.8538ZM13.5 27C11.655 27 9.91125 26.6456 8.26875 25.9369C6.62625 25.2281 5.19187 24.2606 3.96563 23.0344C2.73938 21.8081 1.77188 20.3738 1.06313 18.7313C0.354375 17.0888 0 15.345 0 13.5C0 11.6325 0.354375 9.8775 1.06313 8.235C1.77188 6.5925 2.73938 5.16375 3.96563 3.94875C5.19187 2.73375 6.62625 1.77188 8.26875 1.06313C9.91125 0.354375 11.655 0 13.5 0C15.3675 0 17.1225 0.354375 18.765 1.06313C20.4075 1.77188 21.8363 2.73375 23.0513 3.94875C24.2663 5.16375 25.2281 6.5925 25.9369 8.235C26.6456 9.8775 27 11.6325 27 13.5C27 15.345 26.6456 17.0888 25.9369 18.7313C25.2281 20.3738 24.2663 21.8081 23.0513 23.0344C21.8363 24.2606 20.4075 25.2281 18.765 25.9369C17.1225 26.6456 15.3675 27 13.5 27ZM13.5 24.975C16.695 24.975 19.4062 23.8556 21.6337 21.6169C23.8612 19.3781 24.975 16.6725 24.975 13.5C24.975 10.305 23.8612 7.59375 21.6337 5.36625C19.4062 3.13875 16.695 2.025 13.5 2.025C10.3275 2.025 7.62188 3.13875 5.38312 5.36625C3.14437 7.59375 2.025 10.305 2.025 13.5C2.025 16.6725 3.14437 19.3781 5.38312 21.6169C7.62188 23.8556 10.3275 24.975 13.5 24.975Z" fill="#3B79F6"/>
                                        </svg>
                                          :
                                          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                          <path d="M13.5 27C11.655 27 9.91125 26.6456 8.26875 25.9369C6.62625 25.2281 5.19187 24.2606 3.96563 23.0344C2.73938 21.8081 1.77188 20.3738 1.06313 18.7313C0.354375 17.0888 0 15.345 0 13.5C0 11.6325 0.354375 9.8775 1.06313 8.235C1.77188 6.5925 2.73938 5.16375 3.96563 3.94875C5.19187 2.73375 6.62625 1.77188 8.26875 1.06313C9.91125 0.354375 11.655 0 13.5 0C15.3675 0 17.1225 0.354375 18.765 1.06313C20.4075 1.77188 21.8363 2.73375 23.0513 3.94875C24.2663 5.16375 25.2281 6.5925 25.9369 8.235C26.6456 9.8775 27 11.6325 27 13.5C27 15.345 26.6456 17.0888 25.9369 18.7313C25.2281 20.3738 24.2663 21.8081 23.0513 23.0344C21.8363 24.2606 20.4075 25.2281 18.765 25.9369C17.1225 26.6456 15.3675 27 13.5 27ZM13.5 24.975C16.695 24.975 19.4062 23.8556 21.6338 21.6169C23.8613 19.3781 24.975 16.6725 24.975 13.5C24.975 10.305 23.8613 7.59375 21.6338 5.36625C19.4062 3.13875 16.695 2.025 13.5 2.025C10.3275 2.025 7.62188 3.13875 5.38313 5.36625C3.14438 7.59375 2.025 10.305 2.025 13.5C2.025 16.6725 3.14438 19.3781 5.38313 21.6169C7.62188 23.8556 10.3275 24.975 13.5 24.975Z" fill="#DADEE6"/>
                                        </svg>
                                        }
                                        <p>{item.productName}</p>
                                        <p >{item.arcticule}</p>

                                      
                                        <Avatar name='Dan Abrahmov' src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png' />
                                        {/* <OzoModalEdit item={item}/> */}
                                       
                                    </div>
                                )
                        })}
                        
                     </div>
                    <div  ref={wb} className="MarketPlaceContList">
                   
                        {wbLists.map((item,index)=>{
                           
                        let x=     selectedWb.find(nm => nm.price.nmId == item.price.nmId,"asd")
                     
                                if (x) {
                                  return (
                                    <div key={index} onClick={()=> handleWb(item)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
  <path d="M13.5 17.8538L19.3388 12.015L17.8875 10.5975L13.5 14.985L9.1125 10.5975L7.66125 12.015L13.5 17.8538ZM13.5 27C11.655 27 9.91125 26.6456 8.26875 25.9369C6.62625 25.2281 5.19187 24.2606 3.96563 23.0344C2.73938 21.8081 1.77188 20.3738 1.06313 18.7313C0.354375 17.0888 0 15.345 0 13.5C0 11.6325 0.354375 9.8775 1.06313 8.235C1.77188 6.5925 2.73938 5.16375 3.96563 3.94875C5.19187 2.73375 6.62625 1.77188 8.26875 1.06313C9.91125 0.354375 11.655 0 13.5 0C15.3675 0 17.1225 0.354375 18.765 1.06313C20.4075 1.77188 21.8363 2.73375 23.0513 3.94875C24.2663 5.16375 25.2281 6.5925 25.9369 8.235C26.6456 9.8775 27 11.6325 27 13.5C27 15.345 26.6456 17.0888 25.9369 18.7313C25.2281 20.3738 24.2663 21.8081 23.0513 23.0344C21.8363 24.2606 20.4075 25.2281 18.765 25.9369C17.1225 26.6456 15.3675 27 13.5 27ZM13.5 24.975C16.695 24.975 19.4062 23.8556 21.6337 21.6169C23.8612 19.3781 24.975 16.6725 24.975 13.5C24.975 10.305 23.8612 7.59375 21.6337 5.36625C19.4062 3.13875 16.695 2.025 13.5 2.025C10.3275 2.025 7.62188 3.13875 5.38312 5.36625C3.14437 7.59375 2.025 10.305 2.025 13.5C2.025 16.6725 3.14437 19.3781 5.38312 21.6169C7.62188 23.8556 10.3275 24.975 13.5 24.975Z" fill="#3B79F6"/>
</svg>
                                        <p>{item.alllistss.object}</p>
                                        <p>{item.alllistss.nmID}</p>
                                       
                                        <Avatar name='Dan Abrahmov' src={item.alllistss.mediaFiles[0]} />
                                            {/* <EditModalwb  vendorCodes={item.alllistss.vendorCode} price={item.price.price} nmID={item.alllistss.nmID} /> */}
                                    </div>
                                )
                                }else{
                                  return (
                                    <div key={index} onClick={()=> handleWb(item)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
      <path d="M13.5 27C11.655 27 9.91125 26.6456 8.26875 25.9369C6.62625 25.2281 5.19187 24.2606 3.96563 23.0344C2.73938 21.8081 1.77188 20.3738 1.06313 18.7313C0.354375 17.0888 0 15.345 0 13.5C0 11.6325 0.354375 9.8775 1.06313 8.235C1.77188 6.5925 2.73938 5.16375 3.96563 3.94875C5.19187 2.73375 6.62625 1.77188 8.26875 1.06313C9.91125 0.354375 11.655 0 13.5 0C15.3675 0 17.1225 0.354375 18.765 1.06313C20.4075 1.77188 21.8363 2.73375 23.0513 3.94875C24.2663 5.16375 25.2281 6.5925 25.9369 8.235C26.6456 9.8775 27 11.6325 27 13.5C27 15.345 26.6456 17.0888 25.9369 18.7313C25.2281 20.3738 24.2663 21.8081 23.0513 23.0344C21.8363 24.2606 20.4075 25.2281 18.765 25.9369C17.1225 26.6456 15.3675 27 13.5 27ZM13.5 24.975C16.695 24.975 19.4062 23.8556 21.6338 21.6169C23.8613 19.3781 24.975 16.6725 24.975 13.5C24.975 10.305 23.8613 7.59375 21.6338 5.36625C19.4062 3.13875 16.695 2.025 13.5 2.025C10.3275 2.025 7.62188 3.13875 5.38313 5.36625C3.14438 7.59375 2.025 10.305 2.025 13.5C2.025 16.6725 3.14438 19.3781 5.38313 21.6169C7.62188 23.8556 10.3275 24.975 13.5 24.975Z" fill="#DADEE6"/>
    </svg>                            
                                        <p>{item.alllistss.object}</p>
                                        <p>{item.alllistss.nmID}</p>
                                       
                                        <Avatar name='Dan Abrahmov' src={item.alllistss.mediaFiles[0]} />
                                        
                                            {/* <EditModalwb  vendorCodes={item.alllistss.vendorCode} price={item.price.price} nmID={item.alllistss.nmID} /> */}
                                    </div>
                                )
                                }
                           
                        })}
                    </div>
                    
            </div>
    </div>
    
  </div>

</div>
<div className="CurrentAllItem">
     
     {selectedOzon?.map((item,index)=>{
          
                             if (item.images.length > 0) {
                                
                                 return (
                                     <div key={index}  >
                                       <img name='Dan Abrahmov' src={item.images[0].file_name} />
                                        
                                         <ModalForView item={item} name={"ozon"}>
                                         <p>{item.productName}</p>
                                         <p className="arcticule">{item.arcticule}</p>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <g clip-path="url(#clip0_1665_3923)">
    <path d="M22 0H3C1.34315 0 0 1.34315 0 3V22C0 23.6569 1.34315 25 3 25H22C23.6569 25 25 23.6569 25 22V3C25 1.34315 23.6569 0 22 0Z" fill="#005CFF"/>
    <path d="M12.5631 10.3636C12.5631 11.1733 12.4057 11.8563 12.0908 12.4126C11.776 12.9666 11.351 13.3868 10.816 13.6733C10.2809 13.9574 9.68433 14.0994 9.02619 14.0994C8.36331 14.0994 7.76435 13.9562 7.22932 13.6697C6.69665 13.3809 6.27288 12.9595 5.95801 12.4055C5.64551 11.8492 5.48926 11.1686 5.48926 10.3636C5.48926 9.55395 5.64551 8.87213 5.95801 8.31815C6.27288 7.76181 6.69665 7.34159 7.22932 7.0575C7.76435 6.77104 8.36331 6.62781 9.02619 6.62781C9.68433 6.62781 10.2809 6.77104 10.816 7.0575C11.351 7.34159 11.776 7.76181 12.0908 8.31815C12.4057 8.87213 12.5631 9.55395 12.5631 10.3636ZM10.5319 10.3636C10.5319 9.928 10.4739 9.56105 10.3579 9.26275C10.2442 8.96209 10.075 8.73482 9.85005 8.58094C9.62752 8.42469 9.3529 8.34656 9.02619 8.34656C8.69949 8.34656 8.42368 8.42469 8.19878 8.58094C7.97624 8.73482 7.80697 8.96209 7.69096 9.26275C7.57733 9.56105 7.52051 9.928 7.52051 10.3636C7.52051 10.7992 7.57733 11.1674 7.69096 11.468C7.80697 11.7663 7.97624 11.9936 8.19878 12.1499C8.42368 12.3037 8.69949 12.3807 9.02619 12.3807C9.3529 12.3807 9.62752 12.3037 9.85005 12.1499C10.075 11.9936 10.2442 11.7663 10.3579 11.468C10.4739 11.1674 10.5319 10.7992 10.5319 10.3636ZM13.5051 14V12.9062L16.9426 8.31815H13.5193V6.72724H19.4568V7.82099L16.0193 12.4091H19.4426V14H13.5051Z" fill="white"/>
    <path d="M22.619 17.8574H2.38086V19.0479H22.619V17.8574Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_1665_3923">
      <rect width="25" height="25" fill="white"/>
    </clipPath>
  </defs>
                                        </svg>   
                                         </ModalForView>
                                        
                                         <OzoModalEdit item={item}/>
                                         <span class="material-symbols-outlined deleteCard" onClick={()=>deleteOzonSelect(item)}>delete</span>


                                     </div>
                                 )
                             }
                             return (
                                 <div key={index} >
                                  
                                      <ModalForView item={item} name={"ozon"}>
                                      <img name='Dan Abrahmov' src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png' />
                                         <p style={{width:"161.865px"}}>{item.productName}</p>
                                         <p className="arcticule"  style={{width:"161.865px"}}>{item.arcticule}</p>
                                        
                                         </ModalForView>
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <g clip-path="url(#clip0_1665_3923)">
                      <path d="M22 0H3C1.34315 0 0 1.34315 0 3V22C0 23.6569 1.34315 25 3 25H22C23.6569 25 25 23.6569 25 22V3C25 1.34315 23.6569 0 22 0Z" fill="#005CFF"/>
                      <path d="M12.5631 10.3636C12.5631 11.1733 12.4057 11.8563 12.0908 12.4126C11.776 12.9666 11.351 13.3868 10.816 13.6733C10.2809 13.9574 9.68433 14.0994 9.02619 14.0994C8.36331 14.0994 7.76435 13.9562 7.22932 13.6697C6.69665 13.3809 6.27288 12.9595 5.95801 12.4055C5.64551 11.8492 5.48926 11.1686 5.48926 10.3636C5.48926 9.55395 5.64551 8.87213 5.95801 8.31815C6.27288 7.76181 6.69665 7.34159 7.22932 7.0575C7.76435 6.77104 8.36331 6.62781 9.02619 6.62781C9.68433 6.62781 10.2809 6.77104 10.816 7.0575C11.351 7.34159 11.776 7.76181 12.0908 8.31815C12.4057 8.87213 12.5631 9.55395 12.5631 10.3636ZM10.5319 10.3636C10.5319 9.928 10.4739 9.56105 10.3579 9.26275C10.2442 8.96209 10.075 8.73482 9.85005 8.58094C9.62752 8.42469 9.3529 8.34656 9.02619 8.34656C8.69949 8.34656 8.42368 8.42469 8.19878 8.58094C7.97624 8.73482 7.80697 8.96209 7.69096 9.26275C7.57733 9.56105 7.52051 9.928 7.52051 10.3636C7.52051 10.7992 7.57733 11.1674 7.69096 11.468C7.80697 11.7663 7.97624 11.9936 8.19878 12.1499C8.42368 12.3037 8.69949 12.3807 9.02619 12.3807C9.3529 12.3807 9.62752 12.3037 9.85005 12.1499C10.075 11.9936 10.2442 11.7663 10.3579 11.468C10.4739 11.1674 10.5319 10.7992 10.5319 10.3636ZM13.5051 14V12.9062L16.9426 8.31815H13.5193V6.72724H19.4568V7.82099L16.0193 12.4091H19.4426V14H13.5051Z" fill="white"/>
                      <path d="M22.619 17.8574H2.38086V19.0479H22.619V17.8574Z" fill="white"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1665_3923">
                        <rect width="25" height="25" fill="white"/>
                      </clipPath>
  </defs>
                                                         </svg>   
                                     
                                     <OzoModalEdit item={item}/>
                                     <span class="material-symbols-outlined deleteCard" onClick={()=>deleteOzonSelect(item)}>delete</span>
                                 </div>
                             )
                     })}  


{selectedWb.map((item,index)=>{
                 
                 
                   return (
                       <div key={index} >
                          <img name='Dan Abrahmov' src={item.alllistss.mediaFiles[0]} />
                       
                             <ModalForView item={item} name={"walberis"}> 
                             <p>{item.alllistss.object}</p>
                             <p className="arcticule">{item.alllistss.nmID}</p>
                             <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <g clip-path="url(#clip0_1665_3927)">
    <path d="M22 0H3C1.34315 0 0 1.34315 0 3V22C0 23.6569 1.34315 25 3 25H22C23.6569 25 25 23.6569 25 22V3C25 1.34315 23.6569 0 22 0Z" fill="#8A27D8"/>
    <path d="M6.00719 14L3.8623 6.72729H6.06401L7.05832 11.2017H7.11514L8.29412 6.72729H10.027L11.206 11.2159H11.2628L12.2571 6.72729H14.4589L12.314 14H10.4248L9.18901 9.93752H9.13219L7.89639 14H6.00719ZM15.0981 14V6.72729H18.2515C18.8054 6.72729 19.2706 6.80187 19.6471 6.95101C20.0258 7.10017 20.3111 7.31086 20.5029 7.58312C20.697 7.85537 20.7941 8.17616 20.7941 8.54548C20.7941 8.81299 20.7349 9.05684 20.6165 9.27701C20.5005 9.49718 20.3372 9.68184 20.1265 9.83099C19.9158 9.97777 19.6696 10.0795 19.3878 10.1364V10.2074C19.7003 10.2192 19.9844 10.2985 20.2401 10.4453C20.4958 10.5897 20.6994 10.7898 20.8509 11.0455C21.0024 11.2988 21.0782 11.5971 21.0782 11.9403C21.0782 12.3381 20.974 12.692 20.7657 13.0021C20.5597 13.3123 20.2661 13.5561 19.885 13.7337C19.5038 13.9112 19.0493 14 18.5214 14H15.0981ZM17.0725 12.4233H17.9958C18.3272 12.4233 18.5758 12.3617 18.7415 12.2386C18.9072 12.1132 18.9901 11.9285 18.9901 11.6847C18.9901 11.5142 18.951 11.3698 18.8729 11.2514C18.7948 11.133 18.6835 11.0431 18.5391 10.9815C18.3971 10.92 18.2254 10.8892 18.0242 10.8892H17.0725V12.4233ZM17.0725 9.66763H17.8821C18.055 9.66763 18.2077 9.64042 18.3402 9.58597C18.4728 9.53152 18.5758 9.45338 18.6492 9.35158C18.725 9.24742 18.7628 9.12076 18.7628 8.97161C18.7628 8.74671 18.6823 8.57508 18.5214 8.45671C18.3604 8.33597 18.1568 8.27559 17.9106 8.27559H17.0725V9.66763Z" fill="white"/>
    <path d="M22.619 17.8574H2.38086V19.0479H22.619V17.8574Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_1665_3927">
      <rect width="25" height="25" fill="white"/>
    </clipPath>
  </defs>
                          </svg>
                       
                             </ModalForView>
                          
                               <EditModalwb  vendorCodes={item.alllistss.vendorCode} price={item.price.price} nmID={item.alllistss.nmID} />
                               <span class="material-symbols-outlined deleteCard" onClick={()=> deletWb(item)}>delete</span>
                       </div>
                   )
               })}
     </div>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
{/* addd=================================================================================== */}
       
    </div>
  )
}