import React, { useState, useEffect, useRef } from 'react';

import "./editPrioritySelectLight.css"
import "./editPrioritySelectDark.css"
import axios from 'axios';
import { useToast } from '@chakra-ui/react'
import { useSelector } from 'react-redux';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
} from '@chakra-ui/react'
import sockets from '../../../../../socket/socket';
const socket = sockets;
const EditPrioritySelect = ({ isChild, prioritetGet, allitemForStatus, index, update }) => {

    const [selected, setSelected] = useState(prioritetGet);
    const toast = useToast()
    const [isActive, setIsActive] = useState(false);
    let getmyinfo = useSelector(compID => compID.getMayInfoReducer);
    const getDarkModoe = useSelector(darkModes => darkModes);

    const [options] = useState([
        "Критический",
        "Важно",
        "Срочно",
        "Низший",
    ]);
    const [position, setPosition] = useState();
    const selectRef = useRef(null);

    useEffect(() => {

    }, []);
    const handleSelectClick = (event) => {
        if (getmyinfo.myinfo._id === allitemForStatus.creatorTasks) {
            const selectRect = selectRef.current.getBoundingClientRect();
            console.log(selectRect);
            setPosition({
                top: selectRect.y - 65,
                width: selectRect.width

            });
            setIsActive(!isActive);
        }

    };

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);
    const handleGetNotify = () => {
        socket.emit('getNotifyEmit');
    }
    async function sendInfoNotify(allitemForStatus, isChild, whatIsNotofy, index, currentTask) {
        console.log(currentTask);

        axios.post('/sendInfoNotify/api', {
            allitemForStatus,
            isChild,
            whatIsNotofy,
            index,
            currentTask
        })
            .then(function (response) {
                console.log(response);
                handleGetNotify();
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    const handleUpdateStatus = (e) => {
        if (isChild) {

            allitemForStatus.alltask[0].child[index].prioritet = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    console.log(response.data.message);
                    sendInfoNotify(allitemForStatus, isChild, "prioritet", index,allitemForStatus.alltask[0].child[index])
                    update()
                    toast({
                        position: "top",
                        title: "Приоритет обновлен",
                        description: "Приоритет обновлен",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setIsActive(false);
                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        } else {
            allitemForStatus.alltask[0].prioritet = e
            axios.post('/updateMyTaskForStatusAndPriritet/api', {
                allitemForStatus
            })
                .then(function (response) {
                    console.log(response);
                    sendInfoNotify(allitemForStatus, isChild, "prioritet",index,allitemForStatus.alltask[0])
                    update()
                    toast({
                        position: "top",
                        title: "Приоритет обновлен",
                        description: "Приоритет обновлен",
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setIsActive(false);
                })
                .catch(function (error) {
                    console.log(error);
                    update()
                });

        }

    }
    useEffect(() => {
        if (prioritetGet == "Критический") {
            setSelected("Критический")
        } else if (prioritetGet == "Важно") {
            setSelected("Важно")
        } else if (prioritetGet == "Срочно") {
            setSelected("Срочно")
        } else if (prioritetGet == "Низший") {
            setSelected("Низший")
        }
    }, [prioritetGet, selected])


    return (
        <div ref={selectRef} className={getDarkModoe.ToggleDarkModeReducer.chekced ? "editPriorityBySelectContDark" : "editPriorityBySelectContLight"}>
            {
                getmyinfo.myinfo._id === allitemForStatus.creatorTasks ?
                    <Popover>
                        <PopoverTrigger>
                            <button onClick={handleSelectClick}>
                                <div className='Frame625852'>
                                    <div className='Frame625851'>
                                        <p>{selected}</p>
                                    </div>
                                </div>

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                                </svg>
                            </button>
                        </PopoverTrigger>
                        <PopoverContent padding={0} borderColor={getDarkModoe.ToggleDarkModeReducer.chekced ? "#2C3037" : "white"}>

                            <PopoverBody padding={0}  >
                                <div
                                    className="select-itemsPriority"

                                >
                                    {options.map((option, index) => (
                                        <div key={index} onClick={() => handleUpdateStatus(option)} className='Component56'>
                                            <div className='Frame625852'>
                                                <div className='Frame625851'>
                                                    <p>{option}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </PopoverBody>

                        </PopoverContent>
                    </Popover>
                    :
                    <button >
                        <div className='Frame625852'>
                            <div className='Frame625851'>
                                <p>{selected}</p>
                            </div>
                        </div>

                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M7.50511 9.33702C7.13401 9.00104 6.5634 9.0324 6.23061 9.40706C5.89783 9.78173 5.92889 10.3578 6.29999 10.6938L11.2566 15.2859C11.5994 15.5963 12.1188 15.5963 12.4617 15.2859L17.5334 10.5896C17.9045 10.2536 17.9355 9.67754 17.6027 9.30287C17.27 8.9282 16.6993 8.89684 16.3282 9.23283L11.8591 13.3835L7.50511 9.33702Z" fill="#353535" />
                        </svg>
                    </button>
            }

        </div>
    );
};

export default EditPrioritySelect;
