import React, { useRef, useState,useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import { Input } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";

import {
  useDisclosure,
  Textarea

} from "@chakra-ui/react";


import { useToast } from '@chakra-ui/react'

import CommentBox from "../commentBox/commentBox";
import Status from "../status/status";

import Metka from "../metka/metka";
import UploadFileTaskMenegerEdit from "../uploadFile/uploadFileEdit";
import PerfomerEdit from "../perfomerEdit/perfomerEdit";
import { useSelector } from "react-redux";
import axios from "axios";
import CommentBoxCreatTask from "../commentCreatTask/commentCreatTask";



function ChilMNodalEditShablon({ item ,allaray,nameCurrentSubTask,sendServer,load,editAllTask,index,allitem,handleGetNotify,sendMessage,handleClose}) {

  const toast = useToast()
  const getmyinfo = useSelector((allWorkers) => allWorkers);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const handleCloseChild = () => setShow(false);
  const handleShow = () => setShow(true);
  const [comments, setComments] = useState([]);
  const subTaskRed = useRef(null)
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [prioritet, setPrioritet] = useState("");
  const [metka, setMetka] = useState([]);
  const [performer, setperformer] = useState("");
  const [aboutTask, setAboutTask] = useState("");
  const [file, setfile] = useState(null);
  const [perfomerID, setperfomerID] = useState([]);
  const [endDateConfirm, setDndDateConfirm] = useState("");




  useEffect(()=>{
      
            
            setComments(item.comments)
            setStartDate(item.startDate)
            setEndDate(item.endDate)
            setAboutTask(item.aboutTask)
            setPrioritet(item.prioritet)
            setStatus(item.status)
            setperformer(item.performer)
       

  },[item.comments,item.startDate,item.endDate,item.aboutTask,item.prioritet,item.status,item.performer])









const sendserver=()=>{
             item.comments = comments
            item.performer = performer
            item.startDate = startDate
            item.endDate = endDate
            item.status = status
            item.prioritet = prioritet
            item.aboutTask = aboutTask
            item.file = file
            sendServer()
           
            handleCloseChild()
            handleClose()
            
}


const HandleSetPerfomerId=(e)=>{
 
  setperfomerID([e])
    setperformer(e)
    item.performer = performer
}

const HandleDeletePerfomerId=(e)=>{
  setperformer("")
  setperfomerID([])
  item.performer = performer
}


const handleDate=()=>{
      
  axios.post('/notifyConfirm', {
    eidtTaskID: allitem._id,
    isChild:true,
    adminIDTask:allitem.creatorTasks.toString(),
    typenotify:"date",
    indexTask:index,
    senderName:getmyinfo.getMayInfoReducer.myinfo.name,
    senderTel:getmyinfo.getMayInfoReducer.myinfo.tel,
    senderEmail:getmyinfo.getMayInfoReducer.myinfo.email,
    text:"",
    messageConfirm:endDateConfirm,
    taskName:nameCurrentSubTask,
    oldDate:editAllTask[0].child[index].endDate
    
  })
  .then(function (response) {
    console.log(response);
    handleGetNotify()
    toast({
      position:"top",
    title: 'Отправлено на подтверждение постановщику задачи',
    description: "Отправлено на подтверждение постановщику задачи",
    status: 'success',
    duration: 9000,
    isClosable: true,
  })
  })
  .catch(function (error) {
    console.log(error);
  });
}

const handleStatus=()=>{
  axios.post('/notifyConfirm', {
    eidtTaskID: allitem._id,
    isChild:true,
    adminIDTask:allitem.creatorTasks.toString(),
    typenotify:"status",
    indexTask:index,
    senderName:getmyinfo.getMayInfoReducer.myinfo.name,
    senderTel:getmyinfo.getMayInfoReducer.myinfo.tel,
    senderEmail:getmyinfo.getMayInfoReducer.myinfo.email,
    text:"",
    messageConfirm:endDate,
    taskName:nameCurrentSubTask,
    oldDate:editAllTask[0].child[index].endDate
    
  })
  .then(function (response) {
    console.log(response);
    handleGetNotify()
    toast({
        position:"top",
      title: 'Отправлено на подтверждение постановщику задачи',
      description: "Отправлено на подтверждение постановщику задачи",
      status: 'success',
      duration: 9000,
      isClosable: true,
    })
  })
  .catch(function (error) {
    console.log(error);
  });
}

  return (
    <div className="modalCreatTask">
      <Button colorScheme="blue" onClick={handleShow}>
      <span class="material-symbols-outlined">edit</span>


      </Button>

      <Modal show={show} onHide={handleCloseChild} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Параметры подзадачи</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="inputCreatTask">
        
            <div className="taskinputsDiv">
            
            <h1 className="currentTaskName">{nameCurrentSubTask}</h1>
         
              <Textarea placeholder='Добавьте  описание подзадачи' value={aboutTask} onChange={(e) => setAboutTask(e.target.value)}/>
  
  
              <UploadFileTaskMenegerEdit file={file} setfile={setfile}/>
              
              <CommentBoxCreatTask setComments={setComments} comments={comments} />
            </div>

            <div className="taskinputsDiv2">
            <PerfomerEdit performerS={performer} setperformerS={setperformer} HandleSetPerfomerId={HandleSetPerfomerId} HandleDeletePerfomerId={HandleDeletePerfomerId}/>
              <div className="dateicon">
                <span className="material-symbols-outlined">
                  calendar_month
                </span>
                <p>Сроки</p>
              </div>

              {(() => {
                                  if (allitem.creatorTasks === getmyinfo.getMayInfoReducer.myinfo._id) {
                                    return (
                                      <div className="date">
                                      <label htmlFor="">
                                        с
                                        <Input
                                          size="sm"
                                          type="datetime-local"
                                          colorScheme="grey"
                                          value={startDate}
                                          onChange={(e) => setStartDate(e.target.value)}
                                          
                                        />
                                      </label>
                                      <label htmlFor="">
                                        до
                                        <Input
                                          size="sm"
                                          type="datetime-local"
                                          value={endDate}
                                          onChange={(e) => setEndDate(e.target.value)}
                                        />
                                       
                                      </label>
                                    </div>
                                    )
                                  }else{
                                    return (
                                      <div className="date">
                                      <label htmlFor="">
                                        с
                                        <Input
                                          size="sm"
                                          type="datetime-local"
                                          colorScheme="grey"
                                          value={startDate}
                                          onChange={(e) => setStartDate(e.target.value)}
                                          disabled
                                          
                                        />
                                      </label>
                                      <label htmlFor="">
                                        до
                                        <Input
                                          size="sm"
                                          type="datetime-local"
                                          defaultValue={endDate}
                                          onChange={(e) => setDndDateConfirm(e.target.value)}
                                          key={endDate + endDateConfirm + item.endDate}
                                        />
                                        <button onClick={handleDate} className="confirmEditDate">Запросить подтверждение</button>
                                      </label>
                                    </div>
                                      )
                                  }
                                })()}

              <Status setStatus={setStatus} status={status}/>
              {(() => {
                                  if (allitem.creatorTasks !== getmyinfo.getMayInfoReducer.myinfo._id) {
                                    return (
                                      <button onClick={handleStatus} className="confirmEditDate">Сдать работу</button> 
                                    )
                                  }
                                })()}   

              <Metka setMetka={setMetka} metka={metka}/>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseChild}>
            Закрыть
          </Button>
         
          {
            load?
            <Button
            isLoading
            loadingText='Загрузка'
            colorScheme='teal'
            variant='outline'
          >
            Submit
          </Button>
            :
            <Button colorScheme="blue" onClick={sendserver}> Создать через шаблон</Button>
          }
        </Modal.Footer>
      </Modal>
      
    </div>
  );
}

export default ChilMNodalEditShablon;
