import { useEffect } from "react"
import "./changeChatNameInfoLight.css"
export default function ChangeChatNameInfo({item}) {

    useEffect(()=>{
        console.log(item,"asd");
    },[])
        return (
            <div className="changeChatNameInfoContLight">
                    <div>
                            <p>{item.senderInfo.name}</p>
                            <p>Изменил название группы на</p>
                            <p>{item.messageText}</p>
                    </div>
            </div>
        )
}