import { useNavigate } from "react-router"
import ChangeCompanyDrower from "../changeCompany/changeCompany"
import NotifyToggle from "../notifyToggle/notifyToggle"


export default function NavBarTopMobile({hanldetoggleSearch}) {
    const navigate = useNavigate();
    return (
        <div className="NavBarTopMobileCont">
            <div>
                <div className="logoAndSearch">
                    <div className="imgContContMobile"  onClick={()=> navigate("/")}></div>
                    <div className="searchContMinimizeMobile" onClick={hanldetoggleSearch}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11 0.25C5.61522 0.25 1.25 4.61522 1.25 10C1.25 15.3848 5.61522 19.75 11 19.75C16.3848 19.75 20.75 15.3848 20.75 10C20.75 4.61522 16.3848 0.25 11 0.25ZM2.75 10C2.75 5.44365 6.44365 1.75 11 1.75C15.5563 1.75 19.25 5.44365 19.25 10C19.25 14.5563 15.5563 18.25 11 18.25C6.44365 18.25 2.75 14.5563 2.75 10Z" fill="#353535" />
                            <path d="M19.5304 17.4698C19.2375 17.1769 18.7626 17.1769 18.4697 17.4698C18.1768 17.7626 18.1768 18.2375 18.4697 18.5304L22.4696 22.5304C22.7625 22.8233 23.2374 22.8233 23.5303 22.5304C23.8232 22.2375 23.8232 21.7626 23.5303 21.4697L19.5304 17.4698Z" fill="#353535" />
                        </svg>
                    </div>
                </div>
                <div className="drowerCompanyContMobile">
                    <ChangeCompanyDrower />
                </div>
                <div className="notifyAndQueryCont">
                    <div className="questionMark">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M7.90039 8.07955C7.90039 3.30679 15.4004 3.30683 15.4004 8.07956C15.4004 11.4886 11.9913 10.8067 11.9913 14.8976" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 19.0101L12.01 18.999" stroke="#353535" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    <NotifyToggle />
                </div>
            </div>
        </div>
    )
}